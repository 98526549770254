import { AppAPI } from './APIService';
import { IAddressPayload } from '../types/IAddress';

export const fetchOnboardingStatus = () => AppAPI.get('/account/onboarding');

export const sendOnboardingStatus = (newStep: number) => AppAPI.post(`/account/onboarding/${newStep}`);

export const fetchAccount = () => AppAPI.get('/account');

export const fetchAddressData = (uuid: string) =>
  AppAPI.get(`/addresses/owner/${uuid}/default`);

export const saveAddress = ({uuid, type, ...rest}: IAddressPayload, owner: string) => {
  if (uuid) {
    return AppAPI.patch(`/address/${uuid}`, rest);
  }
  return AppAPI.post('/address', {
    ...rest,
    type,
    owner,
    country: 'PL',
    default: true,
  });
};
