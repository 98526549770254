import * as React from 'react';
import {Button, CircularProgress, FormControl, Grid} from '@material-ui/core';
import {Field, Form, Formik} from 'formik';
import {TextField} from '../../form/TextField/TextField';
import {Text} from "../../Text/Text";
import {WithClasses} from "../../../../types/WithClasses";
import {SubmitFn} from "../../../../types/FormikSubmit";
import {loginFacebookAccount} from "../../../../services/AssociationService";
import {ErrorResponseDefault, parseRequestError} from "../../../../helpers/validation";
import {facebookAccountFormValidationSchema} from "./validationSchema";
import {facebookAccountFormInitialValues} from "./initialValues";
import {IFacebookAccountForm, IFacebookAccountPayload} from "../../../../types/IAssociations";
import {storage} from "../../../../services/StorageService";


const intlPath = 'facebookAccountForm';

interface FacebookAccountFormState {
  errorMessage?: string;
  skipFeedConfirmationRequested: boolean;
  shouldShowModal: boolean;
  googleAnalyticsStep: number;
}


export class FacebookAccountForm extends React.Component<WithClasses,
    FacebookAccountFormState> {
  public readonly state = {
    errorMessage: '',
    skipFeedConfirmationRequested: false,
    shouldShowModal: false,
    googleAnalyticsStep: 0,
  };


  public render() {
    const {classes} = this.props;

    return (
        <Formik
            initialValues={facebookAccountFormInitialValues}
            validationSchema={facebookAccountFormValidationSchema}
            onSubmit={this.handleFacebookAccountLogin}
            render={({isSubmitting}) => (
                <Form noValidate={true}>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <FormControl margin="normal" required fullWidth>
                        <Field
                            component={TextField}
                            autoComplete="app_id"
                            id="app_id"
                            name="app_id"
                            margin="normal"
                            autoFocus
                            messagePrefix={intlPath}
                            disabled={isSubmitting}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl margin="normal" required fullWidth>
                        <Field
                            component={TextField}
                            autoComplete="app_secret"
                            id="app_secret"
                            name="app_secret"
                            margin="normal"
                            messagePrefix={intlPath}
                            disabled={isSubmitting}
                        />
                      </FormControl>
                    </Grid>

                  </Grid>
                  <Grid container item xs={12} justify="flex-end">
                    {isSubmitting ? (
                        <CircularProgress/>
                    ) : (
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isSubmitting}
                        >
                          <Text id={`${intlPath}.submit`}/>
                        </Button>
                    )}
                  </Grid>
                </Form>
            )}
        />
    );
  }

  private handleFacebookAccountLogin: SubmitFn<IFacebookAccountForm> = (values, actions) => {
    this.setState(
        {errorMessage: ''},
        () => {
          loginFacebookAccount(values as IFacebookAccountPayload).then(
              this.onAccountLoginSuccess(values, actions),
              this.onAccountLoginFailure(values, actions)
          );
        }
    );
  };

  private onAccountLoginSuccess: SubmitFn<IFacebookAccountPayload> = (values, actions) => ({
    data,
  }: any) => {
    storage.set('app_id', values.app_id);
    storage.set('app_secret', values.app_secret);
    window.open(data, '_blank');
  };

  private onAccountLoginFailure: SubmitFn<IFacebookAccountPayload> = (_, actions) => (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage, errors} = parseRequestError(response.data);
    this.setState({errorMessage}, () => {
      actions.setSubmitting(false);
      actions.setErrors(errors);
    });
  };
}
