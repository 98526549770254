import * as Yup from 'yup';

export const newCampaignFormValidationSchema = Yup.object({
  name: Yup.string().required('required'),
  budget: Yup.number()
    .typeError('number')
    .positive('positive')
    .required('required'),
  age_min: Yup.number()
    .typeError('number')
    .positive('positive')
    .required('required'),
  age_max: Yup.number()
    .typeError('number')
    .positive('positive')
    .moreThan(Yup.ref('age_min'), 'biggerMinAge')
    .required('required'),
  website: Yup.object().shape({
    label: Yup.string().required('required'),
    value: Yup.string().required('required'),
  }),
  locations: Yup.array().min(1, 'required'),
  interests: Yup.array().min(1, 'required'),
  product_chosen_algorithm: Yup.string().required('required'),
});
