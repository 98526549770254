export const PUBLIC_ROUTES = {
  LOGIN: 'login',
  REGISTER: 'register',
  NEW_PASSWORD: 'new-password',
  RESET_PASSWORD: 'reset-password',
  REGISTER_CONFIRMATION_SUCCESS: 'user-confirmed',
  REGISTER_CONFIRMATION_FAILURE: 'invalid-confirmation-token',
};

export const PRIVATE_ROUTES = {
  DASHBOARD: 'dashboard',
  CHANGE_PASSWORD: 'change-password',
  ONBOARDING: 'onboarding',
  CAMPAIGNS: 'campaigns',
  CAMPAIGN: 'campaign',
  WEBSITES: 'websites',
  NEW: 'new',
  EDIT: 'edit',
  PROFILE: 'profile',
  ECOMMERCE: 'website',
  DONE: 'done',
  PAYMENT: 'payment',
  PRODUCTS: 'products',
  VARIANTS: 'variants',
  ADS: 'ads',
  SETTINGS: 'settings',
};
