import * as React from 'react';

import { Tabs, Tab, AppBar, withStyles } from '@material-ui/core';
import { FormikProps } from 'formik';

import { Text } from '../Text/Text';
import { WithClasses } from '../../../types/WithClasses';
import { MainForm } from './Main/MainForm';
import { TargetForm } from './Target/TargetForm';
import { ProductsForm } from './Products/ProductsForm';
import { styles } from './CampaignForm.styles';
import {ISelectedAutoComplete} from "../../../types/ICampaign";

interface ICampaignFormProps {
  createForm?: boolean;
  websites?: ISelectedAutoComplete[];
  handleChange: any;
  currentTab: number;
}


const intlPath = 'campaignForm';

export class CampaignFormBase extends React.Component<
  WithClasses & ICampaignFormProps & FormikProps<any>
> {


  public render = () => {
    const {
      setFieldTouched,
      setFieldValue,
      classes,
      websites,
      isSubmitting,
      createForm = false,
      values,
      handleChange,
      currentTab,
    } = this.props;

    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={<Text id={`${intlPath}.table.main`} />} />
            <Tab label={<Text id={`${intlPath}.table.target`} />} />
            <Tab
              label={<Text id={`${intlPath}.table.products`} />}
              disabled={!values.website}
            />
          </Tabs>
        </AppBar>
        <div className={classes.tabWrapper}>
          {currentTab === 0 && (
            <MainForm
              isSubmitting={isSubmitting}
              onBlur={setFieldTouched}
              onChange={setFieldValue}
              createForm={createForm}
              websites={websites}
            />
          )}
          {currentTab === 1 && (
            <TargetForm
              isSubmitting={isSubmitting}
              onBlur={setFieldTouched}
              onChange={setFieldValue}
            />
          )}
          {currentTab === 2 && (
            <ProductsForm
              isSubmitting={isSubmitting}
              values={values}
              setFieldValue={setFieldValue}
            />
          )}
        </div>
      </React.Fragment>
    );
  };


}

export const CampaignForm = withStyles(styles)(CampaignFormBase);
