import * as React from 'react';


import {RouteComponentProps, withRouter} from "react-router";
import {AppBar, Paper, Tab, Tabs, withStyles} from "@material-ui/core";
import {Text} from "../../../components/common/Text/Text";
import {WithClasses} from "../../../types/WithClasses";
import {styles} from "./SettingsPageBase.styles";
import {AccountSettingsPage} from "./Account/AccountSettings";
import {UserProfileSettingsPage} from "./Profile/UserProfileSettings";

const intlPath = "settingsPage";

interface ISettingsPageState {
  currentTab: number;
}

export class SettingsBase extends React.Component<RouteComponentProps &
    WithClasses, ISettingsPageState> {

  public readonly state = {
    currentTab: 0,
  };

  componentDidMount() {
    const {location} = this.props;
    const paramString: string = (location.search as any);

    const params = new URLSearchParams(paramString);
    const access_token = params.get('access_token');

    if(access_token && access_token !== 'null'){
      this.setState({currentTab: 1});
    }
  }

  render() {
    const { currentTab } = this.state;
    return (
        <React.Fragment>
          <AppBar position="static" color="default">
            <Tabs
                value={currentTab}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
              <Tab label={<Text id={`${intlPath}.table.profile`}/>}/>
              <Tab label={<Text id={`${intlPath}.table.account`}/>}/>
              <Tab
                  label={<Text id={`${intlPath}.table.campaign`}/>}
              />
            </Tabs>
          </AppBar>
          <div>
            {currentTab === 0 && (
                <Paper>
                  <UserProfileSettingsPage />
                </Paper>
            )}
            {currentTab === 1 && (
                <Paper>
                  <AccountSettingsPage />
                </Paper>
            )}
            {currentTab === 2 && (
                <Paper>
                  Campaigns settings
                </Paper>
            )}
          </div>
        </React.Fragment>
    );
  }

  private handleTabChange = (event: React.ChangeEvent<{}>, currentTab: number) => {
    this.setState({ currentTab });
  };

};

export const SettingsBasePageWithStyles = withStyles(styles)(SettingsBase);

export const SettingsPageBase = withRouter(SettingsBasePageWithStyles);
