import {ICampaign} from '../types/ICampaign';
import {AppAPI} from './APIService';

export interface CampaignRequestError {
  code: number;
  message: string;
}

export const fetchCampaigns = () => AppAPI.get('/campaigns');

export const fetchCampaign = (campaignId: ICampaign['uuid']) =>
    AppAPI.get(`/campaigns/${campaignId}`);

export const createCampaign = (payload: any) => {
  return AppAPI.post('/campaign', payload);
};

export const updateCampaign = (campaignUuid: ICampaign['uuid'], payload: any) =>
    AppAPI.patch(`/campaigns/${campaignUuid}`, payload);

export const renderImageVariants = (campaignUuid: ICampaign['uuid']) =>
    AppAPI.post(`/campaigns/${campaignUuid}/render-image-variants`);

export const createProductList = (campaignUuid: ICampaign['uuid'], payload: any) =>
    AppAPI.post(`/campaigns/${campaignUuid}/product-list`, payload);

export const updateProductList = (productListUuid: ICampaign['uuid'], payload: any) =>
    AppAPI.patch(`/product-lists/${productListUuid}`, payload);


export const fetchCampaignVariants = (campaignUuid: ICampaign['uuid']) =>
    AppAPI.get(`/campaigns/${campaignUuid}/image-variants`);

export const fetchCampaignAds = (campaignUuid: ICampaign['uuid']) =>
    AppAPI.get(`/campaigns/${campaignUuid}/ads`);

export const createCampaignAds = (campaignUuid: ICampaign['uuid'], payload: any) =>
    AppAPI.post(`/campaigns/${campaignUuid}/image-variants`, payload);