import { Theme, createStyles } from '@material-ui/core';
import { IClass } from '../../../../types/WithClasses';

export const styles = ({ spacing }: Theme): IClass =>
  createStyles({
    root: {
      width: '100%',
      padding: spacing.unit * 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    header: {
      textAlign: 'center',
      marginBottom: spacing.unit * 3,
    },
  });
