import {IWebsite} from "./IWebsite";

export interface ICampaign {
  uuid: string;
  name: string;
  status: CampaignStatus;
  start_date: string;
  end_date: string;
  stats_roi: number;
  budget: number;
  budget_spent: number;
  type: CampaignType;
  website_name: string;
  products_count: number;
  target: IFacebookTarget;
  product_chosen_algorithm: ProductChooserAlgorithm;
  products_list: IProductList;
  website: IWebsite;
  ads: any[];
}

export interface ICampaignProductGroup {
  name: string;
  variants: ICampaignProductVariant[];
}

export interface ICampaignProductVariant {
  uuid: string;
  config_json: string;
  feed_uuid: string;
  image_src: string;
  image_id: number;
  image_name: string;
  hash: string;
  ad_variant_uuid: string;
}

export interface ICampaignVariantsForm {
  variants_list: any[];
}

export interface ICampaignForm {
  name: string;
  budget: string;
  age_min: string;
  age_max: string;
  locations: ISelectedAutoComplete[];
  interests: ISelectedAutoComplete[];
  product_chosen_algorithm: ProductChooserAlgorithm;
  products_list: string[];
  website: ISelectedAutoComplete;
}

export interface ISelectedAutoComplete {
  value: string;
  label: string;
}

export interface ICampaignPayload extends ICampaignForm {}

export interface IProductList {
  product_product_lists: string[];
}

export interface IProductPayload extends IProductList {}

interface ITarget {
  type: TargetType;
}

interface IFacebookTarget extends ITarget {
  type: TargetType.FACEBOOK;
  locations: string[];
  interests: string[];
  age_min: number;
  age_max: number;
}

export enum TargetType {
  FACEBOOK = 10,
  GOOGLE = 20,
}

export enum CampaignStatus {
  ACTIVE = 10,
  NOT_READY = 20,
  FINISHED = 30,
  PAUSED = 40,
}

export enum CampaignType {
  FACEBOOK = 10,
  GOOGLE = 20,
}

export enum ProductChooserAlgorithm {
  MANUAL = 1,
  AUTO = 2,
}
