import { Theme, createStyles } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: spacing.unit * 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    button: {
      marginRight: spacing.unit * 2,
    },
  });
