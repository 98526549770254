import * as React from 'react';
import {Button, CircularProgress, FormControl, Grid} from '@material-ui/core';
import {Field, Form, Formik} from 'formik';
import {TextField} from '../../form/TextField/TextField';
import {googleAnalyticsAccountFormInitialValues} from "./initialValues";
import {googleAnalyticsAccountFormValidationSchema} from "./validationSchema";
import {Text} from "../../Text/Text";
import {WithClasses} from "../../../../types/WithClasses";
import {SubmitFn} from "../../../../types/FormikSubmit";
import {IGoogleAnalyticsAccountForm, IGoogleAnalyticsAccountPayload} from "../../../../types/IAssociations";
import {storage} from "../../../../services/StorageService";
import {loginGoogleAccount} from "../../../../services/AssociationService";
import {ErrorResponseDefault, parseRequestError} from "../../../../helpers/validation";


const intlPath = 'googleAnalyticsAccountForm';

interface GoogleAnalyticsAccountFormState {
  errorMessage?: string;
  skipFeedConfirmationRequested: boolean;
  shouldShowModal: boolean;
  googleAnalyticsStep: number;
}


export class GoogleAnalyticsAccountForm extends React.Component<WithClasses,
    GoogleAnalyticsAccountFormState> {
  public readonly state = {
    errorMessage: '',
    skipFeedConfirmationRequested: false,
    shouldShowModal: false,
    googleAnalyticsStep: 0,
  };


  public render() {
    const {classes} = this.props;

    return (
        <Formik
            initialValues={googleAnalyticsAccountFormInitialValues}
            validationSchema={googleAnalyticsAccountFormValidationSchema}
            onSubmit={this.handleGoogleAccountLogin}
            render={({isSubmitting}) => (
                <Form noValidate={true}>
                  <Grid container spacing={24} className={classes.formWrapper}>
                    <Grid item xs={12}>
                      <FormControl margin="normal" required fullWidth>
                        <Field
                            component={TextField}
                            autoComplete="client_id"
                            id="client_id"
                            name="client_id"
                            margin="normal"
                            autoFocus
                            messagePrefix={intlPath}
                            disabled={isSubmitting}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl margin="normal" required fullWidth>
                        <Field
                            component={TextField}
                            autoComplete="client_secret"
                            id="client_secret"
                            name="client_secret"
                            margin="normal"
                            messagePrefix={intlPath}
                            disabled={isSubmitting}
                        />
                      </FormControl>
                    </Grid>

                  </Grid>
                  <Grid container item xs={12} justify="flex-end">
                    {isSubmitting ? (
                        <CircularProgress/>
                    ) : (
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isSubmitting}
                        >
                          <Text id={`${intlPath}.submit`}/>
                        </Button>
                    )}
                  </Grid>
                </Form>
            )}
        />
    );
  }

  private handleGoogleAccountLogin: SubmitFn<IGoogleAnalyticsAccountForm> = (values, actions) => {
    if(
        (document.getElementById('name') as HTMLInputElement) &&
        (document.getElementById('url') as HTMLInputElement) &&
        (document.getElementById('feed') as HTMLInputElement) &&
        (document.getElementById('skip_feed') as HTMLInputElement)
    ){
      const website = {
        name: (document.getElementById('name') as HTMLInputElement).value,
        url: (document.getElementById('url') as HTMLInputElement).value,
        feed: (document.getElementById('feed') as HTMLInputElement).value,
        skip_feed: (document.getElementById('skip_feed') as HTMLInputElement).value
      };
      storage.setObject('website', website);
    }

    this.setState(
        {errorMessage: ''},
        () => {
          loginGoogleAccount(values as IGoogleAnalyticsAccountPayload).then(
              this.onGoogleAccountLoginSuccess(values, actions),
              this.onGoogleAccountLoginFailure(values, actions)
          );
        }
    );
  };

  private onGoogleAccountLoginSuccess: SubmitFn<IGoogleAnalyticsAccountPayload> = (values, actions) => ({
    data,
  }: any) => {
    window.open(data, '_blank')
  };

  private onGoogleAccountLoginFailure: SubmitFn<IGoogleAnalyticsAccountPayload> = (_, actions) => (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage, errors} = parseRequestError(response.data);
    this.setState({errorMessage}, () => {
      actions.setSubmitting(false);
      actions.setErrors(errors);
    });
  };
}
