import * as React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  Typography,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Text} from '../../../Text/Text';
import {WithClasses} from "../../../../../types/WithClasses";
import {styles} from "./NavigationItem.styles";

const renderLink = (to: string) => (props: any) => <Link to={to} {...props} />;

interface ListItemLinkProps {
  Icon: React.ComponentType;
  label: React.ReactNode;
  to: string;
}

const intlPath = 'navigation';


export const ListItemLinkBase = ({Icon, label, to, classes}: ListItemLinkProps & WithClasses) => (
    <li>
      <ListItem button component={renderLink(to)}>
        <ListItemIcon classes={classes.icon} children={<Typography color="primary"><Icon/></Typography>}/>

        <ListItemText
            primary={
              <Typography color="primary">
                <Text id={`${intlPath}.${label}`}/>
              </Typography>
            }
        />
      </ListItem>
    </li>
);

export const ListItemLink = withStyles(styles)(ListItemLinkBase);
