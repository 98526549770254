import * as React from 'react';

import {
  Typography,
  Toolbar,
  withStyles,
  Button,
  Paper,
  Grid,
} from '@material-ui/core';

import {PRIVATE_ROUTES} from '../../../constants/routes';

import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import {Text} from '../../../components/common/Text/Text';
import {ButtonLink} from '../../../components/common/ButtonLink/ButtonLink';
import {Error} from '../../../components/common/Error/Error';
import {
  ICampaign,
  ICampaignPayload,
  ICampaignForm, IProductPayload, ISelectedAutoComplete,
} from '../../../types/ICampaign';
import {
  createProductList,
  fetchCampaign, renderImageVariants,
  updateCampaign, updateProductList,
} from '../../../services/CampaignsService';
import {withRouter, RouteComponentProps} from 'react-router';
import {Loader} from '../../../components/common/Loader/Loader';
import {styles} from './EditCampaignPage.styles';
import {WithClasses} from '../../../types/WithClasses';
import {Formik, Form} from 'formik';
import {editCampaignFormInitialValues} from './initialValues';
import {editCampaignFormValidationSchema} from './validationSchema';
import {CampaignForm} from './../../../components/common/CampaignForm/CampaignForm';
import {
  ErrorResponseDefault,
  parseRequestError,
} from '../../../helpers/validation';
import {SubmitFn} from '../../../types/FormikSubmit';
import {fetchWebsites} from "../../../services/WebsiteService";
import {IWebsite} from "../../../types/IWebsite";

interface IEditCampaignsState {
  isLoading: boolean;
  campaign?: ICampaign;
  websites?: ISelectedAutoComplete[];
  currentTab: number;
  loadingErrorMessage?: string;
  sendingErrorMessage?: string;
}

const intlPath = 'editCampaignPage';

class EditCampaign extends React.Component<WithHelmetProps & WithClasses & RouteComponentProps,
    IEditCampaignsState> {
  public readonly state = {
    isLoading: false,
    campaign: undefined,
    websites: undefined,
    currentTab: 0,
    loadingErrorMessage: '',
    sendingErrorMessage: '',
  };

  public componentDidMount() {
    this.fetchCampaign();
    this.fetchWebsites();
  }

  public render() {
    const {classes, match} = this.props;
    const {
      isLoading,
      loadingErrorMessage,
      campaign,
      websites,
      sendingErrorMessage,
      currentTab
    } = this.state;
    const campaignId: string = (match.params as any).id;
    if (loadingErrorMessage) {
      <Error message={`${intlPath}.requestError.message`}>
        <Button
            className={classes.retryButton}
            type="button"
            variant="contained"
            color="primary"
            onClick={this.fetchCampaign}
        >
          <Text id={`${intlPath}.requestError.retryButton`}/>
        </Button>
      </Error>;
    }
    if (isLoading) {
      return (
          <Loader
              isLoading={isLoading}
              loadingLabelKey={`${intlPath}.fetchingCampaign`}
          />
      );
    }

    console.log(campaign);
    if (campaign && websites) {
      return (
          <Formik
              initialValues={editCampaignFormInitialValues(campaign)}
              validationSchema={editCampaignFormValidationSchema}
              onSubmit={this.onCampaignSave}
              render={formikProps => (
                  <Form noValidate={true} onSubmitCapture={() => this.validate(formikProps)}>
                    <Typography component="h1" variant="h5">
                      <Text id={`${intlPath}.title`}/>
                    </Typography>
                    <Toolbar disableGutters={true}>
                      <ButtonLink
                          to={`/${PRIVATE_ROUTES.CAMPAIGNS}/${campaignId}`}
                          variant="contained"
                          color="primary"
                          aria-label="Back"
                          className={classes.button}
                      >
                        <Text id={`${intlPath}.back`}/>
                      </ButtonLink>
                      <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          aria-label="Save campaign"
                          className={classes.button}
                      >
                        <Text id={`${intlPath}.saveCampaignButton`}/>
                      </Button>
                    </Toolbar>
                    <Paper className={classes.root}>
                      <CampaignForm {...formikProps} createForm={true} websites={websites} handleChange={this.handleTabChange} currentTab={currentTab}/>
                      <Grid container>
                        {sendingErrorMessage && (
                            <Grid item xs={12}>
                              <Typography color="error" align="right">
                                <Text id={sendingErrorMessage}/>
                              </Typography>
                            </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Form>
              )}
          />
      );
    }
    return null;
  }


  private handleTabChange = (event: React.ChangeEvent<{}>, currentTab: number) => {
    this.setState({ currentTab });
  };

  private handleTabChange2 = (currentTab: number) => {
    this.setState({ currentTab });
  };

  private validate = (formikProps : any) => {
    if(
        (formikProps.values.name.length > 8 && formikProps.values.website.value && formikProps.values.budget > 1000)
        &&
        (!formikProps.values.age_min || !formikProps.values.age_max || formikProps.values.locations.length === 0 || formikProps.values.interests.length === 0)
    ){
      this.handleTabChange2(1);
    }

  };

  private fetchCampaign = () => {
    const {match} = this.props;
    const campaignId: string = (match.params as any).id;
    this.setState(
        {isLoading: true, loadingErrorMessage: undefined, campaign: undefined},
        () =>
            fetchCampaign(campaignId).then(this.onCampaignFetchSuccess, this.onFetchFailure)
    );
  };

  private fetchWebsites = () => {
    this.setState(
        {isLoading: true, loadingErrorMessage: undefined, websites: undefined},
        () =>
            fetchWebsites().then(this.onWebsitesFetchSuccess, this.onFetchFailure)
    );
  };

  private onWebsitesFetchSuccess = ({data}: any) => {
    const websitesToSelect = (data.items || []).map((website: IWebsite) => ({
      value: website.uuid,
      label: website.name,
    }));
    this.setState({websites: websitesToSelect, isLoading: false});
  };

  private onCampaignFetchSuccess = ({data}: any) => {
    this.setState({campaign: data, isLoading: false});
  };

  private onFetchFailure = (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage} = parseRequestError(response.data);
    this.setState({
      isLoading: false,
      loadingErrorMessage: errorMessage,
    });
  };

  private onCampaignSave: SubmitFn<ICampaignForm> = (values, actions) => {
    const payload: ICampaignPayload = {
      ...values,
    };

    const {match} = this.props;
    this.setState(
        {
          sendingErrorMessage: '',
          isLoading: true,
        },
        () => {
          const campaignUuid: string = (match.params as any).id;
          updateCampaign(campaignUuid, payload as ICampaignPayload).then(
              this.onSaveSuccess(payload, actions),
              this.onSaveFailure(payload, actions)
          );

        }
    );
  };

  private onSaveSuccess: SubmitFn<ICampaignForm> = (_, actions) => ({
                                                                      data,
                                                                    }: any) => {
    this.setState(
        {sendingErrorMessage: '', isLoading: false, campaign: data},
        () => {
          actions.setSubmitting(false);
        }
    );
// TODO function below flow
    if (data.products_list[0]) {
      const listUuid = data.products_list[0].uuid;
      const productPayload: IProductPayload = {
        product_product_lists: _.products_list,
      };

      updateProductList(listUuid, productPayload as IProductPayload).then(
          this.onProductSaveSuccess(_, actions),
          this.onSaveFailure(_, actions)
      );
    } else {
      const campaignUuid = data.uuid;
      const productPayload: IProductPayload = {
        product_product_lists: _.products_list,
      };

      createProductList(campaignUuid, productPayload as IProductPayload).then(
          this.onProductSaveSuccess(_, actions),
          this.onSaveFailure(_, actions)
      );
    }

  };


  private onProductSaveSuccess: SubmitFn<ICampaignForm> = (_, actions) => ({
                                                                             data,
                                                                           }: any) => {
    renderImageVariants(data.campaign_uuid);

    const {history} = this.props;
    this.setState({sendingErrorMessage: '', isLoading: false}, () => {
      actions.setSubmitting(false);
      history.push(`/${PRIVATE_ROUTES.CAMPAIGNS}/${data.campaign_uuid}`);
    });
  };

  private onSaveFailure: SubmitFn<ICampaignForm> = (_, actions) => (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage, errors} = parseRequestError(response.data);
    this.setState(
        {sendingErrorMessage: errorMessage, isLoading: false},
        () => {
          actions.setSubmitting(false);
          actions.setErrors(errors);
        }
    );
  };
}

export const EditCampaignPageWithStyles = withStyles(styles)(EditCampaign);

export const EditCampaignPageWithHelmet = withHelmet(
    EditCampaignPageWithStyles
);

export const EditCampaignPage = withRouter(EditCampaignPageWithHelmet);
