import * as React from 'react';

import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

interface IconLinkProps {
  to: string;
  children: React.ReactNode;
}

export const IconLink = ({
  to,
  children,
  ...rest
}: IconLinkProps & any) => (
  <IconButton {...{ component: Link } as any} to={to} {...rest}>
    {children}
  </IconButton>
);
