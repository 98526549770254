import * as React from 'react';

import { Typography, Paper, withStyles } from '@material-ui/core';

import { Text } from '../../../components/common/Text/Text';
import { ButtonLink } from '../../../components/common/ButtonLink/ButtonLink';
import { WithClasses } from '../../../types/WithClasses';
import { styles } from './DonePage.styles';
import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import { PRIVATE_ROUTES } from '../../../constants/routes';

const nextUrl = `/${PRIVATE_ROUTES.DASHBOARD}`;

export const Done = ({ classes, messagePrefix }: WithClasses & WithHelmetProps) => (
  <Paper className={classes.root}>
    <Typography component="h1" variant="h5" className={classes.header}>
      <Text id={`${messagePrefix}.header`} />
    </Typography>
    <Typography component="p">
      <Text id={`${messagePrefix}.description`} />
    </Typography>
    <ButtonLink
      to={nextUrl}
      variant="contained"
      color="primary"
      aria-label="Edit campaign"
      className={classes.button}
    >
      <Text id={`${messagePrefix}.startButton`} />
    </ButtonLink>
  </Paper>
);

export const DonePageWithStyles = withStyles(styles)(Done);

export const DonePage = withHelmet(DonePageWithStyles);
