
import { Action, ActionType } from './actions';
import { IAccount } from '../../types/IAccount';

export interface IAccountState {
  account?: IAccount;
}

export const initialState: IAccountState = {
  account: undefined,
};

export function accountReducer(
  state: IAccountState = initialState,
  action: Action
): IAccountState {
  switch (action.type) {
    case ActionType.StoreAccountData:
      return {
        ...state,
        account: action.payload
      };
    default:
      return state;
  }
}

