import * as React from 'react';
import { withStyles, Paper, Typography, Link } from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  withHelmet,
  WithHelmetProps,
} from '../../components/HOC/Helmet/withHelmet';
import { styles } from './RegisterPage.styles';
import { WithClasses } from '../../types/WithClasses';
import { PublicPageWrapper } from '../../components/common/PublicPageWrapper/PublicPageWrapper';
import { RegisterForm } from './RegisterForm/RegisterForm';
import { LinkToLogin } from '../../components/common/LinkToLogin/LinkToLogin';
import { SubmitFn } from '../../types/FormikSubmit';
import { INewUser } from '../../types/IUser';
import { register } from '../../services/UserService';
import { Text } from '../../components/common/Text/Text';

interface RegisterState {
  success: boolean;
}

const intlPath = 'registerPage';
class Register extends React.Component<
  WithHelmetProps & WithClasses & RouteComponentProps,
  RegisterState
> {
  public readonly state = {
    success: false,
  };

  public render() {
    const { classes } = this.props;
    const { success } = this.state;
    return (
      <PublicPageWrapper>
        <Paper className={classes.paper}>
          {success ? (
            <React.Fragment>
              <Typography component="h1" variant="h5">
                <Text id={`${intlPath}.success.title`} />
              </Typography>
              <Typography component="p">
                <Text id={`${intlPath}.success.subtitle`} />
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography component="h1" variant="h5">
                <Text id={`${intlPath}.title`} />
              </Typography>
              <Typography component="p">
                <Text id={`${intlPath}.subtitle`} />
              </Typography>
              <PersonAdd />
              <Typography component="p">
                <Text id={`${intlPath}.withEmail`} />
              </Typography>
              <RegisterForm onRegister={this.doRegister} />
              <Typography className={classes.loginLink}>
                <Link component={LinkToLogin}>
                  <Text id={`${intlPath}.loginLink`} />
                </Link>
              </Typography>
            </React.Fragment>
          )}
        </Paper>
      </PublicPageWrapper>
    );
  }

  private doRegister: SubmitFn<INewUser> = (values, actions) => {
    actions.setStatus({ submitErrorMessage: '' });
    register(values).then(
      this.onRegisterSuccess(values, actions),
      this.onRegisterFailure(values, actions)
    );
  };

  private onRegisterSuccess: SubmitFn<INewUser> = (_, actions) => () => {
    actions.setSubmitting(false);
    actions.setStatus({ submitErrorMessage: '' });
    this.setState({ success: true });
  };

  private onRegisterFailure: SubmitFn<INewUser> = (_, actions) => (
    { response } = { response: {} as any }
  ) => {
    actions.setSubmitting(false);
    const submitErrorMessage =
      response && response.data.msg
        ? response && response.data.msg
        : 'genericErrorMessage';
    actions.setStatus({ submitErrorMessage });
  };
}

const RegisterWithStyles = withStyles(styles)(Register);

const RegisterPageWithHelmet = withHelmet(RegisterWithStyles);

export const RegisterPage = withRouter(RegisterPageWithHelmet);
