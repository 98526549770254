import * as React from 'react';
import { FormControl, Button, withStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';

import { WithClasses } from '../../../types/WithClasses';
import { styles } from './NewPasswordForm.styles';
import { SubmitFn } from '../../../types/FormikSubmit';
import { INewPassword } from '../../../types/IUser';
import { TextField } from '../../../components/common/form/TextField/TextField';
import { newPasswordFormInitialValues } from './initialValues';
import { newPasswordFormValidationSchema } from './validationSchema';
import { Text } from '../../../components/common/Text/Text';

interface ResetPasswordProps {
  onNewPassword: SubmitFn<INewPassword>;
}

const intlPath = 'newPasswordPage.form';

const NewPasswordFormBase = ({
  classes,
  onNewPassword,
}: WithClasses & ResetPasswordProps) => (
  <Formik
    initialValues={newPasswordFormInitialValues}
    validationSchema={newPasswordFormValidationSchema}
    onSubmit={onNewPassword}
    render={({ isSubmitting, status }) => (
      <Form className={classes.form} noValidate={true}>
        <FormControl margin="normal" required fullWidth>
          <Field
            component={TextField}
            id="password"
            name="password"
            margin="normal"
            type="password"
            messagePrefix={intlPath}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <Field
            component={TextField}
            id="confirm_password"
            name="confirm_password"
            margin="normal"
            type="password"
            messagePrefix={intlPath}
          />
        </FormControl>
        {status && status.submitErrorMessage && (
          <Typography className={classes.formSubmitError} color="error">
            <Text id={status.submitErrorMessage} />
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting}
        >
          <Text id={`${intlPath}.submit`} />
        </Button>
      </Form>
    )}
  />
);

export const NewPasswordForm = withStyles(styles)(NewPasswordFormBase);
