import * as React from 'react';
import {Grid, Typography, withStyles} from '@material-ui/core';
import {AccountCircle} from "@material-ui/icons";
import {WithClasses} from "../../../../types/WithClasses";
import {styles} from "./UserInfo.styles";
import {Text} from "../../Text/Text";

const userData = {
  name: "Maciej Borzymowski",
  companyName: "Nextima",
  legalForm: "Spółka z ograniczoną odpowiedzialnością"
};

export const UserInfoBase = ({classes}: WithClasses) => (
    <Grid container className={classes.userInfoWrapper}>
      <Grid item xs={3} className={classes.iconWrapper}>
        <AccountCircle/>
      </Grid>
      <Grid item xs={9} className={classes.userDataWrapper}>
        <Typography color="primary" className={classes.userName}>
          <Text id={userData.name}/>
        </Typography>
        <Typography color="secondary" className={classes.companyName}>
          <Text id={userData.companyName}/>
        </Typography>
        <Typography color="secondary" className={classes.legalFormName}>
          <Text id={userData.legalForm}/>
        </Typography>
      </Grid>
    </Grid>
);

export const UserInfo = withStyles(styles)(UserInfoBase);