export interface IWebsiteForm {
  name: string;
  url: string;
  feed: string;
  skip_feed: boolean;
}

export interface IWebsitePayload extends IWebsiteForm {}

export interface IWebsite {
  uuid: string;
  name: string;
  url: string;
  feeds: IFeed;
  status: IWebsiteStatus;
  campaigns_count: number;
  ga_connected: boolean;
}


export interface IFeed {
  uuid: string;
  name: string;
  url: string;
  type: IFeedType;
  status: IFeedStatus;
  import_status: IFeedImportStatus;
  last_import_date: Date;
  products_count: number;
}

export enum IWebsiteStatus {
  OK = 1,
  NOT_OK = 2,
}

export enum IFeedType {
  FACEBOOK = 1,
  GOOGLE_CSV = 2,
  GOOGLE_XML = 3,
}

export enum IFeedStatus {
  OK = 1,
  NOT_OK = 2,
}

export enum IFeedImportStatus {
  IMPORTING = 'importing...',
  IMPORTED = 'imported',
  FAILED = 'failed',
}