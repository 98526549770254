import * as React from 'react';
import { FormControl, Grid } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from '../form/TextField/TextField';
import { Checkbox } from '../form/CheckBox/CheckBox';

interface WebsiteFormProps {
  viewOnly?: boolean;
  isSubmitting?: boolean;
  values: any;
}

const intlPath = 'websiteForm';

export const WebsiteForm = ({
  viewOnly = false,
  isSubmitting = false,
  values,
}: WebsiteFormProps) => (
  <Grid container spacing={24}>
    <Grid item xs={12}>
      <FormControl margin="normal" required fullWidth>
        <Field
          component={TextField}
          autoComplete="name"
          id="name"
          name="name"
          margin="normal"
          autoFocus
          messagePrefix={intlPath}
          disabled={viewOnly || isSubmitting}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl margin="normal" required fullWidth>
        <Field
          component={TextField}
          autoComplete="url"
          id="url"
          name="url"
          margin="normal"
          messagePrefix={intlPath}
          disabled={viewOnly || isSubmitting}
        />
      </FormControl>
    </Grid>
    <Grid item xs={9}>
      <FormControl margin="normal" required={!values.skip_feed} fullWidth>
        <Field
          component={TextField}
          autoComplete="feed"
          id="feed"
          name="feed"
          margin="normal"
          messagePrefix={intlPath}
          disabled={viewOnly || isSubmitting || values['skip_feed']}
        />
      </FormControl>
    </Grid>
    <Grid item xs={3}>
      <FormControl margin="normal" fullWidth>
        {viewOnly ? (
          <Field
            component={Checkbox}
            id="feed_ok"
            name="feed_ok"
            margin="normal"
            messagePrefix={intlPath}
            disabled={true}
          />
        ) : (
          <Field
            component={Checkbox}
            id="skip_feed"
            name="skip_feed"
            margin="normal"
            messagePrefix={intlPath}
            disabled={viewOnly || isSubmitting}
          />
        )}
      </FormControl>
    </Grid>
  </Grid>
);
