import * as React from 'react';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router';

import { PrivatePageWrapper } from '../../components/common/PrivatePageWrapper/connect';
import {
  withHelmet,
  WithHelmetProps,
} from '../../components/HOC/Helmet/withHelmet';
import { ONBOARDING_NESTED_ROUTES } from '../../constants/routerConfig';

const Onboarding = ({ match }: WithHelmetProps & RouteComponentProps) => (
  <PrivatePageWrapper>
    <Switch>
      {ONBOARDING_NESTED_ROUTES.map(({ Component, path, exact = false, ...props }) => (
        <Route
          exact={exact}
          key={path(match.url)}
          path={path(match.url)}
          render={() => <Component {...props} />}
        />
      ))}
    </Switch>
  </PrivatePageWrapper>
);

export const OnboardingPageWithHelmet = withHelmet(Onboarding);

export const OnboardingPage = withRouter(OnboardingPageWithHelmet);
