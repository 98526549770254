import { Theme, createStyles } from '@material-ui/core';

const ACTIONS_NO = 3;
const ACTION_ICON_WIDTH = `48px`;
const ACTIONS_COLUMN_PADDING = `48px`;

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: spacing.unit * 3,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    actionsColumn: {
      width: `calc(${ACTIONS_NO} * ${ACTION_ICON_WIDTH} + ${ACTIONS_COLUMN_PADDING})`,
    },
    noDataWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: spacing.unit * 3,
    },
    createCampaign: {
      marginTop: spacing.unit * 3,
    },
  });
