import { Theme, createStyles } from '@material-ui/core';
import { drawerWidth } from '../Menu/Menu.styles';

export const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    appBar: {
      marginLeft: drawerWidth,
      backgroundColor: '#fafafa',
      boxShadow: 'unset',
      [breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    menuButton: {
      marginRight: spacing.unit * 3,
      [breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'space-between',
      [breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
      },
    },
  });
