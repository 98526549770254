import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { Dispatch } from 'react';
import { IRootState } from '../../../store/rootReducer';
import {
  PrivatePageWrapperDispatchProps,
  PrivatePageWrapperStoreProps,
  PrivatePageWrapperBase,
} from './PrivatePageWrapper';
import { IAccount } from '../../../types/IAccount';
import { selectAccount } from '../../../store/account/selectors';
import { storeAccountData, Action } from '../../../store/account/actions';

const mapStateToProps: MapStateToProps<
  PrivatePageWrapperStoreProps,
  any,
  IRootState
> = store => ({
  account: selectAccount(store),
});

const mapDispatchToProps: MapDispatchToProps<
  PrivatePageWrapperDispatchProps,
  any
> = (dispatch: Dispatch<Action>) => ({
  storeAccountData: (payload: IAccount) => dispatch(storeAccountData(payload)),
});

export const PrivatePageWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivatePageWrapperBase);
