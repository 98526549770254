import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { getSessionToken, removeSessionToken } from './AuthService';
import { history } from '../helpers/history';
import { PUBLIC_ROUTES } from '../constants/routes';

const SESSION_TOKEN_HEADER = 'session-token';

const sessionRequestInterceptor = function(config: AxiosRequestConfig) {
  const token = getSessionToken();
  if (token) {
    config.headers.common[SESSION_TOKEN_HEADER] = token;
  }
  return config;
};

const errorResponseInterceptor = function(error: AxiosError) {
  if(!error.response || (error.response && error.response.status === 403)) {
    removeSessionToken();
    history.push(`/${PUBLIC_ROUTES.LOGIN}`);
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

export const UsersAPI = axios.create({
  baseURL: process.env.REACT_APP_USERS_BASE_URL,
});

export const AppAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const FbMarketingAPI = axios.create({
  baseURL: process.env.REACT_APP_FACEBOOK_MARKETING_API_BASE_URL,
});

UsersAPI.interceptors.request.use(sessionRequestInterceptor);
AppAPI.interceptors.request.use(sessionRequestInterceptor);

UsersAPI.interceptors.response.use(undefined, errorResponseInterceptor);
AppAPI.interceptors.response.use(undefined, errorResponseInterceptor);
