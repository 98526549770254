
import { Action, ActionType } from './actions';
import { LOCALE } from '../../constants/locales';

export interface ILanguageState {
  locale: string;
}

function getSupportedLocale(locale: string = '') {
  const lang = LOCALE[locale.substring(0, 2).toUpperCase()];
  return lang ? lang : LOCALE.EN;
}

export const initialState: ILanguageState = {
  locale: getSupportedLocale(window.navigator.language),
};

export function languageReducer(
  state: ILanguageState = initialState,
  action: Action
): ILanguageState {
  switch (action.type) {
    case ActionType.SetLanguage:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
}
