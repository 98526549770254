import * as React from 'react';
import {Button, CircularProgress, FormControl, Grid} from '@material-ui/core';
import {Field, Form, Formik} from 'formik';
import {TextField} from '../../form/TextField/TextField';
import {WithClasses} from "../../../../types/WithClasses";
import {Text} from "../../Text/Text";
import {SubmitFn} from "../../../../types/FormikSubmit";
import {
  IGoogleAnalyticsViewForm,
  IGoogleAnalyticsViewPayload
} from "../../../../types/IAssociations";
import {associateGoogleAccount} from "../../../../services/AssociationService";
import {ErrorResponseDefault, parseRequestError} from "../../../../helpers/validation";
import {googleAnalyticsViewFormInitialValues} from "./initialValues";
import {googleAnalyticsViewFormValidationSchema} from "./validationSchema";

interface GoogleAnalyticsViewFormProps {
  accessToken?: string;
}

const intlPath = 'googleAnalyticsViewForm';

interface GoogleAnalyticsViewFormState {
  errorMessage?: string;
}

export class GoogleAnalyticsViewForm extends React.Component<GoogleAnalyticsViewFormProps & WithClasses,
    GoogleAnalyticsViewFormState> {
  public readonly state = {
    errorMessage: '',
  };


  public render() {
    const {classes} = this.props;

    return (
        <Formik
            initialValues={googleAnalyticsViewFormInitialValues}
            validationSchema={googleAnalyticsViewFormValidationSchema}
            onSubmit={this.handleGoogleAccountAssociation}
            render={({isSubmitting}) => (
                <Form noValidate={true}>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <FormControl margin="normal" required fullWidth>
                        <Field
                            component={TextField}
                            autoComplete="account_id"
                            id="account_id"
                            name="account_id"
                            margin="normal"
                            autoFocus
                            messagePrefix={intlPath}
                            disabled={isSubmitting}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl margin="normal" required fullWidth>
                        <Field
                            component={TextField}
                            autoComplete="property_id"
                            id="property_id"
                            name="property_id"
                            margin="normal"
                            messagePrefix={intlPath}
                            disabled={isSubmitting}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl margin="normal" required fullWidth>
                        <Field
                            component={TextField}
                            autoComplete="view_id"
                            id="view_id"
                            name="view_id"
                            margin="normal"
                            messagePrefix={intlPath}
                            disabled={isSubmitting}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} justify="flex-end">
                    {isSubmitting ? (
                        <CircularProgress/>
                    ) : (
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isSubmitting}
                        >
                          <Text id={`${intlPath}.submit`}/>
                        </Button>
                    )}
                  </Grid>
                </Form>
            )}
        />
    )
  }

  private handleGoogleAccountAssociation: SubmitFn<IGoogleAnalyticsViewForm> = (values, actions) => {
    const {accessToken} = this.props;
    const access_token = accessToken;

    if(access_token){
      const payload : IGoogleAnalyticsViewPayload = {...values, access_token};

      this.setState(
          {errorMessage: ''},
          () => {
            associateGoogleAccount(payload as IGoogleAnalyticsViewPayload).then(
                this.onAccountAssociateSuccess(payload, actions),
                this.onAccountAssociateFailure(payload, actions)
            );
          }
      );
    }

  };

  private onAccountAssociateSuccess: SubmitFn<IGoogleAnalyticsViewPayload> = (values, actions) => ({
    data,
  }: any) => {
    console.log(data);
  };

  private onAccountAssociateFailure: SubmitFn<IGoogleAnalyticsViewPayload> = (_, actions) => (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage, errors} = parseRequestError(response.data);
    this.setState({errorMessage}, () => {
      actions.setSubmitting(false);
      actions.setErrors(errors);
    });
  };
}
