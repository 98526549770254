import * as React from 'react';

import { PrivatePageWrapper } from '../../components/common/PrivatePageWrapper/connect';
import {
  withHelmet,
  WithHelmetProps,
} from '../../components/HOC/Helmet/withHelmet';
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router';
import { WebsitesListPage } from './List/ListWebsitesPage';
import { PRIVATE_ROUTES } from '../../constants/routes';
import { NewWebsitePage } from './New/NewWebsitePage';
import { EditWebsitePage } from './Edit/EditWebsitePage';
import { ViewWebsitePage } from './View/ViewWebsitePage';
import { ProductsListPage } from './Products/List/ListProductsPage';

export const Websites = ({ match }: WithHelmetProps & RouteComponentProps) => (
  <PrivatePageWrapper>
    <Switch>
      <Route
        path={`${match.url}/${PRIVATE_ROUTES.NEW}`}
        render={() => <NewWebsitePage messagePrefix="newWebsitesPage" />}
      />
      <Route
          path={`${match.url}/:id/${PRIVATE_ROUTES.PRODUCTS}`}
          render={() => <ProductsListPage messagePrefix="listProductsPage" />}
      />
      <Route
        path={`${match.url}/:id/${PRIVATE_ROUTES.EDIT}`}
        render={() => <EditWebsitePage messagePrefix="editWebsitesPage" />}
      />
      <Route
        path={`${match.url}/:id`}
        render={() => <ViewWebsitePage messagePrefix="viewWebsitesPage" />}
      />
      <Route
        exact
        path={match.url}
        render={() => <WebsitesListPage messagePrefix="listWebsitesPage" />}
      />

    </Switch>
  </PrivatePageWrapper>
);

export const WebsitesPageWithHelmet = withHelmet(Websites);

export const WebsitesPage = withRouter(WebsitesPageWithHelmet);
