import * as React from 'react';

import {
  Typography,
  Toolbar,
  Button,
  withStyles,
  Paper,
  Grid,
} from '@material-ui/core';

import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import {Text} from '../../../components/common/Text/Text';
import {CampaignForm} from '../../../components/common/CampaignForm/CampaignForm';
import {Formik, Form} from 'formik';
import {withRouter, RouteComponentProps} from 'react-router';
import {styles} from './CampaignPage.styles';
import {WithClasses} from '../../../types/WithClasses';
import {Loader} from '../../../components/common/Loader/Loader';
import {newCampaignFormInitialValues} from './initialValues';
import {newCampaignFormValidationSchema} from './validationSchema';
import {ICampaignForm, ICampaignPayload, IProductPayload, ISelectedAutoComplete} from '../../../types/ICampaign';
import {SubmitFn} from '../../../types/FormikSubmit';
import {
  ErrorResponseDefault,
  parseRequestError,
} from '../../../helpers/validation';
import {createCampaign, createProductList, renderImageVariants} from '../../../services/CampaignsService';
import {sendOnboardingStatus} from "../../../services/AccountService";
import {ONBOARDING_STEPS} from "../../../constants/onboarding";
import {getOnboardingUrl} from "../../../helpers/onboarding";
import Modal from "@bdenzer/react-modal";
import {storage} from "../../../services/StorageService";
import {FacebookViewForm} from "../../../components/common/FacebookForms/View/FacebookViewForm";
import {FacebookAccountForm} from "../../../components/common/FacebookForms/Account/FacebookAccountForm";

interface INewCampaignsState {
  isLoading: boolean;
  sendingErrorMessage?: string;
  websites?: ISelectedAutoComplete[];
  currentTab: number;
  shouldShowModal: boolean;
  accessToken: string;
}

const intlPath = 'newCampaignPage';

class Campaign extends React.Component<WithHelmetProps & WithClasses & RouteComponentProps,
    INewCampaignsState> {
  public readonly state = {
    isLoading: false,
    sendingErrorMessage: '',
    websites: undefined,
    currentTab: 0,
    shouldShowModal: false,
    accessToken: '',
  };

  componentDidMount() {
    const access_token = storage.get('access_token');

    if(access_token && access_token !== 'null'){
      this.setState({accessToken: access_token});
      storage.delete('access_token');
    }
  }

  public render() {
    const {classes, messagePrefix} = this.props;
    const {isLoading, sendingErrorMessage, websites, currentTab, shouldShowModal, accessToken} = this.state;

    if (isLoading) {
      return (
          <Loader
              isLoading={isLoading}
              loadingLabelKey={`${intlPath}.fetchingCampaign`}
          />
      );
    }
    return (
        <React.Fragment>
          <Formik
              initialValues={newCampaignFormInitialValues}
              validationSchema={newCampaignFormValidationSchema}
              onSubmit={this.onCampaignSave}
              render={formikProps => (
                  <Form noValidate={true} onSubmitCapture={() => this.validate(formikProps)}>
                    <Typography component="h1" variant="h5">
                      <Text id={`${intlPath}.title`}/>
                    </Typography>
                    <Toolbar disableGutters={true}>

                      <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          aria-label="Save campaign"
                          className={classes.button}
                      >
                        <Text id={`${intlPath}.saveCampaignButton`}/>
                      </Button>
                    </Toolbar>
                    <Paper className={classes.root}>
                      <CampaignForm {...formikProps} createForm={true} websites={websites}
                                    handleChange={this.handleTabChange} currentTab={currentTab}/>
                      <Grid container>
                        {sendingErrorMessage && (
                            <Grid item xs={12}>
                              <Typography color="error" align="right">
                                <Text id={sendingErrorMessage}/>
                              </Typography>
                            </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Form>
              )}
          />
          <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleOpenModal}
          >
            <Text id={`${messagePrefix}.facebook`}/>
          </Button>
          <Modal
              closeModal={this.handleCloseModal}
              shouldShowModal={shouldShowModal}
              title={`${messagePrefix}.facebookFormTitle`}
          >
            {accessToken === '' && (
                <FacebookAccountForm
                    classes={classes}
                />
            )}

            {accessToken !== '' && (
                <FacebookViewForm
                    classes={classes}
                    accessToken={accessToken}
                />
            )}
          </Modal>
        </React.Fragment>
    );
  }

  private handleOpenModal = () => {
    this.setState({shouldShowModal: true});
  };

  private handleCloseModal = () => {
    this.setState({shouldShowModal: false});
  };


  private handleTabChange = (event: React.ChangeEvent<{}>, currentTab: number) => {
    this.setState({currentTab});
  };

  private handleTabChange2 = (currentTab: number) => {
    this.setState({currentTab});
  };

  private validate = (formikProps: any) => {
    if (
        (
            formikProps.values.name.length > 8 &&
            formikProps.values.website.value &&
            formikProps.values.budget > 1000
        )
        &&
        (
            !formikProps.values.age_min ||
            !formikProps.values.age_max ||
            formikProps.values.locations.length === 0 ||
            formikProps.values.interests.length === 0
        )
    ) {
      this.handleTabChange2(1);
    }

  };

  private onCampaignSave: SubmitFn<ICampaignForm> = (values, actions) => {

    const payload: ICampaignPayload = {
      ...values,
    };

    this.setState(
        {
          sendingErrorMessage: '',
          isLoading: true,
        },
        () => {
          createCampaign(payload).then(
              this.onSaveSuccess(payload, actions),
              this.onSaveFailure(payload, actions)
          );
        }
    );
  };

  private onSaveSuccess: SubmitFn<ICampaignForm> = (_, actions) => ({
                                                                      data,
                                                                    }: any) => {
    const campaignUuid = data.uuid;

    if (_.products_list.length > 0) {
      const productPayload: IProductPayload = {
        product_product_lists: _.products_list,
      };

      createProductList(campaignUuid, productPayload as IProductPayload).then(
          this.onProductSaveSuccess(_, actions),
          this.onSaveFailure(_, actions)
      );
    } else {
      sendOnboardingStatus(ONBOARDING_STEPS.CREATE_CAMPAIGN).then(() => {
        const {history} = this.props;
        this.setState({sendingErrorMessage: ''}, () => {
          actions.setSubmitting(false);
          history.push(getOnboardingUrl(ONBOARDING_STEPS.CREATE_CAMPAIGN));
        });
      }, this.onSaveFailure(_, actions));
    }
  };

  private onProductSaveSuccess: SubmitFn<ICampaignForm> = (_, actions) => ({
                                                                             data,
                                                                           }: any) => {
    renderImageVariants(data.campaign_uuid);

    sendOnboardingStatus(ONBOARDING_STEPS.CREATE_CAMPAIGN).then(() => {
      const {history} = this.props;
      this.setState({sendingErrorMessage: ''}, () => {
        actions.setSubmitting(false);
        history.push(getOnboardingUrl(ONBOARDING_STEPS.CREATE_CAMPAIGN));
      });
    }, this.onSaveFailure(_, actions));
  };


  private onSaveFailure: SubmitFn<ICampaignForm> = (_, actions) => (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage, errors} = parseRequestError(response.data);
    this.setState(
        {sendingErrorMessage: errorMessage, isLoading: false},
        () => {
          actions.setSubmitting(false);
          actions.setErrors(errors);
        }
    );
  };
}

export const CampaignPageWithStyles = withStyles(styles)(Campaign);

export const CampaignPageWithHelmet = withHelmet(CampaignPageWithStyles);

export const CampaignPage = withRouter(CampaignPageWithHelmet);
