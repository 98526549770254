import * as React from 'react';
import { FormattedDate as IntlFormattedDate, FormattedRelative } from 'react-intl';

export const FormattedDate = (props: IntlFormattedDate.Props) => (
  <IntlFormattedDate {...props} />
);

export const DateRelative = (props: FormattedRelative.Props) => (
  <FormattedRelative {...props} />
);
