export enum ONBOARDING_STEPS {
  WELCOME = 0,
  USER_INFO = 1,
  CREATE_WEBSITE = 2,
  CREATE_CAMPAIGN = 3,
  SELECT_PRODUCTS = 4,
  PAYMENT_DETAILS = 5,
  SUMMARY = 6,
  DONE = -1,
}
