export function parseRequestError(errorResponse: IErrorResponse) {
  if (errorResponse.code !== 400) {
    return {
      errorMessage: 'genericErrorMessage',
      errors: {},
    };
  }
  const errorMessage = errorResponse.message;
  const errors = Object.getOwnPropertyNames(
    errorResponse.errors.children
  ).reduce((acc, fieldName) => {
    const val = errorResponse.errors.children[fieldName];
    if (val.errors) {
      return { ...acc, [fieldName]: val.errors.join('</br>') };
    }
    return acc;
  }, {});
  return {
    errorMessage,
    errors,
  };
}

export const ErrorResponseDefault: IErrorResponse = {
  message: '',
  code: 0,
  errors: {
    children: {},
  },
};

interface IErrorResponse {
  message: string;
  code: number;
  errors: {
    children: IFieldError;
  };
}

interface IFieldError {
  [key: string]: {
    errors?: string[];
  };
}
