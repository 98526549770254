import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
    createStyles({
      root: {
        width: '100%',
        padding: spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      header: {
        textAlign: 'center',
        marginBottom: spacing.unit * 3,
      },
      button: {
        marginTop: spacing.unit * 3,
      },
    });