import * as React from 'react';

import { Toolbar as MuiToolbar, Button, withStyles } from '@material-ui/core';

import { PRIVATE_ROUTES } from '../../../../constants/routes';

import { Text } from '../../../../components/common/Text/Text';
import { ButtonLink } from '../../../../components/common/ButtonLink/ButtonLink';
import { ICampaign } from '../../../../types/ICampaign';
import { styles } from './Toolbar.styles';
import { WithClasses } from '../../../../types/WithClasses';

interface ToolbarProps {
  campaign: ICampaign;
  onCampaignStop: () => void;
}

const intlPath = 'viewCampaignPage.toolbar';

export const ToolbarBase = ({
  classes,
  campaign,
  onCampaignStop,
}: ToolbarProps & WithClasses) => (
  <MuiToolbar disableGutters={true}>
    <ButtonLink
      to={`/${PRIVATE_ROUTES.CAMPAIGNS}`}
      variant="contained"
      color="primary"
      aria-label="Back"
      className={classes.button}
    >
      <Text id={`${intlPath}.backButton`} />
    </ButtonLink>
    <ButtonLink
      to={`/${PRIVATE_ROUTES.CAMPAIGNS}/${campaign.uuid}/${PRIVATE_ROUTES.EDIT}`}
      variant="contained"
      color="primary"
      aria-label="Edit campaign"
      className={classes.button}
    >
      <Text id={`${intlPath}.editCampaignButton`} />
    </ButtonLink>
    <Button
      variant="contained"
      color="primary"
      aria-label="Stop campaign"
      onClick={onCampaignStop}
      className={classes.button}
    >
      <Text id={`${intlPath}.stopCampaignButton`} />
    </Button>
  </MuiToolbar>
);

export const Toolbar = withStyles(styles)(ToolbarBase);
