import * as React from 'react';

import AsyncSelect from 'react-select/lib/Async';
import { TextFieldProps } from '@material-ui/core/TextField';
import { FieldProps } from 'formik';
import { Text } from '../../Text/Text';
import { withStyles } from '@material-ui/core';
import { styles } from './AutoComplete.styles';
import { WithClasses } from '../../../../types/WithClasses';
import { components } from './components';
import {ISelectedAutoComplete} from "../../../../types/ICampaign";

interface AutoCompleteProps {
  messagePrefix: string;
  loadOptions: () => Promise<any[]>;
  defaultOptions: ISelectedAutoComplete[];
  onChange: any;
  onBlur: any;
  isMulti: boolean;
}

class AutoCompleteBase extends React.Component<
  FieldProps & TextFieldProps & AutoCompleteProps & WithClasses
> {
  public render() {
    const {
      classes,
      loadOptions,
      defaultOptions,
      field,
      messagePrefix,
      form: { touched, errors, isSubmitting },
      disabled = false,
      isMulti = true,
    } = this.props;

    return (
      <AsyncSelect
        classes={classes}
        cacheOptions
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        textFieldProps={{
          label: <Text id={`${messagePrefix}.${field.name}.label`} />,
          error: touched[field.name] && errors[field.name],
          helperText: touched[field.name] && errors[field.name] && (
            <Text id={`${messagePrefix}.${field.name}.${errors[field.name]}`} />
          ),
          disabled: isSubmitting || disabled,
          InputLabelProps: {
            shrink: true,
          },
        }}
        components={components}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={field.value}
        isMulti={isMulti}
        placeholder=""
      />
    );
  }

  private handleChange = (value: any) => {
    // this is going to call setFieldValue and manually update values.[field.name]
    const { field, onChange } = this.props;
    onChange(field.name, value);
  };

  private handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.[field.name]
    const { field, onBlur } = this.props;
    onBlur(field.name, true);
  };
}

export const AutoComplete = withStyles(styles)(AutoCompleteBase);
