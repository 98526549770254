import * as React from 'react';

import {
  Paper,
  withStyles,
  Typography,
  Grid,
} from '@material-ui/core';


import {WithClasses} from '../../../types/WithClasses';

import {Text} from '../Text/Text';

import {styles} from './ProductVariantsList.styles';
import {ProductVariant} from "../ProductVariant/ProductVariant";
import {ICampaignProductGroup} from "../../../types/ICampaign";


//const intlPath = 'listCampaignsPage';


interface CampaignProductVariantListProps {
  productVariants: ICampaignProductGroup;
  onVariantChoose: (variant: any) => void;
  chosenVariants: any[];

}

interface ProductVariantsListState {
}


class ProductVariantListBase extends React.Component<CampaignProductVariantListProps & WithClasses, ProductVariantsListState> {

  public render() {
    const {classes, productVariants, chosenVariants} = this.props;

    return (
        <Paper className={classes.root}>
          <Typography className={classes.productName} component="h5" variant="h5">
            <Text id={productVariants.name}/>
          </Typography>
          <Grid container spacing={24}>
            {productVariants.variants.length && (
                <React.Fragment>
                  {productVariants.variants.map((variant: any) => (
                      <Grid item xs={3}>
                        <ProductVariant
                            variant={variant}
                            handleCheck={this.handleCheck}
                            chosenVariants={chosenVariants}
                        />
                      </Grid>
                  ))}
                </React.Fragment>
            )}
          </Grid>
        </Paper>
    );
  }

  handleCheck = (event: any) => {
    this.setState({checked: event.target.value});

    const {onVariantChoose} = this.props;

    if(onVariantChoose){
      const variant = {value: event.target.value, name: event.target.name};
      onVariantChoose(variant);
    }


  };

}

export const ProductVariantsList = withStyles(styles)(ProductVariantListBase);
