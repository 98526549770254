import * as React from 'react';


import {withHelmet, WithHelmetProps} from "../../components/HOC/Helmet/withHelmet";
import { RouteComponentProps, withRouter} from "react-router";
import {PrivatePageWrapper} from "../../components/common/PrivatePageWrapper/connect";


export const UserProfile = ({  }: WithHelmetProps & RouteComponentProps) => (
    <PrivatePageWrapper>
      User profile
    </PrivatePageWrapper>
);

export const UserProfilePageWithHelmet = withHelmet(UserProfile);

export const UserProfilePage = withRouter(UserProfilePageWithHelmet);
