import * as React from 'react';

import {ICampaign, ISelectedAutoComplete} from '../../../../types/ICampaign';
import {Typography, withStyles, Paper, Divider} from '@material-ui/core';
import {Text} from '../../../../components/common/Text/Text';
import {styles} from './Details.styles';
import {WithClasses} from '../../../../types/WithClasses';
import {ValueRow} from './ValueRow/ValueRow';

interface DetailsPops {
  campaign: ICampaign;
}

const intlPath = 'viewCampaignPage.details';

export const DetailsBase = ({
                              classes,
                              campaign,
                            }: WithClasses & DetailsPops) => (
    <React.Fragment>
      <Typography component="h2" variant="h6">
        <Text id={`${intlPath}.title`}/>
      </Typography>
      <Paper className={classes.root}>
        <div className={classes.wrapper}>
          <Typography component="h3" variant="h6">
            <Text id={`${intlPath}.general.title`}/>
          </Typography>
          <ValueRow
              labelKey={`${intlPath}.general.type`}
              value={campaign.type}
          />
          <Divider/>
          <ValueRow
              labelKey={`${intlPath}.general.website`}
              value={campaign.website_name}
          />
          <Divider/>
          <ValueRow
              labelKey={`${intlPath}.general.productsAmount`}
              value={campaign.products_count}
          />
          <Divider/>
        </div>
        <div className={classes.wrapper}>
          <Typography component="h3" variant="h6">
            <Text id={`${intlPath}.target.title`}/>
          </Typography>
          <ValueRow
              labelKey={`${intlPath}.target.localisation`}
              value={
                campaign.target[0].locations && campaign.target[0].locations
                    .map((location: ISelectedAutoComplete) => location.label).join(', ')
              }
          />
          <Divider/>
          <ValueRow
              labelKey={`${intlPath}.target.interest`}
              value={
                campaign.target[0].interests && campaign.target[0].interests
                    .map((interest: ISelectedAutoComplete) => interest.label).join(', ')
              }
          />
          <Divider/>
          <ValueRow
              labelKey={`${intlPath}.target.ageGroup`}
              value={`${campaign.target[0].age_min}-${campaign.target[0].age_max}`}
          />
          <Divider/>
        </div>
      </Paper>
    </React.Fragment>
);

export const Details = withStyles(styles)(DetailsBase);
