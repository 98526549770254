import * as React from 'react';
import { FormControl, withStyles, Grid } from '@material-ui/core';
import { Field } from 'formik';

import { styles } from './TargetForm.styles';
import { WithClasses } from '../../../../types/WithClasses';
import { TextField } from '../../form/TextField/TextField';
import { AutoComplete } from '../../form/AutoComplete/AutoComplete';
import { fetchInterests, fetchLocations } from '../../../../services/FacebookService';

interface CampaignMainFormProps {
  isSubmitting: boolean;
  onChange: any;
  onBlur: any;
}

const intlPath = 'campaignMainForm';

const TargetFormBase = ({
  classes,
  isSubmitting,
  onChange,
  onBlur,
}: WithClasses & CampaignMainFormProps) => (
  <Grid container spacing={24}>
    <Grid item sm={6} xs={12}>
      <FormControl margin="normal" required fullWidth>
        <Field
          component={TextField}
          id="age_min"
          name="age_min"
          margin="normal"
          messagePrefix={intlPath}
          disabled={isSubmitting}
        />
      </FormControl>
    </Grid>
    <Grid item sm={6} xs={12}>
      <FormControl margin="normal" required fullWidth>
        <Field
          component={TextField}
          id="age_max"
          name="age_max"
          margin="normal"
          messagePrefix={intlPath}
          disabled={isSubmitting}
        />
      </FormControl>
    </Grid>
    <Grid item sm={6} xs={12}>
      <FormControl margin="normal" required fullWidth>
        <Field
          component={AutoComplete}
          id="locations"
          name="locations"
          margin="normal"
          messagePrefix={intlPath}
          disabled={isSubmitting}
          loadOptions={fetchLocations}
          onChange={onChange}
          onBlur={onBlur}
        />
      </FormControl>
    </Grid>
    <Grid item sm={6} xs={12}>
      <FormControl margin="normal" required fullWidth>
        <Field
          component={AutoComplete}
          id="interests"
          name="interests"
          margin="normal"
          messagePrefix={intlPath}
          disabled={isSubmitting}
          loadOptions={fetchInterests}
          onChange={onChange}
          onBlur={onBlur}
        />
      </FormControl>
    </Grid>
  </Grid>
);

export const TargetForm = withStyles(styles)(TargetFormBase);
