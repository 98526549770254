import { INewUser, IResetPassword, INewPassword } from '../types/IUser';
import { AppAPI } from './APIService';

export const register = ({ email, password }: INewUser) =>
  AppAPI.post('/user', {
    email,
    password,
  });

export const resetPassword = (requestData: IResetPassword) =>
  AppAPI.post('/user/password/request', requestData);

export const newPassword = (
  email: string,
  token: string,
  { password }: INewPassword
) =>
  AppAPI.post(`/user/password/reset/${email}/${token}`, {
    password,
  });
