export interface IProduct {
  uuid: string;
  id: number;
  product_id: string;
  title: string;
  price: string;
  category: string;
  stock: number;
  status: IProductStatus;
}

export enum IProductStatus {
  OK = 1,
  NOT_OK = 2,
}
