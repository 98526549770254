export enum ActionType {
  SetLanguage = 'SET_LANGUAGE',
}

export interface ISetLanguage {
  type: ActionType.SetLanguage;
  payload: string;
}
export const setLanguage = (payload: string): ISetLanguage => ({
  payload,
  type: ActionType.SetLanguage,
});

export type Action = ReturnType<typeof setLanguage>;
