import { createSelector } from 'reselect';

import { IAccountState } from '.';
import { IRootState } from '../rootReducer';

const createAccountSelector = createSelector(
  (state: IRootState): IAccountState => state.account,
  (account: IAccountState): IAccountState => account
);

export const selectAccount = createSelector(
  createAccountSelector,
  (state: IAccountState) => state.account
);
