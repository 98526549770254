import * as React from 'react';

import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { FieldProps } from 'formik';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { Text } from '../../Text/Text';

interface Props {
  messagePrefix: string;
}

export const Checkbox = ({
  field,
  form: { touched, errors, isSubmitting },
  disabled = false,
  messagePrefix,
  ...props
}: FieldProps & CheckboxProps & Props) => (
  <React.Fragment>
    <FormControlLabel
      control={
        <MaterialCheckbox
          {...props}
          {...field}
          disabled={isSubmitting || disabled}
          checked={field.value ? 'checked' : ''}
          value={field.value ? 'checked' : ''}
        />
      }
      label={<Text id={`${messagePrefix}.${field.name}.label`} />}
    />
    {Boolean(touched[field.name] && errors[field.name]) && (
      <FormHelperText error={true}>
        <Text id={`${messagePrefix}.${field.name}.${errors[field.name]}`} />
      </FormHelperText>
    )}
  </React.Fragment>
);
