import * as React from 'react';
import { withStyles, Paper, Typography, Link } from '@material-ui/core';

import {
  withHelmet,
  WithHelmetProps,
} from '../../components/HOC/Helmet/withHelmet';
import { WithClasses } from '../../types/WithClasses';
import { styles } from './ResetPasswordPage.styles';
import { PublicPageWrapper } from '../../components/common/PublicPageWrapper/PublicPageWrapper';
import { ResetPasswordForm } from './ResetPasswordForm/ResetPasswordForm';
import { LinkToLogin } from '../../components/common/LinkToLogin/LinkToLogin';
import { RouteComponentProps, withRouter } from 'react-router';
import { SubmitFn } from '../../types/FormikSubmit';
import { IResetPassword } from '../../types/IUser';
import { resetPassword } from '../../services/UserService';
import { Text } from '../../components/common/Text/Text';

interface ResetPasswordState {
  success: boolean;
}

const intlPath = 'resetPasswordPage';

class ResetPassword extends React.Component<
  WithHelmetProps & WithClasses & RouteComponentProps,
  ResetPasswordState
> {
  public readonly state = {
    success: false,
  };

  public render() {
    const { classes } = this.props;
    const { success } = this.state;
    return (
      <PublicPageWrapper>
        <Paper className={classes.paper}>
          {success ? (
            <React.Fragment>
              <Typography component="h1" variant="h5">
                <Text id={`${intlPath}.success.title`} />
              </Typography>
              <Typography component="p">
                <Text id={`${intlPath}.success.subtitle`} />
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography component="h1" variant="h5">
                <Text id={`${intlPath}.title`} />
              </Typography>
              <ResetPasswordForm onResetPassword={this.doResetPassword} />
              <Typography className={classes.loginLink}>
                <Link component={LinkToLogin}>
                  <Text id={`${intlPath}.loginLink`} />
                </Link>
              </Typography>
            </React.Fragment>
          )}
        </Paper>
      </PublicPageWrapper>
    );
  }

  private doResetPassword: SubmitFn<IResetPassword> = (
    values,
    actions
  ) => {
    resetPassword(values).then(
      this.onResetPasswordSuccess(values, actions),
      this.onResetPasswordFailure(values, actions)
    );
  };

  private onResetPasswordSuccess: SubmitFn<IResetPassword> = (
    _,
    actions
  ) => () => {
    actions.setSubmitting(false);
    actions.setStatus({ submitErrorMessage: '' });
    this.setState({ success: true });
  };

  private onResetPasswordFailure: SubmitFn<IResetPassword> = (
    _,
    actions
  ) => ({ response } = { response: {} } as any) => {
    actions.setSubmitting(false);
    const submitErrorMessage =
      response && response.data.msg
        ? response && response.data.msg
        : 'genericErrorMessage';
    actions.setStatus({ submitErrorMessage });
  };
}

const ResetPasswordWithStyles = withStyles(styles)(ResetPassword);

const ResetPasswordWithHelmet = withHelmet(ResetPasswordWithStyles);

export const ResetPasswordPage = withRouter(ResetPasswordWithHelmet);
