import * as React from 'react';

import {Typography, Button, withStyles, Toolbar, Grid, Paper} from '@material-ui/core';


import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import {Loader} from '../../../components/common/Loader/Loader';
import {Text} from '../../../components/common/Text/Text';
import {Error} from '../../../components/common/Error/Error';
import {withRouter, RouteComponentProps} from 'react-router';
import {styles} from './NewCampaignAdsPage.styles';
import {WithClasses} from '../../../types/WithClasses';
import {createCampaignAds, fetchCampaignVariants} from "../../../services/CampaignsService";
import {ErrorResponseDefault, parseRequestError} from "../../../helpers/validation";
import {ICampaignVariantsForm, ICampaignProductGroup} from "../../../types/ICampaign";
import {ButtonLink} from "../../../components/common/ButtonLink/ButtonLink";
import {PRIVATE_ROUTES} from "../../../constants/routes";
import {Form, Formik} from "formik";
import {newCampaignVariantsFormInitialValues} from "./initialValues";
import {newCampaignVariantsFormValidationSchema} from "./validationSchema";
import {SubmitFn} from "../../../types/FormikSubmit";
import {VariantsForm} from "../../../components/common/VariantsForm/VariantsForm";


interface ICampaignProductVariantState {
  isLoading: boolean;
  errorMessage?: string;
  campaignVariants: ICampaignProductGroup[];
  sendingErrorMessage?: string;
}

const intlPath = 'campaignVariantsPage';

class NewCampaignAds extends React.Component<WithHelmetProps & WithClasses & RouteComponentProps,
    ICampaignProductVariantState> {

  public readonly state = {
    isLoading: false,
    error: undefined,
    campaignVariants: [],
    sendingErrorMessage: '',
  };

  public componentDidMount() {
    this.fetchCampaignVariants();
  }

  public render() {
    const {classes} = this.props;
    const {isLoading, error, campaignVariants, sendingErrorMessage} = this.state;

    if (isLoading) {
      return (
          <Loader
              isLoading={isLoading}
              loadingLabelKey={`${intlPath}.fetchingCampaign`}
          />
      );
    }

    if (error) {
      return (
          <Error message={`${intlPath}.requestError.message`}>
            <Button
                className={classes.retryButton}
                type="button"
                variant="contained"
                color="primary"
                onClick={this.fetchCampaignVariants}
            >
              <Text id={`${intlPath}.requestError.retryButton`}/>
            </Button>
          </Error>
      );
    }

    return (
        <Formik
            initialValues={newCampaignVariantsFormInitialValues}
            validationSchema={newCampaignVariantsFormValidationSchema}
            onSubmit={this.onVariantsSave}
            render={formikProps => (
                <Form noValidate={true}>
                  <Typography component="h1" variant="h5">
                    <Text id={`${intlPath}.title`}/>
                  </Typography>
                  <Toolbar disableGutters={true}>
                    <ButtonLink
                        to={`/${PRIVATE_ROUTES.CAMPAIGNS}`}
                        variant="contained"
                        color="primary"
                        aria-label="Campaigns"
                    >
                      <Text id={`${intlPath}.back`}/>
                    </ButtonLink>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        aria-label="Save campaign"
                        className={classes.button}
                    >
                      <Text id={`${intlPath}.saveCampaignVariantsButton`}/>
                    </Button>
                  </Toolbar>
                  {campaignVariants.length === 0 ? (
                          <React.Fragment>
                            <Typography>
                              <Text id={`${intlPath}.noImageVariants`}/>
                            </Typography>
                          </React.Fragment>
                      )
                      :
                      (
                          <Paper>
                            <VariantsForm
                                {...formikProps}
                                campaignVariants={campaignVariants}
                            />
                            <Grid container>
                              {sendingErrorMessage && (
                                  <Grid item xs={12}>
                                    <Typography color="error" align="right">
                                      <Text id={sendingErrorMessage}/>
                                    </Typography>
                                  </Grid>
                              )}
                            </Grid>
                          </Paper>
                      )
                  }

                </Form>
            )}
        />
    );
  }

  private onVariantsSave: SubmitFn<ICampaignVariantsForm> = (values, actions) => {
    const {match} = this.props;
    const campaignUuid: string = (match.params as any).id;

    this.setState(
        {
          sendingErrorMessage: '',
          isLoading: true,
        },
        () => {
          createCampaignAds(campaignUuid, values).then(
              this.onSaveSuccess(values, actions),
              this.onSaveFailure(values, actions)
          );
        }
    );
  };

  private fetchCampaignVariants = () => {
    const {match} = this.props;
    const campaignId: string = (match.params as any).id;
    this.setState({isLoading: true, errorMessage: '', campaignVariants: []}, () =>
        fetchCampaignVariants(campaignId).then(this.onFetchSuccess, this.onFetchFailure)
    );
  };

  private onFetchSuccess = ({data}: any) => {
    this.setState({campaignVariants: data, isLoading: false});
  };

  private onFetchFailure = (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage} = parseRequestError(response.data);
    this.setState({isLoading: false, errorMessage});
  };

  private onSaveSuccess: SubmitFn<ICampaignVariantsForm> = (_, actions) => ({
                                                                              data,
                                                                            }: any) => {
    const {history, match} = this.props;
    const campaignId: string = (match.params as any).id;

    this.setState({sendingErrorMessage: '', isLoading: false}, () => {
      actions.setSubmitting(false);
      history.push(`/${PRIVATE_ROUTES.CAMPAIGNS}/${campaignId}/${PRIVATE_ROUTES.ADS}`);
    });
  };

  private onSaveFailure: SubmitFn<ICampaignVariantsForm> = (_, actions) => (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage, errors} = parseRequestError(response.data);
    this.setState(
        {sendingErrorMessage: errorMessage, isLoading: false},
        () => {
          actions.setSubmitting(false);
          actions.setErrors(errors);
        }
    );
  };
}

export const NewCampaignAdsPageWithStyles = withStyles(styles)(NewCampaignAds);

export const NewCampaignAdsPageWithHelmet = withHelmet(
    NewCampaignAdsPageWithStyles
);

export const NewCampaignAdsPage = withRouter(NewCampaignAdsPageWithHelmet);
