import * as React from 'react';
import { FormControl, Button, withStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';

import { WithClasses } from '../../../types/WithClasses';
import { styles } from './ResetPasswordForm.styles';
import { SubmitFn } from '../../../types/FormikSubmit';
import { IResetPassword } from '../../../types/IUser';
import { TextField } from '../../../components/common/form/TextField/TextField';
import { resetPasswordFormInitialValues } from './initialValues';
import { resetPasswordFormValidationSchema } from './validationSchema';
import { Text } from '../../../components/common/Text/Text';

interface ResetPasswordProps {
  onResetPassword: SubmitFn<IResetPassword>;
}

const intlPath = 'resetPasswordPage.form';

const ResetPasswordFormBase = ({
  classes,
  onResetPassword,
}: WithClasses & ResetPasswordProps) => (
  <Formik
    initialValues={resetPasswordFormInitialValues}
    validationSchema={resetPasswordFormValidationSchema}
    onSubmit={onResetPassword}
    render={({ isSubmitting, status }) => (
      <Form className={classes.form} noValidate={true}>
        <FormControl margin="normal" required fullWidth>
          <Field
            component={TextField}
            autoComplete="email"
            id="email"
            name="email"
            margin="normal"
            messagePrefix={intlPath}
            autoFocus
          />
        </FormControl>
        {status && status.submitErrorMessage && (
          <Typography className={classes.formSubmitError} color="error">
            <Text id={status.submitErrorMessage} />
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting}
        >
          <Text id={`${intlPath}.submit`} />
        </Button>
      </Form>
    )}
  />
);

export const ResetPasswordForm = withStyles(styles)(ResetPasswordFormBase);
