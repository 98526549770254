import * as React from 'react';

import { RadioGroup as MaterialRadioGroup } from '@material-ui/core';

import { RadioGroupProps } from '@material-ui/core/RadioGroup';
import { FieldProps } from 'formik';

export const RadioGroup = ({
  field,
  form,
  ...props
}: FieldProps & RadioGroupProps) => {
  return <MaterialRadioGroup {...field} {...props} />;
};
