import * as React from 'react';
import { List } from '@material-ui/core';

import { navigationItems } from '../../../../constants/navigation';
import { ListItemLink } from './NavigationItem/NavigationItem';

export const Navigation = () => (
  <List>
    {navigationItems.map(item => (
      <ListItemLink key={item.label} {...item} />
    ))}
  </List>
);
