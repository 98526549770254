import { IRoute, INestedRoute } from '../types/IRoute';
import { PUBLIC_ROUTES, PRIVATE_ROUTES } from './routes';
import { LoginPage } from '../pages/Login/LoginPage';
import { RegisterPage } from '../pages/Register/RegisterPage';
import { DashboardPage } from '../pages/Dashboard/DashboardPage';
import { CampaignsPage } from '../pages/Campaings/CampaignsPage';
import { WebsitesPage } from '../pages/Websites/WebsitesPage';
import { OnboardingPage } from '../pages/Onboarding/OnboardingPage';
import { RegisterConfirmationSuccessPage } from '../pages/RegisterConfirmationSuccess/RegisterConfirmationSuccessPage';
import { RegisterConfirmationFailurePage } from '../pages/RegisterConfirmationFailure/RegisterConfirmationFailurePage';
import { ResetPasswordPage } from '../pages/ResetPassword/ResetPasswordPage';
import { NewPasswordPage } from '../pages/NewPassword/NewPasswordPage';
import { WelcomePage } from '../pages/Onboarding/Welcome/WelcomePage';
import { DonePage } from '../pages/Onboarding/Done/DonePage';
import { WebsitePage } from '../pages/Onboarding/Website/WebsitePage';
import { PaymentPage } from '../pages/Onboarding/Payment/PaymentPage';
import { CampaignPage } from '../pages/Onboarding/Campaign/CampaingPage';
import { UserProfilePage } from "../pages/Profile/UserProfilePage";
import { SettingsPage } from "../pages/Settings/SettingsPage";

export const DEFAULT_ROUTE: string = `/${PUBLIC_ROUTES.LOGIN}`;

export const GLOBAL_ROUTES: IRoute[] = [
  {
    Component: LoginPage,
    messagePrefix: 'loginPage',
    path: `/${PUBLIC_ROUTES.LOGIN}`,
  },
  {
    Component: RegisterPage,
    messagePrefix: 'registerPage',
    path: `/${PUBLIC_ROUTES.REGISTER}`,
  },
  {
    Component: RegisterConfirmationSuccessPage,
    messagePrefix: 'registerConfirmationSuccessPage',
    path: `/${PUBLIC_ROUTES.REGISTER_CONFIRMATION_SUCCESS}`,
  },
  {
    Component: RegisterConfirmationFailurePage,
    messagePrefix: 'registerConfirmationFailurePage',
    path: `/${PUBLIC_ROUTES.REGISTER_CONFIRMATION_FAILURE}`,
  },
  {
    Component: ResetPasswordPage,
    messagePrefix: 'resetPasswordPage',
    path: `/${PUBLIC_ROUTES.RESET_PASSWORD}`,
  },
  {
    Component: NewPasswordPage,
    messagePrefix: 'newPasswordPage',
    path: `/${PUBLIC_ROUTES.NEW_PASSWORD}`,
  },
  {
    Component: DashboardPage,
    messagePrefix: 'dashboardPage',
    path: `/${PRIVATE_ROUTES.DASHBOARD}`,
  },
  {
    Component: OnboardingPage,
    messagePrefix: 'onboardingPage',
    path: `/${PRIVATE_ROUTES.ONBOARDING}`,
  },
  {
    Component: CampaignsPage,
    messagePrefix: 'listCampaignsPage',
    path: `/${PRIVATE_ROUTES.CAMPAIGNS}`,
  },
  {
    Component: WebsitesPage,
    messagePrefix: 'listWebsitesPage',
    path: `/${PRIVATE_ROUTES.WEBSITES}`,
  },
  {
    Component: UserProfilePage,
    messagePrefix: 'profilePage',
    path: `/${PRIVATE_ROUTES.PROFILE}`,
  },
  {
    Component: SettingsPage,
    messagePrefix: 'settingsPage',
    path: `/${PRIVATE_ROUTES.SETTINGS}`,
  }
];

export const ONBOARDING_NESTED_ROUTES: INestedRoute[] = [
  {
    Component: WelcomePage,
    exact: true,
    messagePrefix: 'welcomePage',
    path: matchUrl => matchUrl,
  },
  {
    Component: WebsitePage,
    messagePrefix: 'websitePage',
    path: matchUrl => `${matchUrl}/${PRIVATE_ROUTES.ECOMMERCE}`,
  },
  {
    Component: CampaignPage,
    messagePrefix: 'campaignPage',
    path: matchUrl => `${matchUrl}/${PRIVATE_ROUTES.CAMPAIGN}`,
  },
  {
    Component: PaymentPage,
    messagePrefix: 'paymentPage',
    path: matchUrl => `${matchUrl}/${PRIVATE_ROUTES.PAYMENT}`,
  },
  {
    Component: DonePage,
    messagePrefix: 'donePage',
    path: matchUrl => `${matchUrl}/${PRIVATE_ROUTES.DONE}`,
  },
];
