import React from 'react';

import { TextField as MaterialTextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { FieldProps } from 'formik';
import { Text } from '../../Text/Text';

interface Props {
  messagePrefix: string;
}

export const TextField = ({
  field,
  form: { touched, errors, isSubmitting },
  disabled = false,
  messagePrefix,
  ...props
}: FieldProps & TextFieldProps & Props) => {
  return (
    <MaterialTextField
      {...props}
      {...field}
      label={<Text id={`${messagePrefix}.${field.name}.label`} />}
      error={Boolean(touched[field.name] && errors[field.name])}
      helperText={
        touched[field.name] &&
        errors[field.name] && (
          <Text id={`${messagePrefix}.${field.name}.${errors[field.name]}`} />
        )
      }
      disabled={isSubmitting || disabled}
    />
  );
};
