import * as React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  Typography,
} from '@material-ui/core';

import { PRIVATE_ROUTES } from '../../../../constants/routes';

import { IWebsite } from '../../../../types/IWebsite'; //IFeed
import { WithClasses } from '../../../../types/WithClasses';

import {
  ColumnType,
  TableHeader,
} from '../../../../components/common/table/TableHeader/TableHeader';
import { Text } from '../../../../components/common/Text/Text';
import { ButtonLink } from '../../../../components/common/ButtonLink/ButtonLink';

import { styles } from './WebsitesTable.styles';
import { Edit, Search } from '@material-ui/icons';
import { IconLink } from '../../../../components/common/IconLink/IconLink';
import { DateRelative } from '../../../../components/common/Date/Date';

const intlPath = 'listWebsitesPage';
const columnsIntlPath = `${intlPath}.columns`;

const columns: ColumnType[] = [
  { name: 'uuid', labelKey: `${columnsIntlPath}.uuid`, padding: 'dense' },
  { name: 'name', labelKey: `${columnsIntlPath}.name` },
  { name: 'url', labelKey: `${columnsIntlPath}.url` },
  { name: 'last_import_date', labelKey: `${columnsIntlPath}.last_import_date` },
  {
    name: 'products_count',
    labelKey: `${columnsIntlPath}.products_count`,
    align: 'right',
  },
  {
    name: 'campaigns_count',
    labelKey: `${columnsIntlPath}.campaigns_count`,
    align: 'right',
  },
  { name: 'actions', labelKey: `${columnsIntlPath}.actions`, padding: 'dense' },
];

interface WebsitesTableProps {
  websites: IWebsite[];
}

class WebsitesTableBase extends React.Component<
  WebsitesTableProps & WithClasses
> {
  public render() {
    const { classes, websites } = this.props;

    console.log(websites);

    return (
      <Paper className={classes.root}>
        {websites.length ? (
          <div className={classes.tableWrapper}>
            <Table aria-labelledby="tableTitle" padding="checkbox">
              <TableHeader columns={columns} />
              <TableBody>
                {websites.map(website => (
                  <TableRow hover key={website.uuid}>
                    <TableCell padding="dense" component="th" scope="row">
                      {website.uuid}
                    </TableCell>
                    <TableCell>{website.name}</TableCell>
                    <TableCell>{website.url}</TableCell>
                    <TableCell>
                      {website.feeds && website.feeds.last_import_date ? (
                        <DateRelative
                          value={new Date(website.feeds.last_import_date)}
                          units="day"
                          updateInterval={undefined}
                        />
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {website.feeds && website.feeds.products_count}
                    </TableCell>
                    <TableCell align="right">
                      {website.campaigns_count}
                    </TableCell>
                    <TableCell className={classes.actionsColumn}>
                      <IconLink
                        aria-label="Details"
                        to={`${PRIVATE_ROUTES.WEBSITES}/${website.uuid}`}
                      >
                        <Search />
                      </IconLink>
                      <IconLink
                        aria-label="Edit website"
                        to={`${PRIVATE_ROUTES.WEBSITES}/${website.uuid}/${
                          PRIVATE_ROUTES.EDIT
                        }`}
                      >
                        <Edit />
                      </IconLink>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className={classes.noDataWrapper}>
            <Typography component="p">
              <Text id={`${intlPath}.noData`} />
            </Typography>
            <ButtonLink
              to={`/${PRIVATE_ROUTES.WEBSITES}/${PRIVATE_ROUTES.NEW}`}
              variant="contained"
              color="primary"
              aria-label="New website"
            >
              <Text id={`${intlPath}.createWebsite`} />
            </ButtonLink>
          </div>
        )}
      </Paper>
    );
  }
}

// function sumProducts(feeds: IFeed[] = []) {
//   return feeds.reduce((sum, feed) => sum + feed.product_count, 0);
// }

export const WebsitesTable = withStyles(styles)(WebsitesTableBase);
