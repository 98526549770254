import * as React from 'react';

import { withStyles, Paper, Typography } from '@material-ui/core';

import { WithClasses } from '../../../types/WithClasses';

import { Text } from '../Text/Text';

import { styles } from './Error.styles';

interface ErrorProps {
  message: string;
  children?: React.ReactNode;
}

export const ErrorBase = ({ classes, message,children }: WithClasses & ErrorProps) => (
  <Paper className={classes.root}>
    <div className={classes.wrapper}>
      <Typography component="p">
        <Text id={message} />
      </Typography>
      {children}
    </div>
  </Paper>
);

export const Error = withStyles(styles)(ErrorBase);
