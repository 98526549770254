import * as React from 'react';

import {Typography, Button, withStyles, Toolbar, Paper, CardMedia, Grid} from '@material-ui/core';


import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import {Loader} from '../../../components/common/Loader/Loader';
import {Text} from '../../../components/common/Text/Text';
import {Error} from '../../../components/common/Error/Error';
import {withRouter, RouteComponentProps} from 'react-router';
import {styles} from './ListCampaignAdsPage.styles';
import {WithClasses} from '../../../types/WithClasses';
import {ButtonLink} from "../../../components/common/ButtonLink/ButtonLink";
import {PRIVATE_ROUTES} from "../../../constants/routes";
import {ErrorResponseDefault, parseRequestError} from "../../../helpers/validation";
import {fetchCampaignAds} from "../../../services/CampaignsService";
import {IProduct} from "../../../types/IProduct";


interface ICampaignProductVariantState {
  isLoading: boolean;
  errorMessage?: string;
  campaignAds?: any[];
}

const intlPath = 'campaignAdsPage';

class ListCampaignAds extends React.Component<WithHelmetProps & WithClasses & RouteComponentProps,
    ICampaignProductVariantState> {

  public readonly state = {
    isLoading: false,
    error: undefined,
    campaignAds: [],
  };

  public componentDidMount() {
    this.fetchCampaignAds();
  }

  public render() {
    const {classes} = this.props;
    const {isLoading, error, campaignAds} = this.state;

    if (isLoading) {
      return (
          <Loader
              isLoading={isLoading}
              loadingLabelKey={`${intlPath}.fetchingCampaign`}
          />
      );
    }

    if (error) {
      return (
          <Error message={`${intlPath}.requestError.message`}>
            <Button
                className={classes.retryButton}
                type="button"
                variant="contained"
                color="primary"
                onClick={this.fetchCampaignAds}
            >
              <Text id={`${intlPath}.requestError.retryButton`}/>
            </Button>
          </Error>
      );
    }

    return (
        <React.Fragment>
          <Typography component="h1" variant="h5">
            <Text id={`${intlPath}.title`}/>
          </Typography>
          <Toolbar disableGutters={true}>
            <ButtonLink
                to={`/${PRIVATE_ROUTES.CAMPAIGNS}`}
                variant="contained"
                color="primary"
                aria-label="Campaigns"
            >
              <Text id={`${intlPath}.back`}/>
            </ButtonLink>
            <ButtonLink
                to={`/${PRIVATE_ROUTES.CAMPAIGNS}`}
                onClick={this.clickTest}
                variant="contained"
                color="primary"
                aria-label="Campaigns"
            >
              <Text id={`${intlPath}.startCampaign`}/>
            </ButtonLink>
          </Toolbar>

          {campaignAds.map((ad: any) => (
                  <Paper className={classes.adsContainer}>
                    {ad.products.map((product: IProduct) => (
                        <Typography component="h1" variant="h5">
                          <Text id={product.title}/>
                        </Typography>
                    ))}
                    <Grid container >
                      {ad.ad_variants.map((variant: any) => (
                          <Grid item xs={3} className={classes.productItem}>
                            {variant.images.map((image: any) => (
                                <React.Fragment>
                                  <Typography>
                                    <Text id={image.name}/>
                                  </Typography>
                                  {image.variants.map((imageVariant: any) => (
                                      <React.Fragment>
                                        <CardMedia
                                            className={classes.media}
                                            image={require(`../../../assets/photos/image-ad-variants/${imageVariant.feed_uuid}/${imageVariant.image_name}/${imageVariant.image_src}`)}
                                            title="Contemplative Reptile"
                                        />
                                      </React.Fragment>
                                  ))}
                                </React.Fragment>
                            ))}
                          </Grid>

                      ))}
                    </Grid>
                  </Paper>

              )
          )}

        </React.Fragment>
    );
  }

  private clickTest = () => {
    console.log('works!');
  };

  private fetchCampaignAds = () => {
    const {match} = this.props;
    const campaignId: string = (match.params as any).id;
    this.setState({isLoading: true, errorMessage: '', campaignAds: []}, () =>
        fetchCampaignAds(campaignId).then(this.onFetchSuccess, this.onFetchFailure)
    );
  };

  private onFetchSuccess = ({data}: any) => {
    console.log(data);
    this.setState({campaignAds: data, isLoading: false});
  };

  private onFetchFailure = (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage} = parseRequestError(response.data);
    this.setState({isLoading: false, errorMessage});
  };

}

export const ListCampaignAdsPageWithStyles = withStyles(styles)(ListCampaignAds);

export const ListCampaignAdsPageWithHelmet = withHelmet(
    ListCampaignAdsPageWithStyles
);

export const ListCampaignAdsPage = withRouter(ListCampaignAdsPageWithHelmet);
