import * as React from 'react';

import {
  Typography,
  Toolbar,
  Button,
  withStyles,
  Paper,
  Grid,
} from '@material-ui/core';

import { PRIVATE_ROUTES } from '../../../constants/routes';

import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import { Text } from '../../../components/common/Text/Text';
import { ButtonLink } from '../../../components/common/ButtonLink/ButtonLink';
import { CampaignForm } from '../../../components/common/CampaignForm/CampaignForm';
import { Formik, Form } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router';
import { styles } from './NewCampaignPage.styles';
import { WithClasses } from '../../../types/WithClasses';
import { Loader } from '../../../components/common/Loader/Loader';
import { newCampaignFormInitialValues } from './initialValues';
import { newCampaignFormValidationSchema } from './validationSchema';
import {ICampaignForm, ICampaignPayload, IProductPayload, ISelectedAutoComplete} from '../../../types/ICampaign';
import { SubmitFn } from '../../../types/FormikSubmit';
import {
  ErrorResponseDefault,
  parseRequestError,
} from '../../../helpers/validation';
import {createCampaign, createProductList, renderImageVariants} from '../../../services/CampaignsService';
import {fetchWebsites} from "../../../services/WebsiteService";
import {IWebsite} from "../../../types/IWebsite";
import {Error} from "../../../components/common/Error/Error";

interface INewCampaignsState {
  isLoading: boolean;
  sendingErrorMessage?: string;
  loadingErrorMessage?: string;
  websites?: ISelectedAutoComplete[];
  currentTab: number;
}

const intlPath = 'newCampaignPage';

class NewCampaign extends React.Component<
  WithHelmetProps & WithClasses & RouteComponentProps,
  INewCampaignsState
> {
  public readonly state = {
    isLoading: false,
    sendingErrorMessage: '',
    loadingErrorMessage: '',
    websites: undefined,
    currentTab: 0,
  };

  public componentDidMount() {
    this.fetchWebsites();
  }

  public render() {
    const { classes } = this.props;
    const { isLoading, sendingErrorMessage, loadingErrorMessage, websites, currentTab } = this.state;

    if (loadingErrorMessage) {
      <Error message={`${intlPath}.requestError.message`}>
        <Button
            className={classes.retryButton}
            type="button"
            variant="contained"
            color="primary"
            onClick={this.fetchWebsites}
        >
          <Text id={`${intlPath}.requestError.retryButton`}/>
        </Button>
      </Error>;
    }

    if (isLoading) {
      return (
        <Loader
          isLoading={isLoading}
          loadingLabelKey={`${intlPath}.fetchingCampaign`}
        />
      );
    }

    if(websites){
      return (
          <Formik
              initialValues={newCampaignFormInitialValues}
              validationSchema={newCampaignFormValidationSchema}
              onSubmit={this.onCampaignSave}
              render={formikProps => (
                  <Form noValidate={true} onSubmitCapture={() => this.validate(formikProps)}>
                    <Typography component="h1" variant="h5">
                      <Text id={`${intlPath}.title`} />
                    </Typography>
                    <Toolbar disableGutters={true}>
                      <ButtonLink
                          to={`/${PRIVATE_ROUTES.CAMPAIGNS}`}
                          variant="contained"
                          color="primary"
                          aria-label="Back"
                          className={classes.button}
                      >
                        <Text id={`${intlPath}.back`} />
                      </ButtonLink>
                      <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          aria-label="Save campaign"
                          className={classes.button}
                      >
                        <Text id={`${intlPath}.saveCampaignButton`} />
                      </Button>
                    </Toolbar>
                    <Paper className={classes.root}>
                      <CampaignForm {...formikProps} createForm={true} websites={websites} handleChange={this.handleTabChange} currentTab={currentTab}/>
                      <Grid container>
                        {sendingErrorMessage && (
                            <Grid item xs={12}>
                              <Typography color="error" align="right">
                                <Text id={sendingErrorMessage} />
                              </Typography>
                            </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Form>
              )}
          />
      );
    }
    return null;
  }


  private handleTabChange = (event: React.ChangeEvent<{}>, currentTab: number) => {
    this.setState({ currentTab });
  };

  private handleTabChange2 = (currentTab: number) => {
    this.setState({ currentTab });
  };

  private validate = (formikProps : any) => {
    if(
        (formikProps.values.name.length > 8 && formikProps.values.website.value && formikProps.values.budget > 1000)
        &&
        (!formikProps.values.age_min || !formikProps.values.age_max || formikProps.values.locations.length === 0 || formikProps.values.interests.length === 0)
    ){
      this.handleTabChange2(1);
    }

  };

  private onCampaignSave: SubmitFn<ICampaignForm> = (values, actions) => {
    const payload: ICampaignPayload = {
      ...values,
    };

    this.setState(
      {
        sendingErrorMessage: '',
        isLoading: true,
      },
      () => {
        createCampaign(payload).then(
          this.onSaveSuccess(payload, actions),
          this.onSaveFailure(payload, actions)
        );
      }
    );
  };

  private onSaveSuccess: SubmitFn<ICampaignForm> = (_, actions) => ({
    data,
  }: any) => {
    const campaignUuid = data.uuid;

    if(_.products_list.length > 0){
      const productPayload: IProductPayload = {
        product_product_lists: _.products_list,
      };

      createProductList(campaignUuid, productPayload as IProductPayload).then(
          this.onProductSaveSuccess(_, actions),
          this.onSaveFailure(_, actions)
      );
    }else{
      const { history } = this.props;
      this.setState({ sendingErrorMessage: '', isLoading: false }, () => {
        actions.setSubmitting(false);
        history.push(`/${PRIVATE_ROUTES.CAMPAIGNS}/${campaignUuid}`);
      });
    }
  };

  private onProductSaveSuccess: SubmitFn<ICampaignForm> = (_, actions) => ({
    data,
  }: any) => {
    renderImageVariants(data.campaign_uuid);

    const { history } = this.props;
    this.setState({ sendingErrorMessage: '', isLoading: false }, () => {
      actions.setSubmitting(false);
      history.push(`/${PRIVATE_ROUTES.CAMPAIGNS}/${data.campaign_uuid}`);
    });
  };

  private onSaveFailure: SubmitFn<ICampaignForm> = (_, actions) => (
    { response } = {
      response: { data: ErrorResponseDefault },
    } as any
  ) => {
    const { errorMessage, errors } = parseRequestError(response.data);
    this.setState(
      { sendingErrorMessage: errorMessage, isLoading: false },
      () => {
        actions.setSubmitting(false);
        actions.setErrors(errors);
      }
    );
  };

  private fetchWebsites = () => {
    this.setState(
        {isLoading: true, loadingErrorMessage: undefined, websites: undefined},
        () =>
            fetchWebsites()
                .then(
                    this.onWebsitesFetchSuccess,
                    this.onFetchFailure
                )
    );
  };

  private onWebsitesFetchSuccess = ({data}: any) => {
    const websitesToSelect = (data.items || []).map((website: IWebsite) => ({
      value: website.uuid,
      label: website.name,
    }));
    this.setState({websites: websitesToSelect, isLoading: false});
  };

  private onFetchFailure = (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage} = parseRequestError(response.data);
    this.setState({
      isLoading: false,
      loadingErrorMessage: errorMessage,
    });
  };

}

export const NewCampaignPageWithStyles = withStyles(styles)(NewCampaign);

export const NewCampaignPageWithHelmet = withHelmet(NewCampaignPageWithStyles);

export const NewCampaignPage = withRouter(NewCampaignPageWithHelmet);
