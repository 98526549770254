import { Theme, createStyles } from '@material-ui/core';

export const styles = ({ }: Theme) =>
    createStyles({
      userInfoWrapper: {

      },

      iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },

      userDataWrapper: {
        padding: '1em',
      },

      userName: {
        marginBottom: '8px',
      },

      companyName: {

      },

      legalFormName: {
        fontSize: "0.8em"
      },

    });
