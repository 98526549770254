import * as React from 'react';

import { PrivatePageWrapper } from '../../components/common/PrivatePageWrapper/connect';
import {
  withHelmet,
  WithHelmetProps,
} from '../../components/HOC/Helmet/withHelmet';
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router';
import { CampaignsListPage } from './List/ListCampaignsPage';
import { PRIVATE_ROUTES } from '../../constants/routes';
import { NewCampaignPage } from './New/NewCampaignPage';
import { EditCampaignPage } from './Edit/EditCampaignPage';
import { ViewCampaignPage } from './View/ViewCampaingPage';
import {NewCampaignAdsPage} from "../Ads/New/NewCampaingAdsPage";
import {ListCampaignAdsPage} from "../Ads/List/ListCampaingAdsPage";
import {EditCampaignAdsPage} from "../Ads/Edit/EditCampaingAdsPage";

export const Campaigns = ({ match }: WithHelmetProps & RouteComponentProps) => (
  <PrivatePageWrapper>
    <Switch>
      <Route
        path={`${match.url}/${PRIVATE_ROUTES.NEW}`}
        render={() => <NewCampaignPage messagePrefix="newCampaignPage" />}
      />
      <Route
          path={`${match.url}/:id/${PRIVATE_ROUTES.ADS}/${PRIVATE_ROUTES.NEW}`}
          render={() => <NewCampaignAdsPage messagePrefix="newCampaignAdsPage" />}
      />
      <Route
          path={`${match.url}/:id/${PRIVATE_ROUTES.ADS}/${PRIVATE_ROUTES.EDIT}`}
          render={() => <EditCampaignAdsPage messagePrefix="editCampaignAdsPage" />}
      />
      <Route
          path={`${match.url}/:id/${PRIVATE_ROUTES.ADS}`}
          render={() => <ListCampaignAdsPage messagePrefix="listCampaignAdsPage" />}
      />
      <Route
        path={`${match.url}/:id/${PRIVATE_ROUTES.EDIT}`}
        render={() => <EditCampaignPage messagePrefix="editCampaignPage" />}
      />
      <Route
        path={`${match.url}/:id`}
        render={() => <ViewCampaignPage messagePrefix="viewCampaignPage" />}
      />
      <Route
        exact
        path={match.url}
        render={() => <CampaignsListPage messagePrefix="listCampaignsPage" />}
      />
    </Switch>
  </PrivatePageWrapper>
);

export const CampaignsPageWithHelmet = withHelmet(Campaigns);

export const CampaignsPage = withRouter(CampaignsPageWithHelmet);
