import * as React from 'react';

import { withStyles} from '@material-ui/core';
import {FormikProps} from 'formik';

// import { Text } from '../../../components/common/Text/Text';
import {WithClasses} from './../../../types/WithClasses';
// import { MainForm } from './Main/MainForm';
// import { TargetForm } from './Target/TargetForm';
// import { ProductsForm } from './Products/ProductsForm';
import {styles} from './VariantsForm.styles';
import {ProductVariantsList} from "../ProductVariantsList/ProductVariantsList";
import {ICampaignProductGroup, ICampaignProductVariant} from "../../../types/ICampaign";


interface CampaignProductVariantFormProps {
  campaignVariants: ICampaignProductGroup[];
  campaignChosenVariants?: ICampaignProductVariant[];
}

interface ICampaignProductVariantState {
  chosenVariants?: any[];
}

//const intlPath = 'campaignVariantsPage';

export class VariantsFormBase extends React.Component<WithClasses & CampaignProductVariantFormProps & FormikProps<any>, ICampaignProductVariantState> {
  public readonly state = {
    currentTab: 0,
    chosenVariants: [],
  };

  componentDidMount() {
    const {campaignChosenVariants} = this.props;
    if(campaignChosenVariants){
      this.setState({chosenVariants: campaignChosenVariants})
    }
  };


  public render = () => {
    const {campaignVariants} = this.props;
    const {chosenVariants} = this.state;

    return (
        <React.Fragment>
          {campaignVariants.map(productVariants => (
              <ProductVariantsList
                  productVariants={productVariants}
                  onVariantChoose={this.onVariantChoose}
                  chosenVariants={chosenVariants}
              />
          ))}
        </React.Fragment>
    );
  };

  private onVariantChoose = (variant: any) => {
    const {chosenVariants} = this.state;
    const {setFieldValue} = this.props;
    const variantsNumber = 4;

    const index: number = chosenVariants.map((variant: any) => {
      return variant.value;
    }).indexOf(variant.value);

    const selectedProducts = index === -1 ? (
        chosenVariants.length < variantsNumber ? [...chosenVariants, variant] : chosenVariants
    ) : [
      ...chosenVariants.slice(0, index),
      ...chosenVariants.slice(index + 1),
    ];

    console.log(selectedProducts);

    this.setState({chosenVariants: selectedProducts});
    setFieldValue('variants_list', selectedProducts);

  };
}

export const VariantsForm = withStyles(styles)(VariantsFormBase);
