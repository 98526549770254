import { ICampaign, ProductChooserAlgorithm } from '../../../types/ICampaign';
import {IProduct} from "../../../types/IProduct";

export const editCampaignFormInitialValues = (campaign: ICampaign) => ({
  name: campaign.name || '',
  budget: campaign.budget && campaign.budget.toString() || '',
  age_min: campaign.target[0] && campaign.target[0].age_min.toString() || '',
  age_max: campaign.target[0] && campaign.target[0].age_max.toString() || '',
  locations: campaign.target[0] && campaign.target[0].locations || [],
  interests: campaign.target[0] && campaign.target[0].interests || [],
  product_chosen_algorithm:
    campaign.product_chosen_algorithm ||
    ProductChooserAlgorithm.MANUAL.toString(),
  products_list: campaign.products_list[0] && campaign.products_list[0].products.items.map((product : IProduct) => product.uuid) || [],
  website: {value: campaign.website.uuid, label: campaign.website.name} || {value: '', label: ''},
});
