import * as Yup from 'yup';

export const googleAnalyticsViewFormValidationSchema = Yup.object({
  account_id: Yup.string()
      .required('required'),
  property_id: Yup.string()
    .required('required'),
  view_id: Yup.string()
      .required('required'),
});
