import * as React from 'react';


import {withHelmet, WithHelmetProps} from "../../components/HOC/Helmet/withHelmet";
import {Route, RouteComponentProps, Switch, withRouter} from "react-router";
import {PrivatePageWrapper} from "../../components/common/PrivatePageWrapper/connect";
import {SettingsPageBase} from "./Base/SettingsPageBase";


export const Settings = ({ match }: WithHelmetProps & RouteComponentProps) => (
    <PrivatePageWrapper>
      <Switch>
        <Route
            exact
            path={match.url}
            render={() => <SettingsPageBase />}
        />
      </Switch>
    </PrivatePageWrapper>
);

export const SettingsPageWithHelmet = withHelmet(Settings);

export const SettingsPage = withRouter(SettingsPageWithHelmet);
