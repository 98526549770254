import { FbMarketingAPI } from './APIService';
import {ISelectedAutoComplete} from "../types/ICampaign";


export const fetchLocations = (query?: string) => {
  let url = '/targeting/search/adgeolocation/a?location_types[]=city';
  if (query) {
    url = '/targeting/search/adgeolocation/'+ query +'?location_types[]=city';
  }

  let locations : ISelectedAutoComplete[] = [];

  FbMarketingAPI.get(url)
      .then((data) => {
        data.data.map((location : any)=>{
          locations.push({value: location.key, label: location.name + ' (' + location.country_name + ')'});
        });
      })
      .catch((error) => {

      });

  let promise = new Promise((resolve, reject) => {
    let wait = setTimeout(() => {
      clearTimeout(wait);
      resolve(locations);
    }, 1000)
  });

  return promise;
};


export const fetchInterests = (query: string) => {
  let url = '/targeting/search/adinterest/a?location_types[]=city';
  if (query) {
    url = '/targeting/search/adinterest/'+ query +'?location_types[]=city';
  }

  let interests : ISelectedAutoComplete[] = [];

  FbMarketingAPI.get(url)
      .then((data) => {
        data.data.map((interest : any)=>{
          interests.push({value: interest.id, label: interest.name });
        });
      })
      .catch((error) => {

      });

  let promise = new Promise((resolve, reject) => {
    let wait = setTimeout(() => {
      clearTimeout(wait);
      resolve(interests);
    }, 1000)
  });

  return promise;
};
