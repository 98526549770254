import * as Yup from 'yup';
import { isFuture } from 'date-fns';

const MIN_CARD_LENGTH = 13;
const MAX_CARD_LENGTH = 16;

const visaCardRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
const masterCardRegex = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;

const isOneOfTheAcceptableCard = new RegExp(
  `(${visaCardRegex.source})|(${masterCardRegex.source})`
);
const cvvFormat = /^[0-9]{3,4}$/;
const monthFormat = /^(0[1-9]|1[0-2]|[1-9])$/;
const yearFormat = /^([1-9][0-9]{3})$/;

export const paymentFormValidationSchema = Yup.object({
  cardNumber: Yup.string()
    .required('required')
    .min(MIN_CARD_LENGTH)
    .max(MAX_CARD_LENGTH)
    .matches(isOneOfTheAcceptableCard),
  expiryMonth: Yup.string()
    .max(2, 'length')
    .matches(monthFormat, 'format')
    .required('required'),
  expiryYear: Yup.string()
    .length(4, 'length')
    .matches(yearFormat, 'format')
    .test('validDate', 'expired', function(value: string) {
      const { expiryMonth } = this.parent;
      return isFuture(`${value}-${expiryMonth}`);
    }),
  owner: Yup.string().required('required'),
  cvvCode: Yup.string()
    .required('required')
    .matches(cvvFormat, 'format'),
  //selectedPackage: Yup.string().required('required'),
});
