import * as React from 'react';

import {Typography, Toolbar, Button, Paper, Grid, withStyles} from '@material-ui/core';

import {PRIVATE_ROUTES} from '../../../constants/routes';

import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import {Text} from '../../../components/common/Text/Text';
import {ButtonLink} from '../../../components/common/ButtonLink/ButtonLink';
import {Formik, Form} from 'formik';
import {WithClasses} from '../../../types/WithClasses';
import {RouteComponentProps, withRouter} from 'react-router';
import {WebsiteForm} from '../../../components/common/WebsiteForm/WebsiteForm';
import {Loader} from '../../../components/common/Loader/Loader';
import {newWebsiteFormInitialValues} from './initialValues';
import {newWebsiteFormValidationSchema} from './validationSchema';
import {SubmitFn} from '../../../types/FormikSubmit';
import {IWebsiteForm, IWebsitePayload} from '../../../types/IWebsite';
import {createWebsite} from '../../../services/WebsiteService';
import {ErrorResponseDefault, parseRequestError} from '../../../helpers/validation';
import {styles} from './NewWebsitePage.styles';

interface IWebsiteState {
  isLoading: boolean;
  sendingErrorMessage?: string;
  skipFeedConfirmationRequested: boolean;
}

const intlPath = 'newWebsitePage';

class NewWebsite extends React.Component<WithHelmetProps & WithClasses & RouteComponentProps,
    IWebsiteState> {
  public readonly state = {
    isLoading: false,
    sendingErrorMessage: '',
    skipFeedConfirmationRequested: false,
  };

  public render() {
    const {classes} = this.props;
    const {
      isLoading,
      sendingErrorMessage,
      skipFeedConfirmationRequested,
    } = this.state;
    if (isLoading) {
      return (
          <Loader
              isLoading={isLoading}
              loadingLabelKey={`${intlPath}.fetchingCampaign`}
          />
      );
    }
    return (
        <React.Fragment>
          <Typography component="h1" variant="h5">
            <Text id={`${intlPath}.title`}/>
          </Typography>
          <Formik
              initialValues={newWebsiteFormInitialValues}
              validationSchema={newWebsiteFormValidationSchema}
              onSubmit={this.onWebsiteSave}
              render={({isSubmitting, values}) => (
                  <Form noValidate={true}>
                    <Toolbar disableGutters={true}>
                      <ButtonLink
                          to={`/${PRIVATE_ROUTES.WEBSITES}`}
                          variant="contained"
                          color="primary"
                          aria-label="Back"
                          className={classes.button}
                      >
                        <Text id={`${intlPath}.back`}/>
                      </ButtonLink>
                      <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          aria-label="Save website"
                          className={classes.button}
                      >
                        <Text id={`${intlPath}.saveWebsiteButton`}/>
                      </Button>
                    </Toolbar>
                    <Paper className={classes.root}>
                      <WebsiteForm values={values} isSubmitting={isSubmitting}/>
                      <Grid container>
                        {sendingErrorMessage && (
                            <Grid item xs={12}>
                              <Typography color="error" align="right">
                                <Text id={sendingErrorMessage}/>
                              </Typography>
                            </Grid>
                        )}
                        {skipFeedConfirmationRequested && (
                            <Grid item xs={12}>
                              <Typography color="error" align="right">
                                <Text
                                    id={`${intlPath}.skipFeedConfirmationRequested`}
                                />
                              </Typography>
                            </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Form>
              )}
          />
        </React.Fragment>
    );
  }

  private onWebsiteSave: SubmitFn<IWebsiteForm> = (values, actions) => {
    const {skip_feed, ...payload} = values;
    const {skipFeedConfirmationRequested} = this.state;
    if (skip_feed && !skipFeedConfirmationRequested) {
      this.setState({
        skipFeedConfirmationRequested: true,
        sendingErrorMessage: '',
      });
      actions.setSubmitting(false);
      return;
    }
    this.setState(
        {
          sendingErrorMessage: '',
          isLoading: true,
          skipFeedConfirmationRequested: false,
        },
        () => {
          createWebsite(payload as IWebsitePayload).then(
              this.onSaveSuccess(values, actions),
              this.onSaveFailure(values, actions)
          );
        }
    );
  };

  private onSaveSuccess: SubmitFn<IWebsiteForm> = (_, actions) => ({
                                                                     data,
                                                                   }: any) => {
    const {history} = this.props;
    this.setState({sendingErrorMessage: '', isLoading: false}, () => {
      actions.setSubmitting(false);
      history.push(`/${PRIVATE_ROUTES.WEBSITES}/${data.uuid}`);
    });
  };

  private onSaveFailure: SubmitFn<IWebsiteForm> = (_, actions) => (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage, errors} = parseRequestError(response.data);
    this.setState(
        {sendingErrorMessage: errorMessage, isLoading: false},
        () => {
          actions.setSubmitting(false);
          actions.setErrors(errors);
        }
    );
  };
}

export const NewWebsitePageWithStyles = withStyles(styles)(NewWebsite);

export const NewWebsitePageWithHelmet = withHelmet(NewWebsitePageWithStyles);

export const NewWebsitePage = withRouter(NewWebsitePageWithHelmet);
