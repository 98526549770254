import * as React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  Typography, IconButton,
} from '@material-ui/core';

import { PRIVATE_ROUTES } from '../../../../../constants/routes';

import {IProduct, IProductStatus} from '../../../../../types/IProduct'; //IFeed
import { WithClasses } from '../../../../../types/WithClasses';

import {
  ColumnType,
  TableHeader,
} from '../../../../../components/common/table/TableHeader/TableHeader';
import { Text } from '../../../../../components/common/Text/Text';
import { ButtonLink } from '../../../../../components/common/ButtonLink/ButtonLink';

import { styles } from './ProductsTable.styles';
import {Cancel, Edit, PlayArrow, Search} from '@material-ui/icons';
import { IconLink } from '../../../../../components/common/IconLink/IconLink';

const intlPath = 'listProductPage';
const columnsIntlPath = `${intlPath}.columns`;

const columns: ColumnType[] = [
  { name: 'uuid', labelKey: `${columnsIntlPath}.uuid`, padding: 'dense' },
  { name: 'product_id', labelKey: `${columnsIntlPath}.product_id` },
  { name: 'name', labelKey: `${columnsIntlPath}.name` },
  { name: 'price', labelKey: `${columnsIntlPath}.price` },
  { name: 'category', labelKey: `${columnsIntlPath}.category` },
  {
    name: 'stock',
    labelKey: `${columnsIntlPath}.stock`
  },
  {
    name: 'status',
    labelKey: `${columnsIntlPath}.status`
  },
  {
    name: 'status-change',
    labelKey: `${columnsIntlPath}.status-change`
  },
  { name: 'actions', labelKey: `${columnsIntlPath}.actions`, padding: 'dense' },
];

interface ProductsTableProps {
  products: IProduct[];
}

class ProductsTableBase extends React.Component<
  ProductsTableProps & WithClasses
> {
  public render() {
    const { classes, products } = this.props;

    return (
      <Paper className={classes.root}>
        {products ? (
          <div className={classes.tableWrapper}>
            <Table aria-labelledby="tableTitle" padding="checkbox">
              <TableHeader columns={columns} />
              <TableBody>
                {products.map(product => (
                  <TableRow hover key={product.uuid}>
                    <TableCell padding="dense" component="th" scope="row">
                      {product.uuid}
                    </TableCell>
                    <TableCell>{product.product_id}</TableCell>
                    <TableCell>{product.title}</TableCell>
                    <TableCell>{product.price}</TableCell>
                    <TableCell>{product.category}</TableCell>
                    <TableCell>{product.stock}</TableCell>
                    <TableCell>{product.status ? 'true' : 'false'}</TableCell>
                    <TableCell>
                      <IconButton
                          aria-label="Disable Product"
                          onClick={this.handleStatusChange(
                              product.uuid,
                              IProductStatus.NOT_OK
                          )}
                      >
                        <Cancel />
                      </IconButton>
                      <IconButton
                          aria-label="Enable Product"
                          onClick={this.handleStatusChange(
                              product.uuid,
                              IProductStatus.OK
                          )}
                      >
                        <PlayArrow />
                      </IconButton>
                    </TableCell>
                    <TableCell className={classes.actionsColumn}>
                      <IconLink
                        aria-label="Details"
                        to={`${PRIVATE_ROUTES.WEBSITES}/${product.uuid}`}
                      >
                        <Search />
                      </IconLink>
                      <IconLink
                        aria-label="Edit website"
                        to={`${PRIVATE_ROUTES.WEBSITES}/${product.uuid}/${
                          PRIVATE_ROUTES.EDIT
                        }`}
                      >
                        <Edit />
                      </IconLink>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className={classes.noDataWrapper}>
            <Typography component="p">
              <Text id={`${intlPath}.noData`} />
            </Typography>
            <ButtonLink
              to={`/${PRIVATE_ROUTES.WEBSITES}/${PRIVATE_ROUTES.NEW}`}
              variant="contained"
              color="primary"
              aria-label="New website"
            >
              <Text id={`${intlPath}.createWebsite`} />
            </ButtonLink>
          </div>
        )}
      </Paper>
    );
  }


  private handleStatusChange = (
      id: string,
      newStatus: IProductStatus
  ) => () => {};
}



// function sumProducts(feeds: IFeed[] = []) {
//   return feeds.reduce((sum, feed) => sum + feed.product_count, 0);
// }

export const ProductsTable = withStyles(styles)(ProductsTableBase);
