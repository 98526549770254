import {ProductChooserAlgorithm} from '../../../types/ICampaign';

export const newCampaignFormInitialValues = {
  name: '',
  budget: '',
  age_min: '',
  age_max: '',
  website: {value: '', label: ''},
  locations: [],
  interests: [],
  product_chosen_algorithm: ProductChooserAlgorithm.MANUAL,
  products_list: [],
};
