import * as React from 'react';
import { TableHead, TableRow, TableCell, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { TableCellProps } from '@material-ui/core/TableCell';

export interface TableHeaderProps {
  columns: ColumnType[];
}

export interface ColumnType {
  name: string;
  labelKey: string;
  padding?: TableCellProps['padding'];
  align?: TableCellProps['align'];
}

export class TableHeader extends React.Component<TableHeaderProps> {
  render() {
    const { columns } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columns.map(({ name, labelKey, padding, align }) => (
            <TableCell
              key={name}
              padding={padding || 'checkbox'}
              align={align || 'inherit'}
            >
              <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                <FormattedMessage id={labelKey} />
              </Tooltip>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}
