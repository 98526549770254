import * as React from 'react';
import {withStyles, Grid, Typography, FormControl } from '@material-ui/core';
import {Field, FormikProps} from 'formik';

import {WithClasses} from '../../../../types/WithClasses';

import {styles} from './SubscriptionPackageList.styles';
import {SubscriptionPackage} from "../../../../components/common/SubscriptionPackage/SubscriptionPackage";
import {Text} from "../../../../components/common/Text/Text";
import {ISubscription} from "../../../../types/Subscription";
import {RadioGroup} from "../../../../components/common/form/RadioGroup/RadioGroup";

const intlPath = 'subscriptionPackageList';

interface SubscriptionPackageProps {
  subscriptionPackages: ISubscription[];
  classes: any;
  checked: string;
  handleCheck: any;
}



class SubscriptionPackageListBase extends React.Component<SubscriptionPackageProps & WithClasses & FormikProps<any>> {


  public render() {
    const {classes, subscriptionPackages, checked, handleCheck} = this.props;


    return (
        <React.Fragment>
          <Typography component="h1" variant="h5" className={classes.header}>
            <Text id={`${intlPath}.header`}/>
          </Typography>

          <FormControl margin="normal" required fullWidth>
            <Field
                component={RadioGroup}
                id="selectedPackage"
                name="selectedPackage"
                margin="normal"
            >
              <Grid container spacing={24}>
                {subscriptionPackages.map(subscriptionPackage =>
                    <Grid item xs={4}>
                      <SubscriptionPackage checked={checked} handleCheck={handleCheck} subscriptionPackage={subscriptionPackage}/>
                    </Grid>
                )}
              </Grid>
            </Field>
          </FormControl>
        </React.Fragment>
    );
  }

};

export const SubscriptionPackageList = withStyles(styles)(SubscriptionPackageListBase);
