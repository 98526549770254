import * as React from 'react';
import {
  AppBar as MaterialAppBar,
  Toolbar,
  IconButton,
  Badge,
  withStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Notifications,
  Menu as MenuIcon,
  AccountCircle,
} from '@material-ui/icons';

import { WithClasses } from '../../../types/WithClasses';
import { styles } from './AppBar.styles';
import {PRIVATE_ROUTES} from "../../../constants/routes";
import {Link} from "react-router-dom";


interface AppBarProps {
  onMenuOpen: () => void;
  onLogout: () => void;
}

interface AppBarState {
  anchorEl: HTMLElement | null;
}
export class AppBarBase extends React.Component<
  WithClasses & AppBarProps,
  AppBarState
> {
  public readonly state = {
    anchorEl: null,
  };

  public render() {
    const { classes, onMenuOpen } = this.props;
    const { anchorEl } = this.state;
    const isOpen = Boolean(anchorEl);

    return (
      <MaterialAppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            aria-label="Open drawer"
            onClick={onMenuOpen}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div>
            <IconButton>
              <Badge badgeContent={999} color="secondary">
                <Notifications />
              </Badge>
            </IconButton>
            <IconButton
              aria-owns={isOpen ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenu}
            >
              <AccountCircle />
            </IconButton>
          </div>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isOpen}
            onClose={this.handleClose}
          >
            <MenuItem>
              <Link to={`/${PRIVATE_ROUTES.PROFILE}`}>
                Profile
              </Link>
            </MenuItem>
            <MenuItem onClick={this.handleLogout}>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </MaterialAppBar>
    );
  }

  private handleMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  private handleClose = () => {
    this.setState({ anchorEl: null });
  };

  private handleLogout = () => {
    this.handleClose();
    this.props.onLogout();
  };
}
export const AppBar = withStyles(styles)(AppBarBase);
