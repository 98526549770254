import * as Yup from 'yup';

export const registerFormValidationSchema = Yup.object({
  email: Yup.string()
    .email('valid')
    .required('required'),
  password: Yup.string()
    .min(8, 'length')
    .required('required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'identical')
    .required('required'),
  terms_and_conditions: Yup.boolean()
    .oneOf([true], 'required')
    .required('required'),
});
