import * as React from 'react';

import { IntlProvider } from 'react-intl';

import { messages } from '../../../constants/messages';
import { flattenMessages } from '../../../helpers/flattenMessages';

interface I18nProviderProps {
  children?: React.ReactNode;
}

export const I18nProvider = ({ children }: I18nProviderProps) => (
  <IntlProvider locale="en" messages={flattenMessages(messages.en)}>
    {children}
  </IntlProvider>
);
