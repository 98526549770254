import * as React from 'react';
import { FormControl, withStyles, Grid } from '@material-ui/core';
import { Field } from 'formik';

import { styles } from './MainForm.styles';
import { WithClasses } from '../../../../types/WithClasses';
import { TextField } from '../../form/TextField/TextField';
import { AutoComplete } from '../../form/AutoComplete/AutoComplete';
import { fetchWebsites } from '../../../../services/WebsiteService';
import { IWebsite } from '../../../../types/IWebsite';
import {ISelectedAutoComplete} from "../../../../types/ICampaign";

interface CampaignMainFormProps {
  isSubmitting: boolean;
  onChange: any;
  onBlur: any;
  createForm: boolean;
  websites?: ISelectedAutoComplete[];
}

const intlPath = 'campaignMainForm';


const MainFormBase = ({
  classes,
  isSubmitting,
  onChange,
  onBlur,
  createForm,
  websites,
}: WithClasses & CampaignMainFormProps) => (
  <Grid container spacing={24}>
    <Grid item xs={12} sm={7}>
      <FormControl margin="normal" required fullWidth>
        <Field
          component={TextField}
          autoComplete="name"
          id="name"
          name="name"
          margin="normal"
          autoFocus
          messagePrefix={intlPath}
          disabled={isSubmitting}
        />
      </FormControl>
    </Grid>
    {createForm && (
      <Grid item xs={12} sm={7}>
        <FormControl margin="normal" required fullWidth>
          <Field
            component={AutoComplete}
            id="website"
            name="website"
            margin="normal"
            messagePrefix={intlPath}
            disabled={isSubmitting}
            loadOptions={getWebsites}
            defaultOptions={websites}
            onChange={onChange}
            onBlur={onBlur}
            isMulti={false}
          />
        </FormControl>
      </Grid>
    )}
    <Grid item xs={12} sm={7}>
      <FormControl margin="normal" required fullWidth>
        <Field
          component={TextField}
          id="budget"
          name="budget"
          margin="normal"
          messagePrefix={intlPath}
          disabled={isSubmitting}
        />
      </FormControl>
    </Grid>
  </Grid>
);

function getWebsites(query: string) {
  return fetchWebsites(query).then(
    ({ data }: any) => {
      return (data.items || []).map((website: IWebsite) => ({
        value: website.uuid,
        label: website.name,
      }));
    },
    () => []
  );
}

export const MainForm = withStyles(styles)(MainFormBase);
