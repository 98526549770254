import * as React from 'react';

import {
  Typography,
  Paper,
  withStyles,
  Grid,
  CircularProgress,
  Button,
} from '@material-ui/core';
import {withRouter, RouteComponentProps} from 'react-router';

import {Text} from '../../../components/common/Text/Text';
import {WithClasses} from '../../../types/WithClasses';
import {styles} from './WebsitePage.styles';
import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import {SubmitFn} from '../../../types/FormikSubmit';
import {
  ErrorResponseDefault,
  parseRequestError,
} from '../../../helpers/validation';
import {
  IWebsiteForm,
  IWebsitePayload
} from '../../../types/IWebsite';
import {ONBOARDING_STEPS} from '../../../constants/onboarding';
import {getOnboardingUrl} from '../../../helpers/onboarding';
import {sendOnboardingStatus} from '../../../services/AccountService';
import {createWebsite} from '../../../services/WebsiteService';
import {WebsiteForm} from '../../../components/common/WebsiteForm/WebsiteForm';
import {Formik, Form} from 'formik';
import {websiteFormInitialValues} from './initialValues';
import {websiteFormValidationSchema} from './validationSchema';
import Modal from '@bdenzer/react-modal';
import {storage} from "../../../services/StorageService";
import {GoogleAnalyticsAccountForm} from "../../../components/common/GoogleAnalyticsForms/Account/GoogleAnalyticsAccountForm";
import {GoogleAnalyticsViewForm} from "../../../components/common/GoogleAnalyticsForms/View/GoogleAnalyticsViewForm";


interface WebsitePageState {
  errorMessage?: string;
  skipFeedConfirmationRequested: boolean;
  shouldShowModal: boolean;
  accessToken: string;
}

export class Website extends React.Component<WithHelmetProps & WithClasses & RouteComponentProps,
    WebsitePageState> {
  public readonly state = {
    errorMessage: '',
    skipFeedConfirmationRequested: false,
    shouldShowModal: false,
    accessToken: '',
  };

  componentDidMount() {
    const {location} = this.props;
    const paramString: string = (location.search as any);

    const params = new URLSearchParams(paramString);
    const access_token = params.get('access_token');

    if(access_token && access_token !== 'null'){
      this.setState({accessToken: access_token});
    }
  }

  public render() {
    const {classes, messagePrefix} = this.props;
    const {errorMessage, skipFeedConfirmationRequested, shouldShowModal, accessToken} = this.state;

    return (
        <Paper className={classes.root}>
          <Typography component="h1" variant="h5" className={classes.header}>
            <Text id={`${messagePrefix}.header`}/>
          </Typography>
          <Formik
              initialValues={websiteFormInitialValues(storage.getObject('website'))}
              validationSchema={websiteFormValidationSchema}
              onSubmit={this.handleFormSubmit}
              render={({isSubmitting, values}) => (
                  <Form noValidate={true}>
                    <WebsiteForm
                        values={values}
                        isSubmitting={isSubmitting}
                    />
                    <Grid container>
                      {errorMessage && (
                          <Grid item xs={12}>
                            <Typography color="error" align="right">
                              <Text id={errorMessage}/>
                            </Typography>
                          </Grid>
                      )}
                      {skipFeedConfirmationRequested && (
                          <Grid item xs={12}>
                            <Typography color="error" align="right">
                              <Text id={`${messagePrefix}.skipFeedConfirmationRequested`}/>
                            </Typography>
                          </Grid>
                      )}
                    </Grid>
                    <Grid container item xs={12} justify="flex-end">
                      {isSubmitting ? (
                          <CircularProgress/>
                      ) : (
                          <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                              disabled={isSubmitting}
                          >
                            <Text id={`${messagePrefix}.submit`}/>
                          </Button>
                      )}
                    </Grid>
                  </Form>
              )}
          />
          <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleOpenModal}
          >
            <Text id={`${messagePrefix}.googleAnalytics`}/>
          </Button>
          <Modal
              closeModal={this.handleCloseModal}
              shouldShowModal={shouldShowModal}
              title={`${messagePrefix}.googleAnalyticsAccountFormTitle`}
          >
            {accessToken === '' && (
                <GoogleAnalyticsAccountForm
                  classes={classes}
                />
            )}

            {accessToken !== '' && (
                <GoogleAnalyticsViewForm
                    classes={classes}
                    accessToken={accessToken}
                />
            )}
          </Modal>
        </Paper>
    );
  }

  private handleOpenModal = () => {
    this.setState({shouldShowModal: true});
  };

  private handleCloseModal = () => {
    this.setState({shouldShowModal: false});
  };


  private handleFormSubmit: SubmitFn<IWebsiteForm> = (values, actions) => {
    const {skip_feed, ...payload} = values;
    const {skipFeedConfirmationRequested} = this.state;
    if (skip_feed && !skipFeedConfirmationRequested) {
      this.setState({skipFeedConfirmationRequested: true, errorMessage: ''});
      actions.setSubmitting(false);
      return;
    }
    this.setState(
        {errorMessage: '', skipFeedConfirmationRequested: false},
        () => {
          createWebsite(payload as IWebsitePayload).then(
              this.onCreateWebsiteSuccess(values, actions),
              this.onCreateWebsiteFailure(values, actions)
          );
        }
    );
  };

  private onCreateWebsiteSuccess: SubmitFn<IWebsiteForm> = (values, actions) => () => {
    sendOnboardingStatus(ONBOARDING_STEPS.CREATE_WEBSITE).then(() => {
      const {history} = this.props;
      this.setState({errorMessage: ''}, () => {
        actions.setSubmitting(false);
        storage.delete('website');
        history.push(getOnboardingUrl(ONBOARDING_STEPS.CREATE_WEBSITE));
      });
    }, this.onCreateWebsiteFailure(values, actions));
  };

  private onCreateWebsiteFailure: SubmitFn<IWebsiteForm> = (_, actions) => (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage, errors} = parseRequestError(response.data);
    this.setState({errorMessage}, () => {
      actions.setSubmitting(false);
      actions.setErrors(errors);
    });
  };
}

export const WebsitePageWithStyles = withStyles(styles)(Website);

export const WebsitePageWithHelmet = withHelmet(WebsitePageWithStyles);

export const WebsitePage = withRouter(WebsitePageWithHelmet);
