import { createMuiTheme } from '@material-ui/core';

export const THEME = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: "#2296f3", contrastText: "#fff" },
    secondary: { main: "#b2babe", contrastText: "#fff" },
  }
});
