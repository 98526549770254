import * as React from 'react';
import {Button, CircularProgress, FormControl, Grid} from '@material-ui/core';
import {Field, Form, Formik} from 'formik';
import {TextField} from '../../form/TextField/TextField';
import {WithClasses} from "../../../../types/WithClasses";
import {Text} from "../../Text/Text";
import {SubmitFn} from "../../../../types/FormikSubmit";
import {
  IFacebookViewForm,
  IFacebookViewPayload
} from "../../../../types/IAssociations";
import {associateFacebookAccount} from "../../../../services/AssociationService";
import {ErrorResponseDefault, parseRequestError} from "../../../../helpers/validation";
import {facebookViewFormInitialValues} from "./initialValues";
import {facebookViewFormValidationSchema} from "./validationSchema";
import {storage} from "../../../../services/StorageService";

interface GoogleAnalyticsViewFormProps {
  accessToken?: string;
}

const intlPath = 'googleAnalyticsViewForm';

interface FacebookViewFormState {
  errorMessage?: string;
}

export class FacebookViewForm extends React.Component<GoogleAnalyticsViewFormProps & WithClasses,
    FacebookViewFormState> {
  public readonly state = {
    errorMessage: '',
  };


  public render() {
    const {classes} = this.props;

    return (
        <Formik
            initialValues={facebookViewFormInitialValues}
            validationSchema={facebookViewFormValidationSchema}
            onSubmit={this.handleFacebookAccountAssociation}
            render={({isSubmitting}) => (
                <Form noValidate={true}>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <FormControl margin="normal" required fullWidth>
                        <Field
                            component={TextField}
                            autoComplete="user_id"
                            id="user_id"
                            name="user_id"
                            margin="normal"
                            autoFocus
                            messagePrefix={intlPath}
                            disabled={isSubmitting}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} justify="flex-end">
                    {isSubmitting ? (
                        <CircularProgress/>
                    ) : (
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isSubmitting}
                        >
                          <Text id={`${intlPath}.submit`}/>
                        </Button>
                    )}
                  </Grid>
                </Form>
            )}
        />
    )
  }

  private handleFacebookAccountAssociation: SubmitFn<IFacebookViewForm> = (values, actions) => {
    const {accessToken} = this.props;
    const access_token = accessToken;

    console.log(access_token);

    if(access_token){
      const payload : IFacebookViewPayload = {
        ...values, access_token,
        app_id: storage.get('app_id'),
        app_secret: storage.get('app_secret')
      };

      storage.delete('app_id');
      storage.delete('app_secret');

      this.setState(
          {errorMessage: ''},
          () => {
            associateFacebookAccount(payload as IFacebookViewPayload).then(
                this.onAccountAssociateSuccess(payload, actions),
                this.onAccountAssociateFailure(payload, actions)
            );
          }
      );
    }

  };

  private onAccountAssociateSuccess: SubmitFn<IFacebookViewPayload> = (values, actions) => ({
    data,
  }: any) => {
    console.log(data);
  };

  private onAccountAssociateFailure: SubmitFn<IFacebookViewPayload> = (_, actions) => (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage, errors} = parseRequestError(response.data);
    this.setState({errorMessage}, () => {
      actions.setSubmitting(false);
      actions.setErrors(errors);
    });
  };
}
