import * as React from 'react';


import {RouteComponentProps, withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {WithClasses} from "../../../../types/WithClasses";
import {styles} from "./AccountSettings.styles";
import {GoogleAnalyticsAccountForm} from "../../../../components/common/GoogleAnalyticsForms/Account/GoogleAnalyticsAccountForm";
import {GoogleAnalyticsViewForm} from "../../../../components/common/GoogleAnalyticsForms/View/GoogleAnalyticsViewForm";


interface ISettingsPageState {
  accessToken?: string;
}

export class AccountSettings extends React.Component<RouteComponentProps &
    WithClasses, ISettingsPageState> {

  public readonly state = {
    accessToken: ''
  };

  componentDidMount() {
    const {location} = this.props;
    const paramString: string = (location.search as any);

    const params = new URLSearchParams(paramString);
    const access_token = params.get('access_token');

    if(access_token && access_token !== 'null'){
      this.setState({accessToken: access_token});
    }
  }

  render() {
    const {classes} = this.props;
    const {accessToken} = this.state;

    return (
        <div className={classes.formWrapper}>
          {accessToken === '' && (
              <GoogleAnalyticsAccountForm
                  classes={classes}
              />
          )}

          {accessToken !== '' && (
              <GoogleAnalyticsViewForm
                  classes={classes}
                  accessToken={accessToken}
              />
          )}
        </div>
    );
  }

};

export const AccountSettingsPageWithStyles = withStyles(styles)(AccountSettings);

export const AccountSettingsPage = withRouter(AccountSettingsPageWithStyles);
