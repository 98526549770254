import * as React from 'react';

import { ICampaign } from '../../../../types/ICampaign';
import { Typography, withStyles, Paper } from '@material-ui/core';
import { Text } from '../../../../components/common/Text/Text';
import { styles } from './Summary.styles';
import { WithClasses } from '../../../../types/WithClasses';
import {
  FormattedDate,
  DateRelative,
} from '../../../../components/common/Date/Date';

interface SummaryPops {
  campaign: ICampaign;
}

const intlPath = 'viewCampaignPage.summary';

export const SummaryBase = ({
  classes,
  campaign,
}: WithClasses & SummaryPops) => (
  <React.Fragment>
    <Typography component="h2" variant="h6">
      <Text id={`${intlPath}.title`} />
    </Typography>
    <Paper className={classes.root}>
      <div className={classes.wrapper}>
        <Typography component="p" variant="h5">
          <Text
            id={`${intlPath}.budgetRatio`}
            values={{
              spent: campaign.budget_spent.toFixed(2),
              total: campaign.budget.toFixed(2),
              currency: <Text id={`currency.EUR`} />,
            }}
          />
        </Typography>
        <Typography component="p" variant="subtitle1">
          <Text id={`${intlPath}.budget`} />
        </Typography>
      </div>
      <div className={classes.wrapper}>
        <Typography component="p" variant="h5">
          <FormattedDate value={new Date(campaign.end_date)} />(
          <DateRelative
            value={new Date(campaign.end_date)}
            units="day"
            updateInterval={undefined}
          />
          )
        </Typography>
        <Typography component="p" variant="subtitle1">
          <Text id={`${intlPath}.endDate`} />
        </Typography>
      </div>
      <div className={classes.wrapper}>
        <Typography component="p" variant="h5">
          <Text
            id={`${intlPath}.ROIValue`}
            values={{ value: campaign.stats_roi.toFixed(2) }}
          />
        </Typography>
        <Typography component="p" variant="subtitle1">
          <Text id={`${intlPath}.currentROI`} />
        </Typography>
      </div>
    </Paper>
  </React.Fragment>
);

export const Summary = withStyles(styles)(SummaryBase);
