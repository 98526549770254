import * as Yup from 'yup';

export const newPasswordFormValidationSchema = Yup.object({
  password: Yup.string()
    .min(8, 'length')
    .required('required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'identical')
    .required('required'),
});
