import * as Yup from 'yup';

export const loginFormValidationSchema = Yup.object({
  username: Yup.string()
    .email('valid')
    .required('required'),
  password: Yup.string()
    .min(8, 'length')
    .required('required'),
});
