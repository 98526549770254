import { AxiosResponse } from 'axios';

import { IUser } from '../types/IUser';
import { UsersAPI, AppAPI } from './APIService';
import { storage } from './StorageService';

const SESSION_TOKEN = 'SESSION_TOKEN';

export const isAuthenticated = () => !!storage.get(SESSION_TOKEN);

export const setSessionToken = (token: string) =>
  storage.set(SESSION_TOKEN, token);

export const getSessionToken = () => storage.get(SESSION_TOKEN);

export const removeSessionToken = () => storage.delete(SESSION_TOKEN);

export const login = (userData: IUser) =>
  UsersAPI.post('/login', userData).then((response: AxiosResponse) => {
    setSessionToken(response.data);
    return response;
  });

export const logout = (token: string) =>
  UsersAPI.post('/logout', {token: token}).then((response: AxiosResponse) => {
    removeSessionToken();
    return response;
  });

export const fbLogin = (token: string) =>
  AppAPI.post('/user/login/facebook', { token }).then(
    (response: AxiosResponse) => {
      setSessionToken(response.data);
      return response;
    }
  );

