import * as React from 'react';
import {WithClasses} from "../../../types/WithClasses";
import {
  withStyles,
  Paper, CardMedia
} from "@material-ui/core";
import {styles} from './ProductVariant.styles';
import {ICampaignProductVariant} from "../../../types/ICampaign";


//const intlPath = 'subscriptionPackage';

interface ProductVariantProps {
  variant: ICampaignProductVariant;
  handleCheck: any;
  chosenVariants: any[];
}


class ProductVariantBase extends React.Component<WithClasses & ProductVariantProps> {

  public render() {
    const {classes, variant, handleCheck, chosenVariants} = this.props;
    let checked = false;

    if(chosenVariants){
      chosenVariants.map((chosenVariant) => {
        if(chosenVariant.value === variant.uuid){
          checked = true;
        }
      });
    }

    return (
        <Paper className={checked ? classes.true : classes.false}>
          <CardMedia
              className={classes.media}
              image={require(`../../../assets/photos/image-ad-variants/${variant.feed_uuid}/${variant.image_name}/${variant.image_src}`)}
              title="Contemplative Reptile"
          />
          <button type='button' onClick={handleCheck} value={variant.uuid} name={variant.image_name}>klik</button>
        </Paper>
    );
  };
}

export const ProductVariant = withStyles(styles)(ProductVariantBase);