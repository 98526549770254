import * as React from 'react';
import {Typography, withStyles} from '@material-ui/core';
import {WithClasses} from "../../../../types/WithClasses";
import {styles} from "./Logo.styles";


export const LogoBase = ({classes} : WithClasses) => (
  <Typography className={classes.logo}>
      <img className="img-responsive"
           alt="AdPareto"
           src="https://adpareto.com/wp-content/themes/adperforma/gfx/logo_white.svg"
           width="100%"
      />
  </Typography>
);

export const Logo = withStyles(styles)(LogoBase);