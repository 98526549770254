import * as React from 'react';

import {
  Typography,
  Toolbar,
  withStyles,
  Button,
  Paper,
  Grid,
} from '@material-ui/core';

import { PRIVATE_ROUTES } from '../../../constants/routes';

import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import { Text } from '../../../components/common/Text/Text';
import { ButtonLink } from '../../../components/common/ButtonLink/ButtonLink';
import { Error } from '../../../components/common/Error/Error';
import { withRouter, RouteComponentProps } from 'react-router';
import { Loader } from '../../../components/common/Loader/Loader';
import { styles } from './EditWebsitePage.styles';
import { WithClasses } from '../../../types/WithClasses';
import { Formik, Form } from 'formik';
import { editWebsiteFormInitialValues } from './initialValues';
import { editWebsiteFormValidationSchema } from './validationSchema';
import {
  IWebsite,
  IWebsiteForm,
  IWebsitePayload,
} from '../../../types/IWebsite';
import { fetchWebsite, updateWebsite } from '../../../services/WebsiteService';
import {
  parseRequestError,
  ErrorResponseDefault,
} from '../../../helpers/validation';
import { WebsiteForm } from '../../../components/common/WebsiteForm/WebsiteForm';
import { SubmitFn } from '../../../types/FormikSubmit';

interface IEditWebsiteState {
  isLoading: boolean;
  website?: IWebsite;
  loadingErrorMessage?: string;
  sendingErrorMessage?: string;
  skipFeedConfirmationRequested: boolean;
}

const intlPath = 'editWebsitePage';

class EditWebsite extends React.Component<
  WithHelmetProps & WithClasses & RouteComponentProps,
  IEditWebsiteState
> {
  public readonly state = {
    isLoading: false,
    website: undefined,
    loadingErrorMessage: '',
    sendingErrorMessage: '',
    skipFeedConfirmationRequested: false,
  };

  public componentDidMount() {
    this.fetchWebsite();
  }

  public render() {
    const { classes, match } = this.props;
    const {
      isLoading,
      loadingErrorMessage,
      sendingErrorMessage,
      website,
      skipFeedConfirmationRequested,
    } = this.state;
    const websiteId: string = (match.params as any).id;
    if (loadingErrorMessage) {
      <Error message={`${intlPath}.requestError.message`}>
        <Button
          className={classes.retryButton}
          type="button"
          variant="contained"
          color="primary"
          onClick={this.fetchWebsite}
        >
          <Text id={`${intlPath}.requestError.retryButton`} />
        </Button>
      </Error>;
    }
    if (isLoading) {
      return (
        <Loader
          isLoading={isLoading}
          loadingLabelKey={`${intlPath}.fetchingCampaign`}
        />
      );
    }
    if (website) {
      return (
        <React.Fragment>
          <Typography component="h1" variant="h5">
            <Text id={`${intlPath}.title`} />
          </Typography>
          <Formik
            initialValues={editWebsiteFormInitialValues(website)}
            validationSchema={editWebsiteFormValidationSchema}
            onSubmit={this.onWebsiteSave}
            render={({ isSubmitting, values }) => (
              <Form noValidate={true}>
                <Toolbar disableGutters={true}>
                  <ButtonLink
                    to={`/${PRIVATE_ROUTES.WEBSITES}/${websiteId}`}
                    variant="contained"
                    color="primary"
                    aria-label="Back"
                    className={classes.button}
                  >
                    <Text id={`${intlPath}.back`} />
                  </ButtonLink>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    aria-label="Save website"
                    className={classes.button}
                  >
                    <Text id={`${intlPath}.saveWebsiteButton`} />
                  </Button>
                </Toolbar>
                <Paper className={classes.root}>
                  <WebsiteForm values={values} isSubmitting={isSubmitting} />
                  <Grid container>
                    {sendingErrorMessage && (
                      <Grid item xs={12}>
                        <Typography color="error" align="right">
                          <Text id={sendingErrorMessage} />
                        </Typography>
                      </Grid>
                    )}
                    {skipFeedConfirmationRequested && (
                      <Grid item xs={12}>
                        <Typography color="error" align="right">
                          <Text
                            id={`${intlPath}.skipFeedConfirmationRequested`}
                          />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Form>
            )}
          />
        </React.Fragment>
      );
    }
    return null;
  }

  private fetchWebsite = () => {
    const { match } = this.props;
    const websiteId: string = (match.params as any).id;
    this.setState({ isLoading: true, loadingErrorMessage: undefined }, () =>
      fetchWebsite(websiteId).then(this.onFetchSuccess, this.onFetchFailure)
    );
  };

  private onFetchSuccess = ({ data }: any) => {
    this.setState({ website: data, isLoading: false });
  };

  private onFetchFailure = (
    { response } = {
      response: { data: ErrorResponseDefault },
    } as any
  ) => {
    const { errorMessage } = parseRequestError(response.data);
    this.setState({ isLoading: false, loadingErrorMessage: errorMessage });
  };

  private onWebsiteSave: SubmitFn<IWebsiteForm> = (values, actions) => {
    const { skip_feed, ...payload } = values;
    const { match } = this.props;
    const { skipFeedConfirmationRequested } = this.state;
    if (skip_feed && !skipFeedConfirmationRequested) {
      this.setState({
        skipFeedConfirmationRequested: true,
        sendingErrorMessage: '',
      });
      actions.setSubmitting(false);
      return;
    }
    this.setState(
      {
        sendingErrorMessage: '',
        isLoading: true,
        skipFeedConfirmationRequested: false,
      },
      () => {
        const websiteId: string = (match.params as any).id;
        updateWebsite(websiteId, payload as IWebsitePayload).then(
          this.onSaveSuccess(values, actions),
          this.onSaveFailure(values, actions)
        );
      }
    );
  };

  private onSaveSuccess: SubmitFn<IWebsiteForm> = (_, actions) => ({
    data,
  }: any) => {
    this.setState(
      { sendingErrorMessage: '', isLoading: false, website: data },
      () => {
        actions.setSubmitting(false);
      }
    );
  };

  private onSaveFailure: SubmitFn<IWebsiteForm> = (_, actions) => (
    { response } = {
      response: { data: ErrorResponseDefault },
    } as any
  ) => {
    const { errorMessage, errors } = parseRequestError(response.data);
    this.setState(
      { sendingErrorMessage: errorMessage, isLoading: false },
      () => {
        actions.setSubmitting(false);
        actions.setErrors(errors);
      }
    );
  };
}

export const EditWebsitePageWithStyles = withStyles(styles)(EditWebsite);

export const EditWebsitePageWithHelmet = withHelmet(EditWebsitePageWithStyles);

export const EditWebsitePage = withRouter(EditWebsitePageWithHelmet);
