import * as React from 'react';

import { PrivatePageWrapper } from '../../components/common/PrivatePageWrapper/connect';
import {
  withHelmet,
  WithHelmetProps,
} from '../../components/HOC/Helmet/withHelmet';
import { Loader } from '../../components/common/Loader/Loader';
import { fetchOnboardingStatus } from '../../services/AccountService';
import { ONBOARDING_STEPS } from '../../constants/onboarding';
import { RouteComponentProps, withRouter } from 'react-router';
import { getOnboardingUrl } from '../../helpers/onboarding';
import { PUBLIC_ROUTES } from '../../constants/routes';
import { removeSessionToken } from '../../services/AuthService';


interface DashboardState {
  isLoadingOnboardingStatus: boolean;
}

class Dashboard extends React.Component<WithHelmetProps & RouteComponentProps, DashboardState> {
  public readonly state = {
    isLoadingOnboardingStatus: false,
  };

  public componentDidMount() {
    this.setState({ isLoadingOnboardingStatus: true }, () =>
      fetchOnboardingStatus().then(this.onOnboardingSuccess, this.onOnboardingFailure)
    );
  }

  public render() {
    const { isLoadingOnboardingStatus } = this.state;

    if (isLoadingOnboardingStatus) {
      return <Loader isLoading={isLoadingOnboardingStatus} />;
    }

    return <PrivatePageWrapper>Here will be dashboard</PrivatePageWrapper>;
  }

  private onOnboardingSuccess = ({ data } = { data: 0 } as any) => {
    this.setState({isLoadingOnboardingStatus: false});


    if(data !== ONBOARDING_STEPS.DONE) {
      this.props.history.push(getOnboardingUrl(data));
      return;
    }
  };

  private onOnboardingFailure = () => {
    removeSessionToken();
    this.props.history.push(`/${PUBLIC_ROUTES.LOGIN}`);
  }
}

export const DashboardPageWithHelmet = withHelmet(Dashboard);

export const DashboardPage = withRouter(DashboardPageWithHelmet);
