import { IWebsitePayload } from './../types/IWebsite';
import { AppAPI } from './APIService';

export const createWebsite = (payload: IWebsitePayload) => {
  return AppAPI.post('/website', payload);
};

export const updateWebsite = (id: string, payload: IWebsitePayload) => {
  return AppAPI.patch(`/websites/${id}`, payload);
};

export const fetchWebsites = (query?: string) => {
  let url = '/websites';
  if (query) {
    url = `${url}?query=${query}`;
  }
  return AppAPI.get(url);
};

export const fetchWebsite = (id: string) => {
  return AppAPI.get(`/websites/${id}`);
};

export const fetchProducts = (
  websiteUUId: string,
  {
    query = '',
    page = 0,
    limit = 25,
    active = false,
  }: {
    query?: string;
    page?: number;
    limit?: number;
    active?: boolean;
  }
) => {
  let url = `/websites/${websiteUUId}/products?page=${page}&limit=${limit}&active=${active}`;
  if (query) {
    url = `${url}&query=${query}`;
  }
  return AppAPI.get(url);
};

