import * as Yup from 'yup';

export const editCampaignFormValidationSchema = Yup.object({
  name: Yup.string()
    .required('required')
    .min(8)
  ,
  budget: Yup.number()
    .typeError('number')
    .positive('positive')
    .required('required'),
  age_min: Yup.number()
    .typeError('number')
    .positive('positive')
    .required('required')
    .min(13)
    .max(65)
  ,
  age_max: Yup.number()
    .typeError('number')
    .positive('positive')
    .required('required')
    .min(Yup.ref('age_min'))
    .max(65)
  ,
  website: Yup.object().shape({
    label: Yup.string().required('required'),
    value: Yup.string().required('required'),
  }),
  locations: Yup.array().min(1, 'required'),
  interests: Yup.array().min(1, 'required'),
  product_chosen_algorithm: Yup.string().required('required'),
});
