import {
  Home,
  ListAlt,
  List as ListIcon,
  Edit,
  Settings,
} from '@material-ui/icons';

import { INavItem } from '../types/INavItem';
import { PRIVATE_ROUTES } from './routes';

export const navigationItems: INavItem[] = [
  { label: 'dashboard', to: `/${PRIVATE_ROUTES.DASHBOARD}`, Icon: Home },
  {
    label: 'campaigns',
    to: `/${PRIVATE_ROUTES.CAMPAIGNS}`,
    Icon: ListAlt,
  },
  {
    label: 'eCommerce',
    to: `/${PRIVATE_ROUTES.WEBSITES}`,
    Icon: ListIcon,
  },
  { label: 'reports', to: `/${PRIVATE_ROUTES.DASHBOARD}`, Icon: Edit },
  {
    label: 'settings',
    to: `/${PRIVATE_ROUTES.SETTINGS}`,
    Icon: Settings,
  },
];
