import * as React from 'react';
import {
  FormControl,
  withStyles,
  Grid,
  FormControlLabel,
  Radio,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Field } from 'formik';

import { styles } from './ProductsForm.styles';
import { WithClasses } from '../../../../types/WithClasses';
import { ProductChooserAlgorithm } from '../../../../types/ICampaign';
import { RadioGroup } from './../../form/RadioGroup/RadioGroup';
import { Text } from '../../Text/Text';
import { ProductsListWrapper } from './ProductsListWrapper/ProductsListWrapper';
import { IWebsiteStatus } from '../../../../types/IWebsite';

interface CampaignProductsFormProps {
  isSubmitting: boolean;
  values: any;
  setFieldValue: any;
}

interface CampaignProductsFormState {
  isProductsListOpen: boolean;
}

const intlPath = 'campaignProductsForm';

class ProductsFormBase extends React.Component<
  WithClasses & CampaignProductsFormProps,
  CampaignProductsFormState
> {
  public readonly state = {
    isProductsListOpen: false,
  };



  public render = () => {
    const { classes, isSubmitting, values } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={7}>
            <FormControl margin="normal" required fullWidth>
              <Field
                component={RadioGroup}
                id="product_chosen_algorithm"
                name="product_chosen_algorithm"
                margin="normal"
                disabled={isSubmitting}
              >
                <FormControlLabel
                  value={ProductChooserAlgorithm.AUTO.toString()}
                  control={<Radio disabled={isSubmitting} />}
                  label={
                    <Text
                      id={`${intlPath}.product_chosen_algorithm.auto.label`}
                    />
                  }
                  disabled={isSubmitting || !values.website.ga_connected}
                />
                <FormControlLabel
                  value={ProductChooserAlgorithm.MANUAL.toString()}
                  control={<Radio disabled={isSubmitting} />}
                  label={
                    <Text
                      id={`${intlPath}.product_chosen_algorithm.manual.label`}
                    />
                  }
                  disabled={
                    isSubmitting ||
                    values.website.status === IWebsiteStatus.NOT_OK
                  }
                />
              </Field>
            </FormControl>
          </Grid>
          {values.product_chosen_algorithm ===
            ProductChooserAlgorithm.MANUAL.toString() && (
            <Grid item xs={12} sm={7}>
              <Typography>
                <Text
                  id={`${intlPath}.selected_products.count`}
                  values={{
                    count: values.products_list.length,
                  }}
                />
              </Typography>
              <Button
                type="button"
                variant="contained"
                color="primary"
                aria-label="Show products"
                className={classes.button}
                onClick={this.handleOpen}
              >
                <Text id={`${intlPath}.selected_products.showList`} />
              </Button>
            </Grid>
          )}
        </Grid>
        <Dialog
          fullScreen
          open={this.state.isProductsListOpen}
          onClose={this.handleClose}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <Close />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
              <Text id={`${intlPath}.selected_products.header`} />
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.wrapper}>
            <ProductsListWrapper
              websiteId={values.website.value}
              selected={values.products_list}
              onProductSelect={this.onProductSelect}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  };

  private handleOpen = () => {
    this.setState({ isProductsListOpen: true });
  };

  private handleClose = () => {
    this.setState({ isProductsListOpen: false });
  };

  private onProductSelect = (uuid: string) => {
    const { values, setFieldValue } = this.props;
    const index = values.products_list.indexOf(uuid);
    const selectedProducts = index === -1 ? [...values.products_list, uuid] : [
      ...values.products_list.slice(0, index),
      ...values.products_list.slice(index + 1)
    ];

    console.log(selectedProducts);
    setFieldValue('products_list', selectedProducts);
  };
}

export const ProductsForm = withStyles(styles)(ProductsFormBase);
