import * as React from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter, RouteComponentProps, Redirect } from 'react-router';

import { WithClasses } from '../../../types/WithClasses';
import { Menu } from '../Menu/Menu';
import { AppBar } from '../AppBar/AppBar';
import { styles } from './PrivatePageWrapper.styles';
import {getSessionToken, isAuthenticated, logout} from '../../../services/AuthService';
import { PUBLIC_ROUTES } from '../../../constants/routes';
import { IAccount } from '../../../types/IAccount';
import { Loader } from '../Loader/Loader';
import { fetchAccount } from '../../../services/AccountService';

export interface PrivatePageWrapperProps {
  children?: React.ReactNode;
}
export interface PrivatePageWrapperDispatchProps {
  storeAccountData: (payload: IAccount) => void;
}

export interface PrivatePageWrapperStoreProps {
  account?: IAccount;
}

interface PrivatePageWrapperState {
  isOpen: boolean;
  isLoadingAccountData: boolean;
}

class Wrapper extends React.Component<
  WithClasses &
    RouteComponentProps &
    PrivatePageWrapperProps &
    PrivatePageWrapperDispatchProps &
    PrivatePageWrapperStoreProps,
  PrivatePageWrapperState
> {
  public readonly state = {
    isOpen: false,
    isLoadingAccountData: false,
  };

  public componentDidMount() {
    const { account, storeAccountData } = this.props;

    if (isAuthenticated() && !account) {
      this.setState({ isLoadingAccountData: true }, () => {
        fetchAccount().then((response: any) => {
          storeAccountData(response.data);
          this.setState({ isLoadingAccountData: false });
        });
      });
    }
  }

  public render() {
    const { classes, children, account } = this.props;
    const { isOpen, isLoadingAccountData } = this.state;

    if (!isAuthenticated()) {
      return <Redirect to={`/${PUBLIC_ROUTES.LOGIN}`} />;
    }

    if (isLoadingAccountData || !account) {
      return <Loader isLoading={isLoadingAccountData} />;
    }

    return (
      <div className={classes.root}>
        <AppBar onMenuOpen={this.handleDrawerToggle} onLogout={this.onLogout} />
        <Menu onClose={this.handleDrawerToggle} isOpen={isOpen} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    );
  }

  private handleDrawerToggle = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  private onLogout = () => {
    logout(getSessionToken()).then(() => this.props.history.push(`/${PUBLIC_ROUTES.LOGIN}`));
  };
}

export const PrivatePageWrapperWithStyles = withStyles(styles)(Wrapper);

export const PrivatePageWrapperBase = withRouter(PrivatePageWrapperWithStyles);
