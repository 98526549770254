import * as React from 'react';
import { withStyles, Paper, Typography } from '@material-ui/core';
import { parse } from 'query-string';

import {
  withHelmet,
  WithHelmetProps,
} from '../../components/HOC/Helmet/withHelmet';
import { WithClasses } from '../../types/WithClasses';
import { styles } from './NewPasswordPage.styles';
import { PublicPageWrapper } from '../../components/common/PublicPageWrapper/PublicPageWrapper';
import { RouteComponentProps, withRouter } from 'react-router';
import { SubmitFn } from '../../types/FormikSubmit';
import { INewPassword } from '../../types/IUser';
import { newPassword } from '../../services/UserService';
import { Text } from '../../components/common/Text/Text';
import { NewPasswordForm } from './NewPasswordForm/NewPasswordForm';
import { ButtonLink } from '../../components/common/ButtonLink/ButtonLink';
import { PUBLIC_ROUTES } from '../../constants/routes';

const intlPath = 'newPasswordPage';

interface NewPasswordState {
  success: boolean;
}

class NewPassword extends React.Component<
  WithHelmetProps & WithClasses & RouteComponentProps,
  NewPasswordState
> {
  public readonly state = {
    success: false,
  };

  public render() {
    const { classes } = this.props;
    const { success } = this.state;
    return (
      <PublicPageWrapper>
        <Paper className={classes.paper}>
          {success ? (
            <React.Fragment>
              <Typography component="h1" variant="h5">
                <Text id={`${intlPath}.success.title`} />
              </Typography>
              <Typography component="p">
                <Text id={`${intlPath}.success.subtitle`} />
              </Typography>
              <ButtonLink
                to={`/${PUBLIC_ROUTES.LOGIN}`}
                variant="contained"
                color="primary"
                aria-label="Login"
              >
                <Text id={`${intlPath}.loginButton`} />
              </ButtonLink>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography component="h1" variant="h5">
                <Text id={`${intlPath}.title`} />
              </Typography>
              <NewPasswordForm onNewPassword={this.doNewPassword} />
            </React.Fragment>
          )}
        </Paper>
      </PublicPageWrapper>
    );
  }

  private doNewPassword: SubmitFn<INewPassword> = (values, actions) => {
    const { email, token } = parse(this.props.location.search);
    newPassword(email, token, values).then(
      this.onNewPasswordSuccess(values, actions),
      this.onNewPasswordFailure(values, actions)
    );
  };

  private onNewPasswordSuccess: SubmitFn<INewPassword> = (_, actions) => () => {
    actions.setSubmitting(false);
    actions.setStatus({ submitErrorMessage: '' });
    this.setState({ success: true });
  };

  private onNewPasswordFailure: SubmitFn<INewPassword> = (_, actions) => (
    { response } = { response: {} } as any
  ) => {
    actions.setSubmitting(false);
    const submitErrorMessage =
      response && response.data.msg
        ? response && response.data.msg
        : 'genericErrorMessage';
    actions.setStatus({ submitErrorMessage });
  };
}

const NewPasswordWithStyles = withStyles(styles)(NewPassword);

const NewPasswordWithHelmet = withHelmet(NewPasswordWithStyles);

export const NewPasswordPage = withRouter(NewPasswordWithHelmet);
