import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: spacing.unit * 2
    },
  });
