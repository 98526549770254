import * as React from 'react';

import { Typography, Paper, withStyles } from '@material-ui/core';

import { Text } from '../../../components/common/Text/Text';
import { ButtonLink } from '../../../components/common/ButtonLink/ButtonLink';
import { WithClasses } from '../../../types/WithClasses';
import { styles } from './WelcomePage.styles';
import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import { withRouter, RouteComponentProps } from 'react-router';
import { PRIVATE_ROUTES } from '../../../constants/routes';

export const Welcome = ({
  classes,
  match,
  messagePrefix,
}: WithClasses & WithHelmetProps & RouteComponentProps) => {
  const nextUrl = `${match.url}/${PRIVATE_ROUTES.PROFILE}`;

  return (
    <Paper className={classes.root}>
      <Typography component="h1" variant="h5" className={classes.header}>
        <Text id={`${messagePrefix}.header`} />
      </Typography>
      <Typography component="p">
        <Text id={`${messagePrefix}.description`} />
      </Typography>
      <ButtonLink
        to={nextUrl}
        variant="contained"
        color="primary"
        aria-label="Edit campaign"
        className={classes.button}
      >
        <Text id={`${messagePrefix}.startButton`} />
      </ButtonLink>
    </Paper>
  );
};

export const WelcomePageWithStyles = withStyles(styles)(Welcome);

export const WelcomePageWithHelmet = withHelmet(WelcomePageWithStyles);

export const WelcomePage = withRouter(WelcomePageWithHelmet);
