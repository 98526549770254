import * as React from 'react';

import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';

import { withRouter, RouteComponentProps } from 'react-router';
import {
  fetchCampaign,
  updateCampaign,
} from '../../../services/CampaignsService';
import { ICampaign, CampaignStatus } from '../../../types/ICampaign';
import { Loader } from '../../../components/common/Loader/Loader';
import { Error } from '../../../components/common/Error/Error';
import { Toolbar } from './Toolbar/Toolbar';
import { Typography, Button, withStyles } from '@material-ui/core';
import { Summary } from './Summary/Summary';
import { Details } from './Details/Details';
import {
  ErrorResponseDefault,
  parseRequestError,
} from '../../../helpers/validation';
import { Text } from '../../../components/common/Text/Text';
import { styles } from './ViewCampaignPage.styles';
import { WithClasses } from '../../../types/WithClasses';

interface IViewCampaignState {
  isLoading: boolean;
  isStopping: boolean;
  campaign?: ICampaign;
  loadingErrorMessage?: string;
  sendingErrorMessage?: string;
}

const intlPath = 'viewCampaignPage';

class ViewCampaign extends React.Component<
  WithHelmetProps & WithClasses & RouteComponentProps,
  IViewCampaignState
> {
  public readonly state: IViewCampaignState = {
    isLoading: false,
    isStopping: false,
    campaign: undefined,
    loadingErrorMessage: '',
  };

  public componentDidMount() {
    this.fetchCampaign();
  }

  public render() {
    const { classes } = this.props;
    const {
      campaign,
      isLoading,
      loadingErrorMessage,
      sendingErrorMessage,
      isStopping,
    } = this.state;
    if (loadingErrorMessage) {
      return (
        <Error message={`${intlPath}.requestError`}>
          <Button
            className={classes.retryButton}
            type="button"
            variant="contained"
            color="primary"
            onClick={this.fetchCampaign}
          >
            <Text id={`${intlPath}.requestError.retryButton`} />
          </Button>
        </Error>
      );
    }
    if (sendingErrorMessage) {
      return <Error message={`${intlPath}.sendingError`} />;
    }
    if (isLoading) {
      return (
        <Loader
          isLoading={isLoading}
          loadingLabelKey={`${intlPath}.fetchingCampaign`}
        />
      );
    }

    if (isStopping) {
      return (
        <Loader
          isLoading={isStopping}
          loadingLabelKey={`${intlPath}.stoppingCampaign`}
        />
      );
    }

    if (campaign) {
      console.log(campaign);
      return (
        <React.Fragment>
          <Typography component="h1" variant="h5">
            {campaign.name}
          </Typography>
          <Toolbar
            campaign={campaign}
            onCampaignStop={this.handleStopCampaign}
          />
          <Summary campaign={campaign} />
          <Details campaign={campaign} />
        </React.Fragment>
      );
    }
    return null;
  }

  private fetchCampaign = () => {
    const { match } = this.props;
    const campaignId: string = (match.params as any).id;
    this.setState(
      { isLoading: true, loadingErrorMessage: undefined, campaign: undefined },
      () =>
        fetchCampaign(campaignId).then(this.onFetchSuccess, this.onFetchFailure)
    );
  };

  private onFetchSuccess = ({ data }: any) => {
    this.setState({ campaign: data, isLoading: false });
  };

  private onFetchFailure = (
    { response } = {
      response: { data: ErrorResponseDefault },
    } as any
  ) => {
    const { errorMessage } = parseRequestError(response.data);
    this.setState({
      isLoading: false,
      loadingErrorMessage: errorMessage,
    });
  };

  private handleStopCampaign = () => {
    this.setState({ isStopping: true, sendingErrorMessage: undefined }, () => {
      const { match } = this.props;
      const campaignId = (match.params as any).id;
      updateCampaign(campaignId, { status: CampaignStatus.PAUSED }).then(
        this.onStopSuccess,
        this.onStopFailure
      );
    });
  };

  private onStopSuccess = ({ data }: any) => {
    this.setState({ campaign: data, isLoading: false });
  };

  private onStopFailure = (
    { response } = {
      response: { data: ErrorResponseDefault },
    } as any
  ) => {
    const { errorMessage } = parseRequestError(response.data);
    this.setState({
      isLoading: false,
      sendingErrorMessage: errorMessage,
    });
  };
}

export const ViewCampaignPageWithStyles = withStyles(styles)(ViewCampaign);

export const ViewCampaignPageWithHelmet = withHelmet(
  ViewCampaignPageWithStyles
);

export const ViewCampaignPage = withRouter(ViewCampaignPageWithHelmet);
