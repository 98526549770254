import * as React from 'react';
import {
  FormControl,
  Button,
  withStyles,
  Typography,
  Link,
} from '@material-ui/core';
import { Formik, Field, Form } from 'formik';

import { WithClasses } from '../../../types/WithClasses';
import { styles } from './LoginForm.styles';
import { LinkToResetPassword } from '../../../components/common/LinkToResetPassword/LinkToResetPassword';
import { loginFormInitialValues } from './initialValues';
import { loginFormValidationSchema } from './validationSchema';
import { TextField } from '../../../components/common/form/TextField/TextField';
import { IUser } from '../../../types/IUser';
import { SubmitFn } from '../../../types/FormikSubmit';
import { Text } from '../../../components/common/Text/Text';

interface LoginFormProps {
  onLogin: SubmitFn<IUser>;
  submitErrorMessage?: string
}

const intlPath = 'loginPage.form';

const LoginFormBase = ({ classes, onLogin, submitErrorMessage }: WithClasses & LoginFormProps) => (
  <Formik
    initialValues={loginFormInitialValues}
    validationSchema={loginFormValidationSchema}
    onSubmit={onLogin}
    render={({ isSubmitting }) => (
      <Form className={classes.form} noValidate={true}>
        <FormControl margin="normal" required fullWidth>
          <Field
            component={TextField}
            autoComplete="username"
            id="username"
            name="username"
            margin="normal"
            autoFocus
            messagePrefix={intlPath}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <Field
            component={TextField}
            autoComplete="password"
            id="password"
            name="password"
            margin="normal"
            type="password"
            messagePrefix={intlPath}
          />
        </FormControl>
        <Typography className={classes.resetPasswordLink}>
          <Link component={LinkToResetPassword}>
            <Text id={`${intlPath}.resetPasswordLink`} />
          </Link>
        </Typography>
        {submitErrorMessage && (
          <Typography className={classes.formSubmitError} color="error">
            <Text id={submitErrorMessage} />
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting}
        >
          <Text id={`${intlPath}.submit`} />
        </Button>
      </Form>
    )}
  />
);

export const LoginForm = withStyles(styles)(LoginFormBase);
