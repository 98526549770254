import * as React from 'react';
import { withStyles, Paper, Typography, Link } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import ReactFacebookLogin from 'react-facebook-login';
import { injectIntl, InjectedIntlProps } from 'react-intl';

import {
  withHelmet,
  WithHelmetProps,
} from '../../components/HOC/Helmet/withHelmet';
import { LoginForm } from './LoginForm/LoginForm';
import { WithClasses } from '../../types/WithClasses';
import { styles } from './LoginPage.styles';
import { PublicPageWrapper } from '../../components/common/PublicPageWrapper/PublicPageWrapper';
import { LinkToRegister } from '../../components/common/LinkToRegister/LinkToRegister';
import { login, fbLogin } from '../../services/AuthService';
import { PRIVATE_ROUTES } from '../../constants/routes';
import { SubmitFn } from '../../types/FormikSubmit';
import { IUser } from '../../types/IUser';
import { Text } from '../../components/common/Text/Text';

const intlPath = 'loginPage';

interface LoginPageState {
  submitErrorMessage?: string;
}

class Login extends React.Component<
  WithHelmetProps &
    WithClasses &
    RouteComponentProps &
    InjectedIntlProps &
    LoginPageState
> {
  public readonly state = {
    submitErrorMessage: '',
  };

  public render() {
    const { classes, intl } = this.props;
    const { submitErrorMessage } = this.state;
    return (
      <PublicPageWrapper>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            <Text id={`${intlPath}.title`} />
          </Typography>
          <Typography component="p">
            <Text id={`${intlPath}.subtitle`} />
          </Typography>
          <ReactFacebookLogin
            appId="297408394267896"
            autoLoad={false}
            fields="name,email"
            callback={this.onFacebookLogin}
            textButton={intl.formatMessage({ id: `${intlPath}.withFacebook` })}
          />
          <Typography component="p">
            <Text id={`${intlPath}.withEmail`} />
          </Typography>
          <LoginForm
            onLogin={this.doLogin}
            submitErrorMessage={submitErrorMessage}
          />
          <Typography className={classes.registerLink}>
            <Link component={LinkToRegister}>
              <Text id={`${intlPath}.registerLink`} />
            </Link>
          </Typography>
        </Paper>
      </PublicPageWrapper>
    );
  }

  private doLogin: SubmitFn<IUser> = (values, actions) => {
    this.setState({ submitErrorMessage: '' }, () => {
      login(values).then(
        this.onLoginSuccess(values, actions),
        this.onLoginFailure(values, actions)
      );
    });
  };

  private onLoginSuccess: SubmitFn<IUser> = (_, actions) => () => {
    console.log(_);
    this.setState({ submitErrorMessage: '' }, () => {
      actions.setSubmitting(false);
      this.props.history.push(`/${PRIVATE_ROUTES.DASHBOARD}`);
    });
  };

  private onLoginFailure: SubmitFn<IUser> = (_, actions) => (
    { response } = { response: {} } as any
  ) => {
    console.log(response);
    const submitErrorMessage =
      response && response.data.msg
        ? response && response.data.msg
        : 'genericErrorMessage';
    this.setState({ submitErrorMessage }, () => {
      actions.setSubmitting(false);
    });
  };

  private onFacebookLogin = (response: any) => {
    this.setState({ submitErrorMessage: '' }, () => {
      fbLogin(response.accessToken).then(
        this.onFacebookLoginSuccess,
        this.onFacebookLoginFailure
      );
    });
  };

  private onFacebookLoginSuccess = () => {
    this.setState({ submitErrorMessage: '' }, () => {
      this.props.history.push(`/${PRIVATE_ROUTES.DASHBOARD}`);
    });
  };

  private onFacebookLoginFailure = ({ response } = { response: {} } as any) => {
    const submitErrorMessage =
      response && response.data.msg
        ? response && response.data.msg
        : 'genericErrorMessage';
    this.setState({ submitErrorMessage });
  };
}

const LoginWithStyles = withStyles(styles)(Login);

const LoginWithIntl = injectIntl(LoginWithStyles);

const LoginPageWithHelmet = withHelmet(LoginWithIntl);

export const LoginPage = withRouter(LoginPageWithHelmet);
