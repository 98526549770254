import * as React from 'react';
import {Drawer, Hidden, withStyles, Divider, MuiThemeProvider, createMuiTheme} from '@material-ui/core';

import {WithClasses} from '../../../types/WithClasses';
import {styles} from './Menu.styles';
import {Logo} from './Logo/Logo';
import {UserInfo} from './UserInfo/UserInfo';
import {Navigation} from './Navigation/Navigation';

interface MenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const colortheme = createMuiTheme({
  palette: {
    primary: { main: "#edeff0", contrastText: "#fff" },
    secondary: { main: "#b2babe", contrastText: "#fff" },
  }
});

const DrawerContent = () => (
    <MuiThemeProvider theme={colortheme}>
      <Logo/>
      <Divider/>
      <UserInfo/>
      <Divider/>
      <Navigation/>
    </MuiThemeProvider>
);

export const MenuBase = ({
                           isOpen,
                           onClose,
                           classes,
                         }: WithClasses & MenuProps) => (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
            variant="temporary"
            anchor="left"
            open={isOpen}
            onClose={onClose}
            classes={{
              paper: classes.drawerPaper,
            }}
        >
          <DrawerContent/>
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            open
        >
          <DrawerContent/>
        </Drawer>
      </Hidden>
    </nav>
);

export const Menu = withStyles(styles)(MenuBase);
