import * as React from 'react';
import {FormControl, withStyles, Grid, Typography, Paper} from '@material-ui/core';
import { Field, FormikProps } from 'formik';

import { WithClasses } from '../../../../types/WithClasses';
import { TextField } from '../../../../components/common/form/TextField/TextField';

import { styles } from './CreditCardData.styles';
import {Text} from "../../../../components/common/Text/Text";

const intlPath = 'creditCardData';

interface CreditCardDataProps {
  isSubmitting: boolean;
  notChecked: boolean;
}

class CreditCardDataBase extends React.Component<CreditCardDataProps & WithClasses & FormikProps<any>> {

  public render() {
    const {classes, isSubmitting, notChecked} = this.props;

    return (
        <React.Fragment>
          <Typography component="h1" variant="h5" className={classes.header}>
            <Text id={`${intlPath}.header`} />
          </Typography>
          <Paper className={classes.root}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <FormControl margin="normal" required fullWidth>
                  <Field
                      component={TextField}
                      id="cardNumber"
                      name="cardNumber"
                      margin="normal"
                      autoFocus
                      messagePrefix={intlPath}
                      disabled={isSubmitting || notChecked}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl margin="normal" required fullWidth>
                  <Field
                      component={TextField}
                      id="expiryMonth"
                      name="expiryMonth"
                      margin="normal"
                      messagePrefix={intlPath}
                      disabled={isSubmitting || notChecked}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl margin="normal" fullWidth>
                  <Field
                      component={TextField}
                      id="expiryYear"
                      name="expiryYear"
                      margin="normal"
                      messagePrefix={intlPath}
                      disabled={isSubmitting || notChecked}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl margin="normal" fullWidth>
                  <Field
                      component={TextField}
                      id="cvvCode"
                      name="cvvCode"
                      margin="normal"
                      messagePrefix={intlPath}
                      disabled={isSubmitting || notChecked}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl margin="normal" required fullWidth>
                  <Field
                      component={TextField}
                      id="owner"
                      name="owner"
                      margin="normal"
                      messagePrefix={intlPath}
                      disabled={isSubmitting || notChecked}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </React.Fragment>
    );
  }
};


export const CreditCardData = withStyles(styles)(CreditCardDataBase);
