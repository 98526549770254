import * as React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  Typography,
  IconButton,
  Checkbox,
} from '@material-ui/core';

import { styles } from './ProductsList.styles';
import { ColumnType, TableHeader } from '../table/TableHeader/TableHeader';
import { IProduct, IProductStatus } from './../../../types/IProduct';
import { WithClasses } from '../../../types/WithClasses';
import { PlayArrow, Cancel } from '@material-ui/icons';
import { Text } from '../Text/Text';

const intlPath = 'productsList';
const columnsIntlPath = `${intlPath}.columns`;

const selectColumn: ColumnType[] = [
  {
    name: 'select',
    labelKey: `${columnsIntlPath}.select`,
    padding: 'checkbox',
  },
];

const actionsColumn: ColumnType[] = [
  { name: 'actions', labelKey: `${columnsIntlPath}.actions`, padding: 'dense' },
];

const columns: ColumnType[] = [
  { name: 'uuid', labelKey: `${columnsIntlPath}.uuid`, padding: 'dense' },
  { name: 'name', labelKey: `${columnsIntlPath}.name` },
  { name: 'price', labelKey: `${columnsIntlPath}.price`, align: 'right' },
  { name: 'category', labelKey: `${columnsIntlPath}.category` },
  {
    name: 'stock',
    labelKey: `${columnsIntlPath}.stock`,
    align: 'right',
  },
  { name: 'status', labelKey: `${columnsIntlPath}.status` },
];

interface ProductsListProps {
  products: IProduct[];
  selectable?: boolean;
  fetchProducts: any;
  selected?: string[];
  onProductSelect?: (uuid: string) => void;
}

class ProductsListBase extends React.Component<
  ProductsListProps & WithClasses
> {
  public render() {
    const { classes, products, selectable = false, selected = [] } = this.props;

    console.log(selected);
    console.log(products);

    return (
      <Paper className={classes.root}>
        {products.length ? (
          <div className={classes.tableWrapper}>
            <Table aria-labelledby="tableTitle" padding="checkbox">
              <TableHeader
                columns={
                  selectable
                    ? [...selectColumn, ...columns]
                    : [...columns, ...actionsColumn]
                }
              />
              <TableBody>
                {products.map(product => {
                  const isSelected = selected.includes(product.uuid);

                  return (
                    <TableRow
                      hover
                      key={product.uuid}
                      onClick={this.onProductSelect(product.uuid)}
                    >
                      {selectable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                              checked={isSelected}
                              value={product.uuid}
                          />
                        </TableCell>
                      )}
                      <TableCell padding="dense" component="th" scope="row">
                        {product.uuid}
                      </TableCell>
                      <TableCell>{product.title}</TableCell>
                      <TableCell align="right">{product.price}</TableCell>
                      <TableCell>{product.category}</TableCell>
                      <TableCell align="right">{product.stock}</TableCell>
                      <TableCell>{product.status}</TableCell>
                      {!selectable && (
                        <TableCell className={classes.actionsColumn}>
                          <IconButton
                            aria-label="Disable Product"
                            onClick={this.handleStatusChange(
                              product.uuid,
                              IProductStatus.NOT_OK
                            )}
                          >
                            <Cancel />
                          </IconButton>
                          <IconButton
                            aria-label="Enable Product"
                            onClick={this.handleStatusChange(
                              product.uuid,
                              IProductStatus.OK
                            )}
                          >
                            <PlayArrow />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className={classes.noDataWrapper}>
            <Typography component="p">
              <Text id={`${intlPath}.noData`} />
            </Typography>
          </div>
        )}
      </Paper>
    );
  }

  private handleStatusChange = (
    id: string,
    newStatus: IProductStatus
  ) => () => {};

  private onProductSelect = (uuid: string) => () => {
    const { selectable, onProductSelect } = this.props;
    {
      if (selectable && onProductSelect) {
        onProductSelect(uuid);
      }
    }
  };
}

export const ProductsList = withStyles(styles)(ProductsListBase);
