import * as React from 'react';
import { withStyles, Paper, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  withHelmet,
  WithHelmetProps,
} from '../../components/HOC/Helmet/withHelmet';
import { styles } from './RegisterConfirmationFailurePage.styles';
import { WithClasses } from '../../types/WithClasses';
import { PublicPageWrapper } from '../../components/common/PublicPageWrapper/PublicPageWrapper';
import { Text } from '../../components/common/Text/Text';

const intlPath = 'registerConfirmationFailurePage';
class RegisterConfirmationFailure extends React.Component<
  WithHelmetProps & WithClasses & RouteComponentProps
> {
  public render() {
    const { classes } = this.props;
    return (
      <PublicPageWrapper>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            <Text id={`${intlPath}.title`} />
          </Typography>
          <Typography component="p">
            <Text id={`${intlPath}.subtitle`} />
          </Typography>
        </Paper>
      </PublicPageWrapper>
    );
  }
}

const RegisterConfirmationFailureWithStyles = withStyles(styles)(
  RegisterConfirmationFailure
);

const RegisterConfirmationFailureWithHelmet = withHelmet(
  RegisterConfirmationFailureWithStyles
);

export const RegisterConfirmationFailurePage = withRouter(
  RegisterConfirmationFailureWithHelmet
);
