import * as React from 'react';

import { Toolbar as MuiToolbar, withStyles } from '@material-ui/core';

import { PRIVATE_ROUTES } from '../../../../constants/routes';

import { Text } from '../../../../components/common/Text/Text';
import { ButtonLink } from '../../../../components/common/ButtonLink/ButtonLink';
import { styles } from './Toolbar.styles';
import { WithClasses } from '../../../../types/WithClasses';
import { IWebsite } from '../../../../types/IWebsite';

interface ToolbarProps {
  website: IWebsite;
}

const intlPath = 'viewWebsitePage.toolbar';

export const ToolbarBase = ({
  classes,
  website,
}: ToolbarProps & WithClasses) => (
  <MuiToolbar disableGutters={true}>
    <ButtonLink
      to={`/${PRIVATE_ROUTES.WEBSITES}`}
      variant="contained"
      color="primary"
      aria-label="Back"
      className={classes.button}
    >
      <Text id={`${intlPath}.backButton`} />
    </ButtonLink>
    <ButtonLink
      to={`/${PRIVATE_ROUTES.WEBSITES}/${website.uuid}/${PRIVATE_ROUTES.EDIT}`}
      variant="contained"
      color="primary"
      aria-label="Edit website"
      className={classes.button}
    >
      <Text id={`${intlPath}.editWebsiteButton`} />
    </ButtonLink>
    <ButtonLink
        to={`/${PRIVATE_ROUTES.WEBSITES}/${website.uuid}/${PRIVATE_ROUTES.PRODUCTS}`}
        variant="contained"
        color="primary"
        aria-label="Website products"
        className={classes.button}
        disabled={!website.feeds}
    >
      <Text id={`${intlPath}.websiteProductsButton`} />
    </ButtonLink>
  </MuiToolbar>
);

export const Toolbar = withStyles(styles)(ToolbarBase);
