import * as React from 'react';

import { Provider } from 'react-redux';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { THEME } from '../../../constants/theme';
import { Route, Redirect, Switch, Router } from 'react-router-dom';
import { DEFAULT_ROUTE, GLOBAL_ROUTES } from '../../../constants/routerConfig';
import { I18nProvider } from '../IntlProvider/IntlProvider';
import { history } from '../../../helpers/history';
import { store } from '../../../store';

class App extends React.Component {
  public render() {
    return (
      <Provider store={store}>
        <I18nProvider>
          <MuiThemeProvider theme={THEME}>
            <React.Fragment>
              <CssBaseline />
              <Router history={history}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to={DEFAULT_ROUTE} />}
                  />
                  {GLOBAL_ROUTES.map(({ Component, path, ...props }) => (
                    <Route
                      key={path}
                      path={path}
                      render={() => <Component {...props} />}
                    />
                  ))}
                  <Redirect to={DEFAULT_ROUTE} />
                </Switch>
              </Router>
            </React.Fragment>
          </MuiThemeProvider>
        </I18nProvider>
      </Provider>
    );
  }
}

export default App;
