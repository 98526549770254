import * as React from 'react';

import {Button, withStyles} from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';

import { WithClasses } from '../../../types/WithClasses';
import { styles } from './PaymentPage.styles';
import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import { CreditCardData } from './CreditCardData/CreditCardData';
import { SubscriptionPackageList } from './SubscriptionPackageList/SubscriptionPackageList';
import { paymentFormInitialValues } from './CreditCardData/initialValues';
import { paymentFormValidationSchema } from './CreditCardData/validationSchema';
import { Formik, Form } from 'formik';
import { SubmitFn } from '../../../types/FormikSubmit';
import { IPaymentForm } from '../../../types/IPayment';
import {Text} from "../../../components/common/Text/Text";
import {sendOnboardingStatus} from "../../../services/AccountService";
import {ONBOARDING_STEPS} from "../../../constants/onboarding";
import {getOnboardingUrl} from "../../../helpers/onboarding";
import {ErrorResponseDefault, parseRequestError} from "../../../helpers/validation";

interface PaymentPageState {
  errorMessage?: string;
  checked?: string;
}


const subscriptionPackages = [
  {
    product: "Podstawowy",
    price: 123,
    definitions: [
      {
        name: 'CMS',
        parameters: [
          {
            name: 'parameter-1',
            value: 'brak'
          }
        ]
      },
      {
        name: 'Analitycs',
        parameters: [
          {
            name: 'e-commerce',
            value: 'brak'
          },
          {
            name: 'grupowanie',
            value: 'brak'
          }
        ]
      },
      {
        name: 'Marketing',
        parameters: [
          {
            name: 'raporty',
            value: 'podstawowe'
          },
          {
            name: 'ai',
            value: 'brak'
          }
        ]
      }
    ],
  },
  {
    product: "Zaawansowany",
    price: 224,
    definitions: [
      {
        name: 'CMS',
        parameters: [
          {
            name: 'parameter-1',
            value: 'jest'
          }
        ]
      },
      {
        name: 'Analitycs',
        parameters: [
          {
            name: 'e-commerce',
            value: 'zaawansowany'
          },
          {
            name: 'grupowanie',
            value: 'jest'
          }
        ]
      },
      {
        name: 'Marketing',
        parameters: [
          {
            name: 'raporty',
            value: 'zaawansowane'
          },
          {
            name: 'ai',
            value: 'jest'
          }
        ]
      }
    ],
  },
  {
    product: "Ekspert",
    price: 456,
    definitions: [
      {
        name: 'CMS',
        parameters: [
          {
            name: 'parameter-1',
            value: 'pełny'
          }
        ]
      },
      {
        name: 'Analitycs',
        parameters: [
          {
            name: 'e-commerce',
            value: 'pełny'
          },
          {
            name: 'grupowanie',
            value: 'jest'
          }
        ]
      },
      {
        name: 'Marketing',
        parameters: [
          {
            name: 'raporty',
            value: 'wszystkie'
          },
          {
            name: 'ai',
            value: 'jest'
          }
        ]
      }
    ],
  }
];

const intlPath = 'paymentPage';

export class Payment extends React.Component<
  WithHelmetProps & WithClasses & RouteComponentProps,
  PaymentPageState
> {
  public readonly state = {
    errorMessage: '',
    cardData: {},
    checked: '',
  };
  public render() {

    const { classes } = this.props;
    const { checked } = this.state;


    return (
       <Formik
         initialValues={paymentFormInitialValues}
         validationSchema={paymentFormValidationSchema}
         onSubmit={this.handleFormSubmit}
         render={formikProps => (
           <Form noValidate={true}>
             <SubscriptionPackageList checked={checked} subscriptionPackages={subscriptionPackages} handleCheck={this.handleCheck} {...formikProps}/>
             <CreditCardData notChecked={checked === ''} {...formikProps}/>

             <Button
                 type="submit"
                 variant="contained"
                 color="primary"
                 aria-label="Save payment"
                 className={classes.button}
                 disabled={checked === ''}
             >
               <Text id={`${intlPath}.savePaymentButton`} />
             </Button>
           </Form>
         )}
       />
    );
  }
//2572480455213566
  handleCheck = (event:any) => {
    this.setState({checked: event.target.value});
  };

  private handleFormSubmit: SubmitFn<IPaymentForm> = (values, actions) => {
    console.log(values);
    sendOnboardingStatus(ONBOARDING_STEPS.SUMMARY).then(() => {
      const { history } = this.props;
      this.setState({ errorMessage: '' }, () => {
        actions.setSubmitting(false);
        history.push(getOnboardingUrl(ONBOARDING_STEPS.SUMMARY));
      });
    }, this.onSaveFailure(values, actions));
  };

  private onSaveFailure: SubmitFn<IPaymentForm> = (_, actions) => (
      { response } = {
        response: { data: ErrorResponseDefault },
      } as any
  ) => {
    const { errorMessage, errors } = parseRequestError(response.data);
    this.setState(
        { errorMessage: errorMessage},
        () => {
          actions.setSubmitting(false);
          actions.setErrors(errors);
        }
    );
  };
}

export const PaymentPageWithStyles = withStyles(styles)(Payment);

export const PaymentPageWithHelmet = withHelmet(PaymentPageWithStyles);

export const PaymentPage = withRouter(PaymentPageWithHelmet);
