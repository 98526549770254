import { createStyles } from '@material-ui/core';

export const styles = () =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  });
