import { ONBOARDING_STEPS } from '../constants/onboarding';
import { PRIVATE_ROUTES } from '../constants/routes';

const BASE_URL = `/${PRIVATE_ROUTES.ONBOARDING}`;

export function getOnboardingUrl(step: ONBOARDING_STEPS) {
  switch (step) {
    case ONBOARDING_STEPS.WELCOME:
      return BASE_URL;
    case ONBOARDING_STEPS.USER_INFO:
      return `${BASE_URL}/${PRIVATE_ROUTES.ECOMMERCE}`;
    case ONBOARDING_STEPS.CREATE_WEBSITE:
      return `${BASE_URL}/${PRIVATE_ROUTES.CAMPAIGN}`;
    case ONBOARDING_STEPS.CREATE_CAMPAIGN:
      return `${BASE_URL}/${PRIVATE_ROUTES.PAYMENT}`;
    case ONBOARDING_STEPS.PAYMENT_DETAILS:
      return `${PRIVATE_ROUTES.CAMPAIGNS}`;
    case ONBOARDING_STEPS.SELECT_PRODUCTS:
      return `${BASE_URL}/${PRIVATE_ROUTES.DONE}`;
    case ONBOARDING_STEPS.SUMMARY:
      return `/${PRIVATE_ROUTES.DASHBOARD}`;
    default:
      return BASE_URL;
  }
}
