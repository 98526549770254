import { Theme, createStyles } from '@material-ui/core';

export const styles = ({ spacing, mixins }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: mixins.toolbar,
    title: {
      flexGrow: 1,
    },
    content: {
      flexGrow: 1,
      overflow: 'auto',
      padding: spacing.unit * 3,
    },
  });
