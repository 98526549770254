import * as Yup from 'yup';

export const newWebsiteFormValidationSchema = Yup.object({
  name: Yup.string()
    .required('required')
    .min(8)
  ,
  url: Yup.string()
    .url('url')
    .required('required'),
  skip_feed: Yup.boolean(),
  //I'm not sure why this condition should be reverted.
  feed: Yup.string().when('skip_feed', {
    is: false,
    then: Yup.string()
      .url('url')
      .required('required'),
    otherwise: Yup.string().url('url'),
  }),
});
