import * as React from 'react';

import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';

import {withRouter, RouteComponentProps} from 'react-router';
import {Loader} from '../../../components/common/Loader/Loader';
import {Error} from '../../../components/common/Error/Error';
import {Toolbar} from './Toolbar/Toolbar';
import {Typography, Grid, withStyles, Paper} from '@material-ui/core';
import {IWebsite} from '../../../types/IWebsite';
import {fetchWebsite} from '../../../services/WebsiteService';
import {
  ErrorResponseDefault,
  parseRequestError,
} from '../../../helpers/validation';
import {WebsiteForm} from '../../../components/common/WebsiteForm/WebsiteForm';
import {Formik, Form} from 'formik';
import {viewWebsiteFormInitialValues} from './initialValues';
import {Text} from '../../../components/common/Text/Text';
import {noop} from '../../../helpers/utils';
import {WithClasses} from '../../../types/WithClasses';
import {styles} from './ViewWebsitePage.styles';

interface IViewWebsiteState {
  isLoading: boolean;
  website?: IWebsite;
  errorMessage?: string;
}

const intlPath = 'viewWebsitePage';

class ViewWebsite extends React.Component<WithHelmetProps & WithClasses & RouteComponentProps,
    IViewWebsiteState> {
  public readonly state: IViewWebsiteState = {
    isLoading: false,
    website: undefined,
    errorMessage: undefined,
  };

  public componentDidMount() {
    const {match} = this.props;
    const websiteId: string = (match.params as any).id;
    this.setState({isLoading: true, errorMessage: undefined}, () =>
        fetchWebsite(websiteId).then(this.onFetchSuccess, this.onFetchFailure)
    );
  }

  public render() {
    const {classes} = this.props;
    const {website, isLoading, errorMessage} = this.state;
    if (errorMessage) {
      return (
          <Error message={`${intlPath}.requestError`}/>
      );
    }
    if (isLoading) {
      return (
          <Loader
              isLoading={isLoading}
              loadingLabelKey={`${intlPath}.fetchingWebsite`}
          />
      );
    }
    console.log(website);


    if (website) {
      return (
          <React.Fragment>
            <Typography component="h1" variant="h5">
              {website.name}
            </Typography>
            <Toolbar website={website}/>
            <Formik
                initialValues={viewWebsiteFormInitialValues(website)}
                onSubmit={noop}
                render={({values}) => (
                    <Paper className={classes.root}>
                      <Form noValidate={true}>
                        <WebsiteForm values={values} viewOnly={true}/>
                        <Grid item xs={12}>
                          {values.ga_connected ? (
                              <Typography color="primary">
                                <Text id={`${intlPath}.ga_connected`}/>
                              </Typography>
                          ) : (
                              <Typography color="error">
                                <Text id={`${intlPath}.ga_not_connected`}/>
                              </Typography>
                          )}
                        </Grid>
                      </Form>
                    </Paper>
                )}
            />
          </React.Fragment>
      );
    }
    return null;
  }

  private onFetchSuccess = ({data}: any) => {
    this.setState({website: data, isLoading: false});
  };

  private onFetchFailure = (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage} = parseRequestError(response.data);
    this.setState({isLoading: false, errorMessage});
  };
}

export const ViewWebsitePageWithStyles = withStyles(styles)(ViewWebsite);

export const ViewWebsitePageWithHelmet = withHelmet(ViewWebsitePageWithStyles);

export const ViewWebsitePage = withRouter(ViewWebsitePageWithHelmet);
