import { Theme, createStyles } from '@material-ui/core';

import { IClass } from '../../../types/WithClasses';

export const styles = ({ spacing }: Theme): IClass =>
  createStyles({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: spacing.unit,
    },
    submit: {
      marginTop: spacing.unit * 3,
    },
  });
