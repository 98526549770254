import { Theme, createStyles } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    retryButton: {
      marginTop: spacing.unit * 3,
    },
    media: {
      height: '550px',
    },
    productItem: {
      padding: spacing.unit * 3,
    },
    adsContainer: {
      padding: spacing.unit * 3,
      marginBottom: spacing.unit * 3,
    }
  });
