import * as React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { styles } from './ValueRow.styles';
import { Text } from '../../../../../components/common/Text/Text';
import { WithClasses } from '../../../../../types/WithClasses';

interface ValueRowProps {
  labelKey: string;
  value: string | number;
}

export const ValueRowBase = ({
  classes,
  labelKey,
  value,
}: ValueRowProps & WithClasses) => (
  <div className={classes.root}>
    <Typography>
      <Text id={labelKey} />
    </Typography>
    <Typography>{value}</Typography>
  </div>
);

export const ValueRow = withStyles(styles)(ValueRowBase);
