import { Theme, createStyles } from '@material-ui/core';

export const drawerWidth = 240;

export const styles = ({ breakpoints }: Theme) =>
  createStyles({
    drawer: {
      [breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#455a64',
      color: '#edeff0',
    },
  });
