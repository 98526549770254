import { IPaymentForm } from './../../../../types/IPayment';

export const paymentFormInitialValues: IPaymentForm = {
  cardNumber: '',
  expiryMonth: '',
  expiryYear: '',
  owner: '',
  cvvCode: '',
  selectedPackage: '',
};
