import { Theme, createStyles } from '@material-ui/core';

export const styles = ({ }: Theme) =>
  createStyles({
    text: {
      color: '#edeff0',
      margin: '1em'
    },

    icon: {
      color: '#edeff0',
      margin: '3em'
    },
  });
