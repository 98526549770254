import * as React from 'react';

import {Typography, Button, withStyles, Toolbar, Grid, Paper} from '@material-ui/core';


import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import {Loader} from '../../../components/common/Loader/Loader';
import {Text} from '../../../components/common/Text/Text';
import {Error} from '../../../components/common/Error/Error';
import {withRouter, RouteComponentProps} from 'react-router';
import {styles} from './EditCampaignAdsPage.styles';
import {WithClasses} from '../../../types/WithClasses';
import { createCampaignAds, fetchCampaignVariants} from "../../../services/CampaignsService";
import {ErrorResponseDefault, parseRequestError} from "../../../helpers/validation";
import {ICampaignVariantsForm, ICampaignProductGroup, ICampaignProductVariant} from "../../../types/ICampaign";
import {ButtonLink} from "../../../components/common/ButtonLink/ButtonLink";
import {PRIVATE_ROUTES} from "../../../constants/routes";
import {Form, Formik} from "formik";
import {editCampaignVariantsFormInitialValues} from "./initialValues";
import {editCampaignVariantsFormValidationSchema} from "./validationSchema";
import {SubmitFn} from "../../../types/FormikSubmit";
import {VariantsForm} from "../../../components/common/VariantsForm/VariantsForm";
import {IAd} from "../../../types/IAd";


interface ICampaignProductVariantState {
  isLoading: boolean;
  errorMessage?: string;
  campaignVariants: ICampaignProductGroup[];
  sendingErrorMessage?: string;
  campaignChosenVariants: ICampaignProductVariant[],
}

const intlPath = 'campaignVariantsPage';

class EditCampaignAds extends React.Component<WithHelmetProps & WithClasses & RouteComponentProps,
    ICampaignProductVariantState> {

  public readonly state = {
    isLoading: false,
    error: undefined,
    campaignVariants: [],
    sendingErrorMessage: '',
    campaignChosenVariants: [],
  };

  public componentDidMount() {
    this.fetchCampaignVariants();
  }

  public render() {
    const {classes} = this.props;
    const {isLoading, error, campaignVariants, sendingErrorMessage, campaignChosenVariants} = this.state;



    const currentVariants : IAd = {variants_list: ['c2c504c2213aa26d0d8bfb4ca613c9f5', '993b83a2e3615d4bb961f9c17fea0dde']} ;

    if (isLoading) {
      return (
          <Loader
              isLoading={isLoading}
              loadingLabelKey={`${intlPath}.fetchingCampaign`}
          />
      );
    }

    if (error) {
      return (
          <Error message={`${intlPath}.requestError.message`}>
            <Button
                className={classes.retryButton}
                type="button"
                variant="contained"
                color="primary"
                onClick={this.fetchCampaignVariants}
            >
              <Text id={`${intlPath}.requestError.retryButton`}/>
            </Button>
          </Error>
      );
    }

    return (
        <Formik
            initialValues={editCampaignVariantsFormInitialValues(currentVariants)}
            validationSchema={editCampaignVariantsFormValidationSchema}
            onSubmit={this.onVariantsSave}
            render={formikProps => (
                <Form noValidate={true}>
                  <Typography component="h1" variant="h5">
                    <Text id={`${intlPath}.title`}/>
                  </Typography>
                  <Toolbar disableGutters={true}>
                    <ButtonLink
                        to={`/${PRIVATE_ROUTES.CAMPAIGNS}`}
                        variant="contained"
                        color="primary"
                        aria-label="Campaigns"
                    >
                      <Text id={`${intlPath}.back`}/>
                    </ButtonLink>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        aria-label="Save campaign"
                        className={classes.button}
                    >
                      <Text id={`${intlPath}.saveCampaignVariantsButton`}/>
                    </Button>
                  </Toolbar>
                  <Paper>
                    <VariantsForm
                        {...formikProps}
                        campaignVariants={campaignVariants}
                        campaignChosenVariants={campaignChosenVariants}
                    />
                    <Grid container>
                      {sendingErrorMessage && (
                          <Grid item xs={12}>
                            <Typography color="error" align="right">
                              <Text id={sendingErrorMessage}/>
                            </Typography>
                          </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Form>
            )}
        />
    );
  }

  private onVariantsSave: SubmitFn<ICampaignVariantsForm> = (values, actions) => {
    this.setState(
        {
          sendingErrorMessage: '',
          isLoading: true,
        },
        () => {
          createCampaignAds('323124d', values).then(
              this.onSaveSuccess(values, actions),
              this.onSaveFailure(values, actions)
          );
        }
    );
  };

  private fetchCampaignVariants = () => {
    const {match} = this.props;
    const campaignId: string = (match.params as any).id;
    this.setState({isLoading: true, errorMessage: '', campaignVariants: []}, () =>
        fetchCampaignVariants(campaignId).then(this.onFetchSuccess, this.onFetchFailure)
    );
  };

  private onFetchSuccess = ({data}: any) => {
    let chosenVariants : any[] = [];

    data.map((product : any) => {
      product.variants.map((variant : ICampaignProductVariant) => {
        if(variant.ad_variant_uuid){
          const item = {value: variant.uuid, name: variant.image_name};
          chosenVariants.push(item);
        }
      })
    });
    this.setState({campaignVariants: data, isLoading: false, campaignChosenVariants: chosenVariants});
  };

  private onFetchFailure = (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage} = parseRequestError(response.data);
    this.setState({isLoading: false, errorMessage});
  };

  private onSaveSuccess: SubmitFn<ICampaignVariantsForm> = (_, actions) => ({
                                                                      data,
                                                                    }: any) => {
    const {history} = this.props;
    this.setState({sendingErrorMessage: '', isLoading: false}, () => {
      actions.setSubmitting(false);
      history.push(`/${PRIVATE_ROUTES.CAMPAIGNS}/${data.campaign_uuid}`);
    });
  };

  private onSaveFailure: SubmitFn<ICampaignVariantsForm> = (_, actions) => (
      {response} = {
        response: {data: ErrorResponseDefault},
      } as any
  ) => {
    const {errorMessage, errors} = parseRequestError(response.data);
    this.setState(
        {sendingErrorMessage: errorMessage, isLoading: false},
        () => {
          actions.setSubmitting(false);
          actions.setErrors(errors);
        }
    );
  };
}

export const EditCampaignAdsPageWithStyles = withStyles(styles)(EditCampaignAds);

export const EditCampaignAdsPageWithHelmet = withHelmet(
    EditCampaignAdsPageWithStyles
);

export const EditCampaignAdsPage = withRouter(EditCampaignAdsPageWithHelmet);
