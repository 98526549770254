import * as React from 'react';

import { Typography, Toolbar, Button, withStyles } from '@material-ui/core';

import { PRIVATE_ROUTES } from '../../../constants/routes';

import {
  withHelmet,
  WithHelmetProps,
} from '../../../components/HOC/Helmet/withHelmet';
import { Loader } from '../../../components/common/Loader/Loader';
import { Text } from '../../../components/common/Text/Text';
import { Error } from '../../../components/common/Error/Error';
import { ButtonLink } from '../../../components/common/ButtonLink/ButtonLink';
import { WebsitesTable } from './WebsitesTable/WebsitesTable';
import { withRouter, RouteComponentProps } from 'react-router';
import { styles } from './ListWebsitesPage.styles';
import { WithClasses } from '../../../types/WithClasses';
import {
  ErrorResponseDefault,
  parseRequestError,
} from '../../../helpers/validation';
import { IWebsite } from '../../../types/IWebsite';
import { fetchWebsites } from '../../../services/WebsiteService';

interface IWebsitesState {
  isLoading: boolean;
  websites: IWebsite[];
  errorMessage?: string;
}

const intlPath = 'listWebsitesPage';

class Websites extends React.Component<
  WithHelmetProps & WithClasses & RouteComponentProps,
  IWebsitesState
> {
  public readonly state = {
    isLoading: false,
    websites: [],
    error: undefined,
  };

  public componentDidMount() {
    this.fetchWebsites();
  }

  public render() {
    const { classes } = this.props;
    const { websites, isLoading, error } = this.state;
    return (
      <React.Fragment>
        <Typography component="h1" variant="h5">
          <Text id={`${intlPath}.title`} />
        </Typography>
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : error ? (
          <Error message={`${intlPath}.requestError.message`}>
            <Button
              className={classes.retryButton}
              type="button"
              variant="contained"
              color="primary"
              onClick={this.fetchWebsites}
            >
              <Text id={`${intlPath}.requestError.retryButton`} />
            </Button>
          </Error>
        ) : (
          <React.Fragment>
            <Toolbar disableGutters={true}>
              <ButtonLink
                to={`/${PRIVATE_ROUTES.WEBSITES}/${PRIVATE_ROUTES.NEW}`}
                variant="contained"
                color="primary"
                aria-label="New campaign"
              >
                <Text id={`${intlPath}.createWebsite`} />
              </ButtonLink>
            </Toolbar>
            <WebsitesTable websites={websites} />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  private fetchWebsites = () => {
    this.setState({ isLoading: true, errorMessage: '', websites: [] }, () =>
      fetchWebsites().then(this.onFetchSuccess, this.onFetchFailure)
    );
  };

  private onFetchSuccess = ({ data }: any) => {
    this.setState({ websites: data.items, isLoading: false });
  };

  private onFetchFailure = (
    { response } = {
      response: { data: ErrorResponseDefault },
    } as any
  ) => {
    const { errorMessage } = parseRequestError(response.data);
    this.setState({ isLoading: false, errorMessage });
  };
}

export const WebsitesListPageWithStyles = withStyles(styles)(Websites);

export const WebsitesListPageWithHelmet = withHelmet(
  WebsitesListPageWithStyles
);

export const WebsitesListPage = withRouter(WebsitesListPageWithHelmet);
