import * as React from 'react';
import { FormControl, Button, withStyles, Typography } from '@material-ui/core';
import { Form, Field, Formik } from 'formik';

import { WithClasses } from '../../../types/WithClasses';
import { styles } from './RegisterForm.styles';
import { SubmitFn } from '../../../types/FormikSubmit';
import { INewUser } from '../../../types/IUser';
import { registerFormValidationSchema } from './validationSchema';
import { registerFormInitialValues } from './initialValues';
import { Checkbox } from '../../../components/common/form/CheckBox/CheckBox';
import { TextField } from '../../../components/common/form/TextField/TextField';
import { Text } from '../../../components/common/Text/Text';

interface RegisterFormProps {
  onRegister: SubmitFn<INewUser>;
}

const intlPath = 'registerPage.form';

export const RegisterFormBase = ({
  classes,
  onRegister,
}: WithClasses & RegisterFormProps) => (
  <Formik
    initialValues={registerFormInitialValues}
    validationSchema={registerFormValidationSchema}
    onSubmit={onRegister}
    render={({ isSubmitting, status }) => (
      <Form className={classes.form} noValidate={true}>
        <FormControl margin="normal" required fullWidth>
          <Field
            component={TextField}
            id="email"
            name="email"
            margin="normal"
            autoFocus
            messagePrefix={intlPath}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <Field
            component={TextField}
            id="password"
            name="password"
            margin="normal"
            type="password"
            messagePrefix={intlPath}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <Field
            component={TextField}
            id="confirm_password"
            name="confirm_password"
            margin="normal"
            type="password"
            messagePrefix={intlPath}
          />
        </FormControl>
        <Field
          name="terms_and_conditions"
          component={Checkbox}
          color="primary"
          messagePrefix={intlPath}
        />
        {status && status.submitErrorMessage && (
          <Typography className={classes.formSubmitError} color="error">
            <Text id={status.submitErrorMessage} />
          </Typography>
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting}
        >
          <Text id={`${intlPath}.submit`} />
        </Button>
      </Form>
    )}
  />
);

export const RegisterForm = withStyles(styles)(RegisterFormBase);
