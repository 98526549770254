import * as React from 'react';

import {
  Fade,
  CircularProgress,
  withStyles,
  Typography,
} from '@material-ui/core';

import { WithClasses } from '../../../types/WithClasses';

import { styles } from './Loader.styles';
import { Text } from '../Text/Text';

interface LoaderProps {
  isLoading: boolean;
  loadingLabelKey?: string;
}

export const LoaderBase = ({
  classes,
  isLoading,
  loadingLabelKey,
}: WithClasses & LoaderProps) => (
  <div className={classes.wrapper}>
    <Fade
      in={isLoading}
      style={{
        transitionDelay: isLoading ? '500ms' : '0ms',
      }}
      unmountOnExit
    >
      <React.Fragment>
        <CircularProgress />
        {loadingLabelKey && (
          <Typography component="p" variant="subtitle1">
            <Text id={loadingLabelKey} />
          </Typography>
        )}
      </React.Fragment>
    </Fade>
  </div>
);

export const Loader = withStyles(styles)(LoaderBase);
