import * as React from 'react';


import {RouteComponentProps, withRouter} from "react-router";
import { Paper, withStyles} from "@material-ui/core";
import {WithClasses} from "../../../../types/WithClasses";
import {styles} from "./UserProfileSettings.styles";


interface ISettingsPageState {
  currentTab: number;
}

export class UserProfileSettings extends React.Component<RouteComponentProps &
    WithClasses, ISettingsPageState> {

  public readonly state = {
    currentTab: 0,
  };

  render() {
    return (
        <Paper>
          User profile settings
        </Paper>
    );
  }

};

export const UserProfileSettingsPageWithStyles = withStyles(styles)(UserProfileSettings);

export const UserProfileSettingsPage = withRouter(UserProfileSettingsPageWithStyles);
