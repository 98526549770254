import { combineReducers } from 'redux';

import { accountReducer as account, IAccountState } from './account';
import { ILanguageState, languageReducer as language } from './language';

export interface IRootState {
  account: IAccountState;
  language: ILanguageState;
}

export const rootReducer = combineReducers({
  account,
  language,
});
