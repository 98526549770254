import * as React from 'react';

import { Loader } from '../../../Loader/Loader';
import { Error } from '../../../Error/Error';
import { Button, withStyles } from '@material-ui/core';
import { Text } from '../../../Text/Text';
import { fetchProducts } from '../../../../../services/WebsiteService';
import {
  ErrorResponseDefault,
  parseRequestError,
} from '../../../../../helpers/validation';
import { IProduct } from '../../../../../types/IProduct';
import { styles } from './ProductListWrapper.styles';
import { WithClasses } from '../../../../../types/WithClasses';
import { ProductsList } from '../../../ProductsList/ProductsList';

interface IProps {
  websiteId: string;
  selected: string[];
  onProductSelect: (uuid: string) => void;
}

interface IState {
  isLoading: boolean;
  products: IProduct[];
  errorMessage?: string;
}

const intlPath = 'productsListWrapper';

class ProductsListWrapperBase extends React.Component<
  IProps & WithClasses,
  IState
> {
  public readonly state = {
    isLoading: false,
    products: [],
    errorMessage: undefined,
  };

  public componentDidMount() {
    this.fetchProducts();
  }

  public render() {
    const { classes, selected, onProductSelect } = this.props;
    const { products, isLoading, errorMessage } = this.state;

    if (isLoading) {
      return <Loader isLoading={isLoading} />;
    }
    if (errorMessage) {
      return (
        <Error message={`${intlPath}.requestError.message`}>
          <Button
            className={classes.retryButton}
            type="button"
            variant="contained"
            color="primary"
            onClick={() => this.fetchProducts()}
          >
            <Text id={`${intlPath}.requestError.retryButton`} />
          </Button>
        </Error>
      );
    }
    return (
      <ProductsList
        selectable={true}
        products={products}
        fetchProducts={this.fetchProducts}
        selected={selected}
        onProductSelect={onProductSelect}
      />
    );
  }

  private fetchProducts = (query = '', page = 1, active = true) => {
    const { websiteId } = this.props;
    this.setState({ isLoading: true, errorMessage: '', products: [] }, () =>
      fetchProducts(websiteId, { query, page , active}).then(
        this.onFetchSuccess,
        this.onFetchFailure
      )
    );
  };

  private onFetchSuccess = ({ data }: any) => {
    this.setState({ products: data.items, isLoading: false });
  };

  private onFetchFailure = (
    { response } = {
      response: { data: ErrorResponseDefault },
    } as any
  ) => {
    const { errorMessage } = parseRequestError(response.data);
    this.setState({ isLoading: false, errorMessage });
  };
}

export const ProductsListWrapper = withStyles(styles)(ProductsListWrapperBase);
