import { Theme, createStyles } from '@material-ui/core';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { IClass } from './../../../../types/WithClasses';

export const styles = ({ spacing, palette }: Theme): IClass =>
  createStyles({
    input: {
      display: 'flex',
      padding: 0,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: `${spacing.unit / 2}px ${spacing.unit / 4}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        palette.type === 'light' ? palette.grey[300] : palette.grey[700],
        0.08
      ),
    },
    noOptionsMessage: {
      padding: `${spacing.unit}px ${spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16,
    },
    paper: {
      zIndex: 1,
      marginTop: spacing.unit,
    },
    divider: {
      height: spacing.unit * 2,
    },
  });
