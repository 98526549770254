import * as React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  Typography,
  IconButton,
} from '@material-ui/core';

import { PRIVATE_ROUTES } from '../../../../constants/routes';

import { ICampaign } from '../../../../types/ICampaign';
import { WithClasses } from '../../../../types/WithClasses';

import {
  ColumnType,
  TableHeader,
} from '../../../../components/common/table/TableHeader/TableHeader';
import { Text } from '../../../../components/common/Text/Text';
import { ButtonLink } from '../../../../components/common/ButtonLink/ButtonLink';

import { styles } from './CampaignsTable.styles';
import {Edit, Cancel, Search, ListAlt, PlayArrow} from '@material-ui/icons';
import { IconLink } from '../../../../components/common/IconLink/IconLink';
import { DateRelative } from '../../../../components/common/Date/Date';

const intlPath = 'listCampaignsPage';
const columnsIntlPath = `${intlPath}.columns`;

const columns: ColumnType[] = [
  { name: 'uuid', labelKey: `${columnsIntlPath}.uuid`, padding: 'dense' },
  { name: 'name', labelKey: `${columnsIntlPath}.name` },
  { name: 'ends_in', labelKey: `${columnsIntlPath}.ends_in` },
  { name: 'website_name', labelKey: `${columnsIntlPath}.website_name` },
  {
    name: 'products_count',
    labelKey: `${columnsIntlPath}.products_count`,
    align: 'right',
  },
  { name: 'status', labelKey: `${columnsIntlPath}.status` },
  { name: 'actions', labelKey: `${columnsIntlPath}.actions`, padding: 'dense'},
];

interface CampaignsTableProps {
  campaigns: ICampaign[];
  onStopCampaign: (campaignId: ICampaign['uuid']) => void;
}

class CampaignsTableBase extends React.Component<
  CampaignsTableProps & WithClasses
> {
  public render() {
    const { classes, campaigns } = this.props;

    console.log(campaigns);

    return (
      <Paper className={classes.root}>
        {campaigns.length ? (
          <div className={classes.tableWrapper}>
            <Table
              aria-labelledby="tableTitle"
              padding="checkbox"
            >
              <TableHeader columns={columns} />
              <TableBody>
                {campaigns.map(campaign => (
                  <TableRow hover key={campaign.uuid}>
                    <TableCell padding="dense" component="th" scope="row">{campaign.uuid}</TableCell>
                    <TableCell>{campaign.name}</TableCell>
                    <TableCell>
                      <DateRelative
                        value={new Date(campaign.end_date)}
                        units="day"
                        updateInterval={undefined}
                      />
                    </TableCell>
                    <TableCell>{campaign.website_name}</TableCell>
                    <TableCell align="right">
                      {campaign.products_count}
                    </TableCell>
                    <TableCell>{campaign.status}</TableCell>
                    <TableCell className={classes.actionsColumn}>
                      <IconLink
                          aria-label="Products"
                          to={`${PRIVATE_ROUTES.CAMPAIGNS}/${campaign.uuid}/${campaign.ads[0] ?
                              PRIVATE_ROUTES.ADS : (PRIVATE_ROUTES.ADS+'/'+PRIVATE_ROUTES.NEW)}`}
                          disabled={!campaign.products_list[0]}
                      >
                        <ListAlt />
                      </IconLink>
                      <IconLink
                        aria-label="Details"
                        to={`${PRIVATE_ROUTES.CAMPAIGNS}/${campaign.uuid}`}
                      >
                        <Search />
                      </IconLink>
                      <IconLink
                        aria-label="Edit campaign"
                        to={`${PRIVATE_ROUTES.CAMPAIGNS}/${campaign.uuid}/${
                          PRIVATE_ROUTES.EDIT
                        }`}
                        disabled={campaign.status === 10}
                      >
                        <Edit />
                      </IconLink>
                      {campaign.status === 10 && (
                          <IconButton
                              aria-label="Stop campaign"
                              onClick={this.handleStopCampaign(campaign.uuid)}
                          >
                            <Cancel />
                          </IconButton>
                      )}
                      {campaign.status === 20 && (
                          <IconButton
                              aria-label="Stop campaign"
                              onClick={this.handleStopCampaign(campaign.uuid)}
                              disabled={true}
                          >
                            <PlayArrow />
                          </IconButton>
                      )}
                      {campaign.status === 30 && (
                          <IconButton
                              aria-label="Stop campaign"
                              onClick={this.handleStopCampaign(campaign.uuid)}
                          >
                            <PlayArrow />
                          </IconButton>
                      )}
                      {campaign.status === 40 && (
                          <IconButton
                              aria-label="Continue campaign"
                              onClick={this.handleStopCampaign(campaign.uuid)}
                          >
                            <PlayArrow />
                          </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className={classes.noDataWrapper}>
            <Typography component="p">
              <Text id={`${intlPath}.noData`} />
            </Typography>
            <ButtonLink
              to={`/${PRIVATE_ROUTES.CAMPAIGNS}/${PRIVATE_ROUTES.NEW}`}
              variant="contained"
              color="primary"
              aria-label="New campaign"
            >
              <Text id={`${intlPath}.createCampaign`} />
            </ButtonLink>
          </div>
        )}
      </Paper>
    );
  }

  private handleStopCampaign = (campaignId: ICampaign['uuid']) => () => {
    this.props.onStopCampaign(campaignId);
  };
}

export const CampaignsTable = withStyles(styles)(CampaignsTableBase);
