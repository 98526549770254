import {
  IGoogleAnalyticsAccountPayload,
  IGoogleAnalyticsViewPayload,
  IFacebookAccountPayload,
  IFacebookViewPayload
} from "../types/IAssociations";
import {AppAPI} from "./APIService";

export const loginGoogleAccount = (payload: IGoogleAnalyticsAccountPayload) => {
  return AppAPI.post(`/app/google/login_check`, payload);
};

export const associateGoogleAccount = (payload: IGoogleAnalyticsViewPayload) => {
  return AppAPI.post(`/app/google/associate`, payload);
};

export const loginFacebookAccount = (payload: IFacebookAccountPayload) => {
  return AppAPI.post(`/app/facebook/login_check`, payload);
};

export const associateFacebookAccount = (payload: IFacebookViewPayload) => {
  return AppAPI.post(`/app/facebook/associate`, payload);
};