import * as React from 'react';

import { Typography, Toolbar, Button, withStyles } from '@material-ui/core';

import { PRIVATE_ROUTES } from '../../../../constants/routes';

import {
  withHelmet,
  WithHelmetProps,
} from '../../../../components/HOC/Helmet/withHelmet';
import { Loader } from '../../../../components/common/Loader/Loader';
import { Text } from '../../../../components/common/Text/Text';
import { Error } from '../../../../components/common/Error/Error';
import { ButtonLink } from '../../../../components/common/ButtonLink/ButtonLink';
import { ProductsTable } from './ProductsTable/ProductsTable';
import { withRouter, RouteComponentProps } from 'react-router';
import { styles } from './ListProductsPage.styles';
import { WithClasses } from '../../../../types/WithClasses';
import {
  ErrorResponseDefault,
  parseRequestError,
} from '../../../../helpers/validation';
import { IProduct } from '../../../../types/IProduct';
import {fetchProducts} from '../../../../services/WebsiteService';
import {IFeedImportStatus} from "../../../../types/IWebsite";

interface IProductState {
  isLoading: boolean;
  products: IProduct[];
  errorMessage?: string;
  feedStatus: IFeedImportStatus;
}

const intlPath = 'listProductPage';

class Products extends React.Component<
  WithHelmetProps & WithClasses & RouteComponentProps,
    IProductState
> {
  public readonly state : IProductState = {
    isLoading: false,
    products: [],
    errorMessage: undefined,
    feedStatus: IFeedImportStatus.FAILED,
  };

  public componentDidMount() {
    this.fetchProducts();
  }

  public render() {
    const { classes, match } = this.props;
    const { products, isLoading, errorMessage, feedStatus } = this.state;
    const websiteId: string = (match.params as any).id;

    return (
      <React.Fragment>
        <Typography component="h1" variant="h5">
          <Text id={`${intlPath}.title`} />
        </Typography>
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : errorMessage ? (
          <Error message={`${intlPath}.requestError.message`}>
            <Button
              className={classes.retryButton}
              type="button"
              variant="contained"
              color="primary"
              onClick={this.fetchProducts}
            >
              <Text id={`${intlPath}.requestError.retryButton`} />
            </Button>
          </Error>
        ) : (
          <React.Fragment>
            <Toolbar disableGutters={true}>
              <ButtonLink
                to={`/${PRIVATE_ROUTES.WEBSITES}/${websiteId}`}
                variant="contained"
                color="primary"
                aria-label="View website"
              >
                <Text id={`${intlPath}.viewWebsite`}/>
              </ButtonLink>
            </Toolbar>
            {feedStatus===IFeedImportStatus.IMPORTED && products && products.length > 0 &&  (
                <ProductsTable products={products}/>
            )}
            {feedStatus===IFeedImportStatus.IMPORTING && (
                <Typography>
                  <Text id={`${intlPath}.renderingFeed`}/>
                </Typography>
            )}
            {feedStatus===IFeedImportStatus.FAILED && (
                <Typography>
                  <Text id={`${intlPath}.renderingFeedFailed`}/>
                </Typography>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  private fetchProducts = () => {
    const {match} = this.props;
    const websiteId: string = (match.params as any).id;

    this.setState({ isLoading: true, errorMessage: '', products: [] }, () =>
        fetchProducts(websiteId, {query: '', page: 1, limit: 25}).then(this.onFetchSuccess, this.onFetchFailure)
    );
  };

  private onFetchSuccess = ({ data }: any) => {
    this.setState({ products: data.products.items, isLoading: false, feedStatus: data.import_status });
  };

  private onFetchFailure = (
    { response } = {
      response: { data: ErrorResponseDefault },
    } as any
  ) => {
    const { errorMessage } = parseRequestError(response.data);
    this.setState({ isLoading: false, errorMessage });
  };
}

export const ProductsListPageWithStyles = withStyles(styles)(Products);

export const ProductsListPageWithHelmet = withHelmet(
    ProductsListPageWithStyles
);

export const ProductsListPage = withRouter(ProductsListPageWithHelmet);
