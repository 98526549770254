import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
    createStyles({
      root: {
        width: '100%',
        padding: spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      p3: {
        padding: spacing.unit * 3,
      },
      header: {
        textAlign: 'center',
        marginBottom: spacing.unit * 3,
      },
      button: {
        marginTop: spacing.unit * 3,
      },
      buttonWrapper: {
        textAlign: 'center',
      },
      packageButton: {
        margin: spacing.unit * 3,
        padding: spacing.unit * 2,
        borderRadius: spacing.unit,
        backgroundColor: '#3f51b5',
        color: 'white',
        display: 'inline-block',
        cursor: 'pointer',
      },
      checked: {
        boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        transition: 'all 0.6s cubic-bezier(.25,.8,.25,1)',
      },
      hidden: {
        display: 'none',
      },
      media: {
        height: '600px',
      },
      true: {
        backgroundColor: 'red',
      },
      false: {
        backgroundColor: 'blue',
      }
    });