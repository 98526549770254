import { IAccount } from '../../types/IAccount';

export enum ActionType {
  StoreAccountData = 'STORE_ACCOUNT_DATA',
}

export interface IStoreAccountData {
  type: ActionType.StoreAccountData;
  payload: IAccount;
}
export const storeAccountData = (payload: IAccount): IStoreAccountData => ({
  payload,
  type: ActionType.StoreAccountData,
});

export type Action = ReturnType<typeof storeAccountData>;
