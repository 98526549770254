import * as React from 'react';
import {WithClasses} from "../../../types/WithClasses";
import {Text} from "../Text/Text";
import {
  Typography,
  withStyles,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Radio,
  Paper,
} from "@material-ui/core";
import {ISubscription} from "../../../types/Subscription";
import {styles} from './SubscriptionPackage.styles';


const intlPath = 'subscriptionPackage';

interface SubscriptionPackageProps {
  subscriptionPackage: ISubscription;
  checked: string;
  handleCheck: any;
}


class SubscriptionPackageBase extends React.Component<WithClasses & SubscriptionPackageProps> {

  public render() {

    const {classes, subscriptionPackage, checked, handleCheck} = this.props;

    return (
        <Paper className={checked === `${subscriptionPackage.product}.radio` && classes.checked}>
          <Paper>
            <div
                className={classes.p3}
            >
              <Typography component="h1" variant="h5" className={classes.header}>
                <Text id={subscriptionPackage.product}/>
              </Typography>
              <Typography component="h1" variant="h5" className={classes.header}>
                <Text id={subscriptionPackage.price.toString()}/>
              </Typography>
              <Typography component="h1" variant="h5" className={classes.header}>
                <Text id={`${intlPath}.paymentPeriod`}/>
              </Typography>
            </div>
          </Paper>
          {subscriptionPackage.definitions.map((definition) => {
            return (
                <React.Fragment>
                  <Typography component="h1" variant="h5"
                              className={classes.header}>
                    <Text id={definition.name}/>
                  </Typography>
                  <List>
                    {definition.parameters.map(module => {
                      return (
                          <ListItem>
                            <ListItemText
                                primary={module.name}
                                secondary={module.value}
                            />
                          </ListItem>
                      );
                    })}
                  </List>
                </React.Fragment>
            );
          })}
          <div className={classes.buttonWrapper}>
            <label>
              <FormControlLabel
                  value={`${subscriptionPackage.product}.radio`}
                  id={`${subscriptionPackage.product}.radio`}
                  control={<Radio/>}
                  name="subscription_package"
                  checked={checked === `${subscriptionPackage.product}.radio`}
                  onClick={handleCheck.bind(this)}
                  className={classes.hidden}
                  label={
                    <Text
                        id={`${intlPath}.subscriptionPackage.label`}
                    />
                  }
              />
              <span
                  className={classes.packageButton}
              >
              <Text
                  id={`${intlPath}.subscriptionPackage.chooseButton`}
              />
            </span>
            </label>
          </div>
        </Paper>
    );

  };


}

export const SubscriptionPackage = withStyles(styles)(SubscriptionPackageBase);