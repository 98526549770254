import { Theme, createStyles } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      marginTop: spacing.unit * 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${spacing.unit * 2}px ${spacing.unit * 3}px ${spacing.unit *
        3}px`,
    },
    loginLink: {
      marginTop: spacing.unit * 2,
    },
  });
