import { Theme, createStyles } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    retryButton: {
      marginTop: spacing.unit * 3,
    },
    button: {
      marginRight: spacing.unit * 2,
    },
  });
