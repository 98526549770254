export const storage = {
  set: (key: string, token: string) => sessionStorage.setItem(key, token),

  setObject: (key: string, token: object) => sessionStorage.setItem(key, JSON.stringify(token)),

  get: (key: string) => sessionStorage.getItem(key) || '',

  getObject: (key: string) => JSON.parse(sessionStorage.getItem(key) || '{}') ,

  delete: (key: string) => sessionStorage.removeItem(key),
};
