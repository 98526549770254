import * as React from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter, RouteComponentProps, Redirect } from 'react-router';

import { WithClasses } from '../../../types/WithClasses';

import { styles } from './PublicPageWrapper.styles';
import { PRIVATE_ROUTES } from '../../../constants/routes';
import { isAuthenticated } from '../../../services/AuthService';

export interface PublicPageWrapperProps {
  children?: React.ReactNode;
}

class Wrapper extends React.Component<PublicPageWrapperProps & WithClasses & RouteComponentProps> {
  public render() {
    if (isAuthenticated()) {
      return <Redirect to={`/${PRIVATE_ROUTES.DASHBOARD}`} />;
    }

    const { classes, children } = this.props;
    return <main className={classes.main}>{children}</main>;
  }
}

export const PublicPageWrapperWithStyles = withStyles(styles)(Wrapper);

export const PublicPageWrapper = withRouter(PublicPageWrapperWithStyles);
