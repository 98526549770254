import * as React from 'react';

import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

interface ButtonLinkProps {
  to: string;
  children: React.ReactNode;
}

export const ButtonLink = ({
  to,
  children,
  ...rest
}: ButtonLinkProps & any) => (
  <Button {...{ component: Link } as any} to={to} {...rest}>
    {children}
  </Button>
);
