import * as React from 'react';
import { withStyles, Paper, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  withHelmet,
  WithHelmetProps,
} from '../../components/HOC/Helmet/withHelmet';
import { styles } from './RegisterConfirmationSuccessPage.styles';
import { WithClasses } from '../../types/WithClasses';
import { PublicPageWrapper } from '../../components/common/PublicPageWrapper/PublicPageWrapper';
import { Text } from '../../components/common/Text/Text';
import { PUBLIC_ROUTES } from '../../constants/routes';
import { ButtonLink } from '../../components/common/ButtonLink/ButtonLink';

const intlPath = 'registerConfirmationSuccessPage';
class RegisterConfirmationSuccess extends React.Component<
  WithHelmetProps & WithClasses & RouteComponentProps
> {
  public render() {
    const { classes } = this.props;
    return (
      <PublicPageWrapper>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            <Text id={`${intlPath}.title`} />
          </Typography>
          <Typography component="p">
            <Text id={`${intlPath}.subtitle`} />
          </Typography>
          <ButtonLink
            to={PUBLIC_ROUTES.LOGIN}
            variant="contained"
            color="primary"
            aria-label="Login"
            className={classes.button}
          >
            <Text id={`${intlPath}.ctaButton`} />
          </ButtonLink>
        </Paper>
      </PublicPageWrapper>
    );
  }
}

const RegisterConfirmationSuccessWithStyles = withStyles(styles)(
  RegisterConfirmationSuccess
);

const RegisterConfirmationSuccessWithHelmet = withHelmet(
  RegisterConfirmationSuccessWithStyles
);

export const RegisterConfirmationSuccessPage = withRouter(
  RegisterConfirmationSuccessWithHelmet
);
